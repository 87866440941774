import {
    Flex,
    useColorModeValue,
    useSimpleToast,
    useToast,
    ToastId,
    Heading,
    Button,
    Input,
    Text,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/common/Title";
import { validateEmail } from "lib/validateEmail";

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const { sendPasswordResetEmail } = useAuth();
    const { toast } = useSimpleToast();
    const successToast = useToast();
    const successToastIdRef = useRef<ToastId>();
    const navigate = useNavigate();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");

    const handleSendPasswordReset = async () => {
        try {
            setLoading(true);
            successToastIdRef.current = successToast({ description: "Sending password reset email...", status: "success", isClosable: false });
            sendPasswordResetEmail(email);
            successToast.update(successToastIdRef.current, { description: "Sent! Check your emails.", status: "success", isClosable: true });
        }
        catch (error) {
            toast({ id: "password-reset-error", status: "error", title: "Send password reset email failed", description: "Reason: " + error });
        }
        finally {
            setLoading(false);
            navigate("/login");
        }
    };
    
    return (
        <Flex direction="column" height="100vh" minHeight="500px" bg={bgColor} borderRadius="4px">
            <Title p="4px"/>
            <Flex justifyContent="center" alignItems="center" height="100%" overflow="hidden">
                <Flex direction="column" w="256px" h="328px">
                    <Heading mb={12}>Reset Password</Heading>
                    <Text mb={8}>Enter the email address used for your LAWO Flex Licensing account to receive a password reset link.</Text>
                    <Text mb={1}>Email Address</Text>
                    <Input id="username" maxHeight="40px" mb={8} onChange={(e) => setEmail(e.target.value)} value={email} />
                    <Flex direction="row" justify="flex-end">
                        <Button colorScheme="lawoBlue" color="white" isDisabled={loading || !validateEmail(email)} onClick={handleSendPasswordReset}>Send Email</Button>
                        <Button ml={2} isDisabled={loading} onClick={() => navigate("/login")}>Cancel</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ResetPassword;
