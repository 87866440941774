import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Flex,
    Spinner,
    Spacer,
    Box,
    NumberInput,
    NumberInputField,
    Select,
    FormLabel,
    useColorModeValue,
    Heading,
    Checkbox,
    PlusIcon,
    Input,
    MinusIcon,
    InfoIcon,
} from "@lawo/lawo-ui";
import Confirmation from "components/common/Confirmation";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import useProducts from "hooks/useProducts";
import EntitlementSummary from "models/EntitlementSummary";
import useEntitlements from "hooks/useEntitlements";
import { assignSerialNumber } from "api/entitlements";
  
interface NewLicenseModalProps {
    isOpen: boolean;
    onClose: () => void;
    downloadSelectedLicenses: (selection: string[]) => Promise<void>;
};

type Page = "Page1" | "Page2";

const NewLicenseModal = ({isOpen, onClose, downloadSelectedLicenses}: NewLicenseModalProps) => {
    const [page, setPage] = useState<Page>("Page1");
    const [productName, setProductName] = useState("Edge");
    const [autoDownload, setAutoDownload] = useState(true);
    const [page1valid, setPage1Valid] = useState(false);
    const [page2valid, setPage2Valid] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const productCodes = useProducts();
    const color = useColorModeValue("lawoGrey.50", "black");
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    const textColor = useColorModeValue("white", "black");
    const entitlementSummaries = useEntitlements();
    const [currentEntitlementSummaries, setCurrentEntitlementSummaries] = useState<EntitlementSummary[]>([]);
    const [toAssign, setToAssign] = useState(new Map<string, number>());
    const [edges, setEdges] = useState(new Array<string[]>(["", ""]));
    const [inProgress, setInProgress] = useState(false);
    const { currentCustomerAccountId } = useAuth();
    const [insufficientEntitlement, setInsufficientEntitlement] = useState<EntitlementSummary>(null);

    useEffect(() => {
        setCurrentEntitlementSummaries(entitlementSummaries.filter((entitlementSummary) => {
            return entitlementSummary.productName === productName;
        }));
        setToAssign(new Map<string, number>());
    }, [productName, entitlementSummaries]);

    useEffect(() => {
        if (page === "Page1") {
            const allZeros = Array.from(toAssign.values()).reduce((acc, cur) => acc + cur, 0) === 0;
            const valid = Array.from(toAssign.entries()).every(([key, value]) => {
                const entitlementSummary = entitlementSummaries.find((entitlementSummary: EntitlementSummary) => entitlementSummary.id === key);
                if (entitlementSummary) {
                    return value <= entitlementSummary.unusedPaths.length;
                }
            });
            setPage1Valid(valid && !allZeros);
        }

    }, [page, toAssign]);

    useEffect(() => {
        if (inProgress) {
            return;
        }

        const empty = edges.some((edge) => edge[0] === "" || edge[1] === "");
        const duplicateSerials = edges.some((edge, index) => edges.findIndex((e) => e[0] === edge[0]) !== index);
        const balanceError = insufficientBalance();

        setInsufficientEntitlement(balanceError);
        setPage2Valid(!empty && !duplicateSerials && !balanceError);
    }, [edges, inProgress, toAssign]);

    const handleAssign = () => {
        setInProgress(true);

        edges.map(async (edge: string[], index: number) => {
            const serialNumber = edge[0];
            const label = edge[1];

            if (serialNumber === "" || label === "") {
                return;
            }

            const entitlementPaths: string[] = [];
            toAssign.forEach((value, key) => {
                const front = index * value;
                const back = front + value;
    
                const entitlementSummary = entitlementSummaries.find((entitlementSummary: EntitlementSummary) => entitlementSummary.id === key);
                if (entitlementSummary) {
                    if (back > entitlementSummary.unusedPaths.length) {
                        return;
                    }

                    entitlementPaths.push(...entitlementSummary.unusedPaths.slice(front, back));
                }
            });

            await assignSerialNumber(currentCustomerAccountId, entitlementPaths, serialNumber, label);

            if (autoDownload) {
                await downloadSelectedLicenses(entitlementPaths);
            }
        });

        setInProgress(false);
        handleClose();
    };

    const handleClose = () => {
        setToAssign(new Map<string, number>());
        setPage1Valid(false);
        setPage2Valid(false);
        setInProgress(false);
        setPage("Page1");
        setConfirmed(false);
        setProductName("Edge");
        setEdges([["", ""]]);
        onClose();
    };

    const setAssignQuantity = (entitlementSummary: EntitlementSummary, value: number) => {
        const newToAssign = new Map(toAssign);
        newToAssign.set(entitlementSummary.id, value);
        setToAssign(newToAssign);
    };

    const addEdge = () => {
        setEdges([...edges, ["", ""]]);
    };

    const setEdgeSerialNumber = (index: number, serialNumber: string) => {
        edges[index][0] = serialNumber;
        setEdges([...edges]);
    };

    const setEdgeLabel = (index: number, label: string) => {
        edges[index][1] = label;
        setEdges([...edges]);
    };

    const removeEdge = (index: number) => {
        edges.splice(index, 1);
        setEdges([...edges]);
    };

    const insufficientBalance = (): EntitlementSummary => {
        let result = null;
        toAssign.forEach((amount: number, entitlementSummaryId: string) => {
            const entitlementSummary = entitlementSummaries.find((entitlementSummary: EntitlementSummary) => entitlementSummary.id === entitlementSummaryId);
            if (entitlementSummary) {
                if ((amount * edges.length) > entitlementSummary.unusedPaths.length) {
                    result = entitlementSummary;
                    return;
                }
            }
        });

        return result;
    };

    const isDuplicateSerialNumber = (index: number): boolean => {
        if (index >= edges.length) {
            return false;
        }

        return edges.some((edge, idx) => idx !== index && edge[0] !== "" && edge[0] === edges[index][0]);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                handleAssign();
            }}
            >
                <ModalContent minWidth={500} bg={bgColor}>
                    <Flex direction="row" align="center">
                        <ModalHeader flex="1">New License</ModalHeader>
                        <Text pr={4}>{page === "Page1" ? "1 of 2" : "2 of 2"}</Text>
                    </Flex>
                    <ModalBody>
                        {
                            page === "Page1" && (
                                <>
                                    <FormLabel htmlFor="productId">Product</FormLabel>
                                    <Select
                                        id="productId"
                                        name="productId"
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                    >
                                        {
                                        productCodes
                                            .filter((code, thisIdx) => productCodes.findIndex((c) => c.productName === code.productName && c.productName !== "Flex") === thisIdx)
                                            .sort((a, b) => a.productName.localeCompare(b.productName))
                                            .map((code) => (                                        
                                                <option key={code.id} value={code.productName}>
                                                    {code.productName === "Edge" ? ".edge" : code.productName}
                                                </option>
                                                ))
                                        }
                                    </Select>
                                    <Spacer height={4}/>
                                    <Box borderTop={"1px solid"} borderColor={color} />
                                    <Spacer height={4}/>
                                    <EntitlementsHeader />
                                    {
                                        currentEntitlementSummaries.filter((value: EntitlementSummary) => value.productName !== "Flex").map((entitlementSummary: EntitlementSummary, index: number) => {
                                            const used = entitlementSummary.usedPaths?.length || 0;
                                            const unused = entitlementSummary.unusedPaths?.length || 0;
                                            const total = used + unused;

                                            return (
                                                <Flex key={index} align="center" py={1}>
                                                    <Flex width="100%">{entitlementSummary.name}</Flex>
                                                    <Flex width="140px">{unused}</Flex>
                                                    <Flex pr={2} align="center">
                                                        <NumberInput
                                                            id={entitlementSummary.partNumber}
                                                            width="64px"
                                                            min={0}
                                                            max={unused}
                                                            value={toAssign.get(entitlementSummary.id) ?? 0}
                                                            onChange={(stringValue, numberValue) => setAssignQuantity(entitlementSummary, isNaN(numberValue) ? 0 : numberValue)}
                                                            isDisabled={unused === 0}
                                                        >
                                                            <NumberInputField />
                                                        </NumberInput>
                                                    </Flex>
                                                    <Flex width="60px">
                                                        / {total}
                                                    </Flex>
                                                </Flex>
                                            );
                                        })
                                    }
                                </>
                            )
                        }
                        {
                            page === "Page2" && (
                                <>
                                    {
                                        productName === "Edge" 
                                            ? <Text>Enter the 20 character Serial Number and a label for the license file. To assign to multiple Edge devices please select the Add Edge button.</Text>
                                            : <Text>Enter the 20 character System ID and a label for the license file. To assign to multiple HOME systems please select the Add System button.</Text>
                                    }
                                    <Spacer height={4}/>
                                    <Flex>
                                        <FormLabel m={0} flex="1" htmlFor="serialNumber">{productName === "Edge" ? "Serial Number" : "System ID"}</FormLabel>
                                        <Box width="16px" />
                                        <FormLabel m={0} flex="1" htmlFor="label">Label</FormLabel>
                                        <Box width="16px" />
                                        <Flex width="40px" />
                                    </Flex>
                                    <Flex direction="column">
                                        {
                                            edges.map((value, index) => (
                                                <Flex key={index} direction="row" pb="16px" align="center">
                                                    <Input
                                                        id="serialNumber"
                                                        name="serialNumber"
                                                        isInvalid={isDuplicateSerialNumber(index)}
                                                        value={edges[index][0]}
                                                        onChange={(e) => setEdgeSerialNumber(index, e.target.value)} />
                                                    <Box width="16px" />
                                                    <Input
                                                        id="label"
                                                        name="label"
                                                        value={edges[index][1]}
                                                        onChange={(e) => setEdgeLabel(index, e.target.value)}/>
                                                    <Box width="16px" />
                                                    <Flex width="40px">
                                                    {
                                                        index > 0 &&
                                                        <Button size="md" minWidth="40px" minHeight="40px" onClick={() => removeEdge(index)}>
                                                            <MinusIcon />
                                                        </Button>
                                                    }
                                                    </Flex>
                                                </Flex>
                                            ))
                                        }
                                    </Flex>
                                    <Flex alignItems="stretch">
                                        <Button
                                            onClick={addEdge}
                                            data-test-id="modal-new-licenses-add-edge-button"
                                        >
                                            <PlusIcon />
                                            <Text>Add {productName === "Edge" ? "Edge" : "System"}</Text>
                                        </Button>
                                        {
                                            insufficientEntitlement &&
                                            <Flex bg="lawoRed.100" ml="8px" flex="1" borderRadius="4px" px="4px" align="center">
                                                <InfoIcon color={textColor} />
                                                <Text color={textColor} ml="4px">{insufficientEntitlement.name}: {insufficientEntitlement.unusedPaths.length} Available / {toAssign.get(insufficientEntitlement.id) * edges.length} Required</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                    <Spacer height={4}/>
                                    <Box borderTop={"1px solid"} borderColor={color} />
                                    <Spacer height={4}/>
                                    <Checkbox defaultChecked onChange={(e) => setAutoDownload(e.target.checked)}>Automatically download licenses</Checkbox>
                                    <Spacer height={4}/>
                                    <Text>To confirm you want to assign these licenses enter <b>confirm</b> in the text field below and then select the Confirm button.</Text>
                                    <Confirmation
                                        targetValue="confirm"
                                        hideMessage
                                        onValid={setConfirmed}
                                        testID="assign-modal-confirm"
                                    />
                                </>
                            )
                        }
                    </ModalBody>
                    <ModalFooter display="flex" justifyContent="end">
                        {
                            page === "Page1" &&
                            <>
                                <Button
                                    mr={2}
                                    onClick={() => setPage("Page2")}
                                    data-test-id="modal-new-licenses-next-button"
                                    isDisabled={!page1valid || inProgress}
                                >
                                    <Box display="flex" alignItems="center" width="50px" justifyContent="center">
                                        <Text display={!inProgress ? "block" : "none"}>Next</Text>
                                        <Spinner size="sm" display={inProgress ? "block" : "none"} />
                                    </Box>
                                </Button>
                            </>
                        }
                        {
                            page === "Page2" &&
                            <>
                                <Button mr={2} onClick={(() => setPage("Page1"))} data-test-id="modal-new-licenses-previous-button">Previous</Button>
                                <Button
                                    type="submit"
                                    colorScheme="lawoBlue"
                                    mr={2}
                                    data-test-id="modal-assign-entitlements-confirm-button"
                                    isDisabled={!page2valid || inProgress || !confirmed}
                                >
                                    <Box display="flex" alignItems="center" width="50px" justifyContent="center">
                                        <Text display={!inProgress ? "block" : "none"}>Confirm</Text>
                                        <Spinner size="sm" display={inProgress ? "block" : "none"} />
                                    </Box>
                                </Button>
                            </>
                        }
                        <Button onClick={() => handleClose()} data-test-id="modal-new-licenses-cancel-button">Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};


const EntitlementsHeader = () => {
    return (
        <Flex py={4}>
            <Heading size="sm" width="100%">Entitlement</Heading>
            <Heading size="sm" width="140px">Available</Heading>
            <Heading size="sm" width="180px">Assign</Heading>
        </Flex>
    );
};

export default NewLicenseModal;
