// import { useAuth } from "contexts/AuthContext";
import { Navigate } from "react-router-dom";
import { Loading } from 'components/common';
import { useEffect, useState } from "react";
import { useSimpleToast } from "@lawo/lawo-ui";
import { FirebaseError } from "firebase/app";
import { ActionCodeErrors } from "lib/actionCodeErrors";
import { Login, SetNewPassword } from "pages/Login";
import Revert2FA from "pages/Login/Revert2FA";
import Subscriptions from "pages/Subscriptions";


const ActionRoute = (): JSX.Element => {
    // const { verifyPasswordReset } = useAuth();
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("");
    const [code, setCode] = useState("");
    const { toast } = useSimpleToast();

    const verifyCode = async (currentMode: string, currentCode: string) => {
        try {
            // const verifiedEmail = await verifyPasswordReset(code);
            // TODO: check if email is verified. otherwise redirect to login
            setMode(currentMode);
            setCode(currentCode);
        } catch (error) {
            if (error instanceof FirebaseError) {
                toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: ActionCodeErrors[error.code] });
            } else {
                toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: "Unknown error. Please try again or contact Lawo support." });
                console.error("Invalid action code", currentCode, error);
            }
            setMode("");
            setCode("");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const checkForCode = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const currentMode = urlParams.get("mode");
            const currentCode = urlParams.get("oobCode");

            await verifyCode(currentMode, currentCode);
        };

        checkForCode();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (mode === "signIn") {
        return <Login />;
    } else if (mode === "resetPassword") {
        return <SetNewPassword oobCode={code} />;
    } else if (mode === "revertSecondFactorAddition") {
        return <Revert2FA oobCode={code} />;
    }
};

export default ActionRoute;
