import {
    Flex,
    Heading,
    Image,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@lawo/lawo-ui';
import Logo from 'assets/images/lawo_logo.png';
import { useState } from 'react';
import { Link as RLink } from 'react-router-dom';

const Title = ({...props}) => {
    const aboutModal = useDisclosure();

    return (
        <Flex maxHeight="72px" align="center" {...props}>
            <Image
                onClick={aboutModal.onOpen}
                cursor="pointer"
                src={Logo}
                alt="Lawo Logo"
                height="56px"
                width="56px"
                borderRadius="4px"
                data-test-id="global-header-logo"
            />
            <Flex
                flexDirection="column"
                ml="16px"
                minHeight="46px"
                justifyContent="center"
            >
                <Heading>Licensing</Heading>
            </Flex>
            <About {...aboutModal} />
        </Flex>
    );
};

interface AboutProps {
    isOpen: boolean;
    onClose: () => void;
}

const About = ({ isOpen, onClose }: AboutProps) => {
    const [currentVersion] = useState("1.0.0");
    const borderColor = useColorModeValue("lawoGrey.50", "black");

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent pb={"16px"}>
                <ModalHeader>About</ModalHeader>
                <ModalCloseButton/>
                <ModalBody data-test-id="about-modal">
                    <VStack align="start">
                        <Heading size="sm">Version</Heading>
                        <Text>Lawo Flex Portal v{currentVersion}</Text>
                        <Text>Revision [{process.env.REACT_APP_GIT_SHA}]</Text>
                    </VStack>
                    <Flex borderTop="1px" borderColor={borderColor} w="100%" my="16px" />
                    <VStack align="start" mt="16px">
                        <Heading size="sm">Legal</Heading>
                        <RLink
                            data-test-id="about-open-source-attributions-link"
                            to="/credits"
                            onClick={onClose}
                        >
                            Open Source Attributions
                        </RLink>
                        <RLink
                            data-test-id="about-privacy-link"
                            to="/settings/privacy"
                            onClick={onClose}
                        >
                            Privacy
                        </RLink>
                        <RLink
                            data-test-id="about-imprint-link"
                            to="/settings/imprint"
                            onClick={onClose}
                        >
                            Imprint
                        </RLink>
                        <RLink
                            data-test-id="about-terms-link"
                            to="/settings/terms-of-use"
                            onClick={onClose}
                        >
                            Terms Of Use
                        </RLink>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default Title;
