import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore"; 
import SiteConnection from "models/SiteConnection";
import { refreshConnectionStatus } from "api";

const useSiteConnection = (siteId: string): SiteConnection => {
    const [data, setData] = useState<SiteConnection>();

    useEffect(() => {         
        if (siteId?.length !== 20) {
            return;
        }

        refreshConnectionStatus(siteId).catch(e => {});

        const docRef = doc(db, "siteConnections", siteId);
        const unsubscribe = onSnapshot(docRef, snap => {
            if (snap.exists()) {
                const refreshedData = snap.data() as SiteConnection;                
                setData({id: siteId, ...refreshedData});
            } else {
                setData(null);
            }
        }, error => {
            console.log(error);
        });
        return () => {            
            unsubscribe();            
        }
    }, [siteId]);

    return data;
}

export default useSiteConnection;
