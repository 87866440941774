import { funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";
import { UserInfo } from "firebase/auth";

export const createOrUpdateUser = async (accountId: string, name: string, email: string, role: string, puaAccepted: boolean) => {
    interface CreateUserRequestProps {
        "name": string,
        "email": string,
        "role": string,
        "customerId": string
        "puaAccepted": boolean
    };

    interface CreateUserResponseProps {
        "user": UserInfo,
        "msg": string
    };

    const callCreateUserHttp = httpsCallable<CreateUserRequestProps, CreateUserResponseProps>(funcs, "createUserHttp");

    try {
        await callCreateUserHttp({
            "name": name,
            "email": email,
            "role": role,
            "customerId": accountId,
            "puaAccepted": puaAccepted,
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Create user failed");
    }    
};

export const deleteUser = async (accountId: string, email: string) => {
    interface DeleteUserRequestProps {
        "email": string,
        "customerId": string
    };

    interface DeleteUserResponseProps {
        "uid": string;
		"msg": string;
    };
   

    const callDeleteUserHttp = httpsCallable<DeleteUserRequestProps, DeleteUserResponseProps>(funcs, "deleteUserHttp");

    try {
        await callDeleteUserHttp({
            "email": email,
            "customerId": accountId
        });
    }
    catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Delete user failed");
    }
};

export const sendPasswordReset = async (email: string) => {
    interface SendPasswordResetRequestProps {
        "email": string
    };

    interface SendPasswordResetResponseProps {
        "msg": string
    };

    const callSendPasswordResetHttp = httpsCallable<SendPasswordResetRequestProps, SendPasswordResetResponseProps>(funcs, "sendPasswordResetHttp");

    try {
        const request: SendPasswordResetRequestProps = {
            "email": email
        };
        await callSendPasswordResetHttp(request);
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Send password reset failed");
    }
}

export const updateUserNotifications = async (accountId: string, email: string, notifications: string[]) =>{
    interface UpdateUserNotificationsRequestProps {
        "customerId": string,
        "email": string,
        "notifications": string[],
    };

    interface UpdateUserNotificationsResponseProps {
        "msg": string,
        "user": UserInfo,
    };

    const callUpdateUserNotificationsHttp = httpsCallable<UpdateUserNotificationsRequestProps, UpdateUserNotificationsResponseProps>(funcs, "updateUserNotificationsHttp");

    try {
        await callUpdateUserNotificationsHttp({
            "customerId": accountId,
            "email": email,
            "notifications": notifications,
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Update user notifications failed");
    }
}
