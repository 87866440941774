import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { doc, DocumentData, onSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import EntitlementSummary from "models/EntitlementSummary";

const useEntitlements = (): EntitlementSummary[] => {
    const [entitlementSummaryData, setEntitlementSummaryData] = useState<EntitlementSummary[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }

        const summariesPath = "customerAccounts/" + currentCustomerAccountId + "/summaries";
        const unsubscribeEntitlements = onSnapshot(doc(db, summariesPath, "entitlements"), (doc) => {
            const entitlementSummaryData = doc.data() as DocumentData;
            if (entitlementSummaryData) {
                const entitlementSummaries: EntitlementSummary[] = [];
                Object.keys(entitlementSummaryData).forEach((key) => {
                    const entitlementSummary = entitlementSummaryData[key] as EntitlementSummary;
                    entitlementSummary.id = key;
                    entitlementSummaries.push(entitlementSummary);
                });

                setEntitlementSummaryData(entitlementSummaries);
            }
        });

        return () => unsubscribeEntitlements();
    }, [currentCustomerAccountId]);

    return entitlementSummaryData;
}

export default useEntitlements;
