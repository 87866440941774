import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Stack,
    Box,    
} from "@lawo/lawo-ui";
import { useState } from "react";
import Confirmation from "../Confirmation";

export const AlertStyle = {
    backgroundColor: "#EB7B69",
    border: "1px solid",
    borderColor: "#AA3B2A",
    color: "white"
};

export const DefaultStyle = {    
    alignItems: "top"
}

interface DeleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOk: () => void;
    message: string;
    title: string;
    requiresConfirm?: boolean;
    buttonText?: string;
    style?: any;
    buttonColor?: string;
    cancelText?: string;
}

// Generic delete modal dialog
const ConfirmModal = ({ isOpen, onOk, onClose, message: text, title, requiresConfirm, buttonText, style, buttonColor, cancelText }: DeleteModalProps) => {
    const [confirmed, setConfirmed] = useState(!requiresConfirm);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onOk();
                    onClose();
                }}
            >
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody>
                        <Stack spacing="16px">
                            <Box style={style ?? DefaultStyle}>
                                <Text>{text}</Text>
                            </Box>
                            {
                            requiresConfirm ?
                            <Confirmation
                                targetValue="confirm"
                                onValid={setConfirmed}
                                testID="delete-modal-confirm"
                            /> 
                            : null}
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme={ buttonColor ?? "lawoRed" }
                            mr={3}
                            data-test-id="delete-modal-delete-button"
                            isDisabled={!confirmed}
                        >
                            {buttonText ?? "Delete"}
                        </Button>
                        <Button onClick={onClose} data-test-id="delete-modal-cancel-button">
                            {cancelText ?? "Cancel"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};

export default ConfirmModal;