import {  funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";

export const validateSiteId = (siteId: string): boolean => {
    const siteIdRequiredLength = 20;
    if (siteId.length !== siteIdRequiredLength) {
        return false;
    }
    // validate that siteid only contains alphanumeric characters, no spaces, no special characters
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(siteId)) {
        return false;
    }
    return true;
};

export const generateAccessKey = async (systemId: string, customerId: string): Promise<string> => {
    interface GenerateAccessKeyProps {
        systemId: string;
        customerId: string;
    }
    interface GenerateAccessKeyResponseProps {
        privateKey: string;
    }

    const callGenerateAccessKey = httpsCallable<GenerateAccessKeyProps, GenerateAccessKeyResponseProps>(funcs, "generateAccessKeyHttp");

    try {

        const { data } = await callGenerateAccessKey({
            "systemId": systemId,
            "customerId": customerId,
        });

        return data.privateKey;

    } catch (error) {
        console.log(error);
        throw new Error("Could not generate access key.");
    }
};


interface UpsertSystemRequestProps {
    "customerId":   string,
    "systemId":     string, // For create leave blank, for update provide systemId
    "name":         string,
    "siteId":       string

};

interface UpsertSystemResponseProps {    
    "msg":      string,
    "systemId": string
};

export const updateSystem = async (accountId: string, systemId: string, name: string, siteId: string) => {
    try {
        if (!validateSiteId(siteId)) {
            throw new Error("Invalid siteId.");
        }

        const callUpsertSystemHttp = httpsCallable<UpsertSystemRequestProps, UpsertSystemResponseProps>(funcs, "upsertSystemHttp");

        await callUpsertSystemHttp({
            "customerId": accountId,
            "systemId": systemId,
            "name": name,
            "siteId": siteId
        });        
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not update system");
    }
};

export const createSystem = async (accountId: string, name: string, siteId: string): Promise<string> => {
    try {
        if (!validateSiteId(siteId)) {
            throw new Error("Invalid siteId.");
        }

        const callUpsertSystemHttp = httpsCallable<UpsertSystemRequestProps, UpsertSystemResponseProps>(funcs, "upsertSystemHttp");

        const { data } = await callUpsertSystemHttp({
            "customerId": accountId,
            "systemId": "",
            "name": name,
            "siteId": siteId
        });

        return data.systemId;
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not create system");
    }
};

export const deleteSystem = async (accountId: string, systemId: string): Promise<string> => {
    interface DeleteSystemRequestProps {
        "customerId":   string,
        "systemId":     string
    };

    interface DeleteSystemResponseProps {
        "msg":      string,
        "systemId": string
    };

    const callDeleteSystemHttp = httpsCallable<DeleteSystemRequestProps, DeleteSystemResponseProps>(funcs, "deleteSystemHttp");

    try {
        const { data } = await callDeleteSystemHttp({
            "customerId":   accountId,
            "systemId":     systemId
        });

        return data.msg;
    } catch(e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not delete system");
    }
};
