import {
    Box,
    SideMenu,
    Flex,
    useColorModeValue,
} from "@lawo/lawo-ui";
import { NavigationItem } from "components/common/Layout";
import Header from "pages/common/Header";
import { Outlet } from "react-router-dom";

const Settings = () => {
    const borderColor = useColorModeValue("lawoGrey.50", "black");
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    
    return (
        <Flex flexDirection="column" flex="1">
            <Header
                borderBottom="1px"
                borderColor={borderColor}
            >
                Settings
            </Header>
            <Flex flexDirection="row" flex="1">
                <SideMenu
                    width="200px"
                    minWidth="200px"
                    height="100%"
                    borderBottomLeftRadius="8px"
                    borderRight="1px solid"
                    borderColor={borderColor}
                    bg={bgColor}
                    p="8px"
                    data-test-id="settings-side-panel-navigation"
                >
                    <NavigationItem
                        data-test-id="settings-side-panel-navigation-account"
                        to="/settings/account"
                    >
                        Account
                    </NavigationItem>
                    <NavigationItem
                        data-test-id="settings-side-panel-navigation-notifications"
                        to="/settings/notifications"
                    >
                        Notifications
                    </NavigationItem>
                    <NavigationItem
                        data-test-id="settings-side-panel-navigation-pua"
                        to="/settings/privacy"
                    >
                        Privacy
                    </NavigationItem>
                    <NavigationItem
                        data-test-id="settings-side-panel-navigation-pua"
                        to="/settings/imprint"
                    >
                        Imprint
                    </NavigationItem>
                    <NavigationItem
                        data-test-id="settings-side-panel-navigation-pua"
                        to="/settings/terms-of-use"
                    >
                        Terms Of Use
                    </NavigationItem>
                </SideMenu>
                <Outlet />
            </Flex>
        </Flex>
    );
};

export default Settings;
