import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,    
} from "@lawo/lawo-ui";
import ManualAssertContent from "pages/common/ManualAssertContent";
import { useEffect, useState } from "react";
import System from "models/System";

interface ManualRevokeModalProps {
    isOpen: boolean;    
    onClose: () => void;    
    system: System;
    onContinue: (revokeKey: string) => void;
}

// ManualRevokeModal displays instructions for revoking tokens in an offline system.
const ManualRevokeModal = ({ isOpen, onClose, system, onContinue }: ManualRevokeModalProps) => {
    const [text, setText] = useState("");
    const [inProgress, setInProgress] = useState(false);
    useEffect(() => {
        if (isOpen) {
            setText("");
            setInProgress(false);
        }
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                setInProgress(true);
                onContinue(text);
            }}
            >
                <ModalContent minW={520}>
                    <ModalHeader>Manual Revoke Required</ModalHeader>
                    <ModalBody>
                        <ManualAssertContent 
                            onRevokeKeyChange={setText}
                            isDisabled={inProgress}
                            system={system}
                             />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme="lawoBlue"
                            mr={3}
                            data-test-id="modal-revoke-submit-button"
                            isDisabled={text.length === 0 || inProgress}

                        >
                            {inProgress ? <Spinner mr={2} /> : null}
                            Continue
                        </Button>
                        <Button 
                            onClick={onClose} 
                            data-test-id="model-revoke-cancel-button">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};


export default ManualRevokeModal;
