import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import System from "models/System";


const useSystem = (systemId: string): System => {
    const [data, setData] = useState<System>();
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        const path = "customerAccounts/" + currentCustomerAccountId + "/systems";

        const unsubscribe = onSnapshot(doc(db, path, systemId), (snapshot) => {
            const data = snapshot.data();            
            const system: System = {
                id: snapshot.id,
                tokenTotal: 0,
                ...(data as System)
            };

            setData(system);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, systemId]);

    return data;
}

export default useSystem;
