import {
  Flex,
  Heading,
  Stack,
  Text,
  Checkbox,
  useColorModeValue,
  CheckboxGroup,
} from "@lawo/lawo-ui";
import { updateUserNotifications } from "api/users";
import { db } from "firebase.config";
import { useAuth } from "contexts/AuthContext";
import { doc, DocumentData, onSnapshot, QueryDocumentSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import CustomerUser from "models/CustomerUser";

const Notifications = () => {
    const { currentUser, isAdmin, currentCustomerAccountId } = useAuth();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    const [notifications, setNotifications] = useState<string[]>([]);

    useEffect(() => {
        if (!currentUser || !currentCustomerAccountId) {
            return;
        }

        const docRef = doc(db, "customerUsers", currentUser.uid);
        const unsubscribe = onSnapshot(docRef, (snapshot: QueryDocumentSnapshot<DocumentData>) => {
            if (snapshot.exists()) {
                const customerUser: CustomerUser = {
                    id: snapshot.id,
                    ...(snapshot.data() as CustomerUser),
                };

                const currentNotifications = customerUser.notifications[currentCustomerAccountId];
                if (currentNotifications.sort().toString() === notifications.sort().toString()) {
                    return;
                }
                setNotifications(currentNotifications);
            }
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, currentUser]);

    const updateUserNotificationsHandler = async (value: string[]) => {
        setNotifications(value);
        updateUserNotifications(currentCustomerAccountId, currentUser.email, value);
    }

    if (!notifications) {
        return null;
    }

    return (
        <Flex flexDirection="column" flex="1">
            <Flex
                flexDirection="column"
                bg={bgColor}
                borderBottomRightRadius="8px"
                height="calc(100vh - 140px)"
                overflow="auto"
            >
                <Stack textAlign="left" spacing="16px" padding={4} align="start">
                    <Heading size="lg">Notifications</Heading>
                    <Text>
                        Please select the notifications you would like to receive by email.
                    </Text>
                    <CheckboxGroup value={notifications} onChange={(value: string[]) => updateUserNotificationsHandler(value.sort())}>
                        <Checkbox spacing={4} name="newSub" value="newSub" isDisabled={isAdmin}>New Subscription pushed to Wallet</Checkbox>
                        <Checkbox spacing={4} name="subActivated" value="subActivated" isDisabled={isAdmin}>Subscription Activated</Checkbox>
                        <Checkbox spacing={4} name="subCancelled" value="subCancelled" isDisabled={isAdmin}>Subscription Cancelled</Checkbox>
                        <Checkbox spacing={4} name="subEnding" value="subEnding" isDisabled={isAdmin}>Subscription Ending Warning</Checkbox>
                        <Checkbox spacing={4} name="allocate" value="allocate">Allocation of Credits to System</Checkbox>
                        <Checkbox spacing={4} name="revoke" value="revoke">Revocation of Credits from a System</Checkbox>
                    </CheckboxGroup>
                </Stack>
            </Flex>
        </Flex>
    );
};

export default Notifications;
