import { Button, DownloadIcon, Flex, FormControl, FormLabel, Stack, Text, TextInput, useSimpleToast, useToast } from "@lawo/lawo-ui";
import { useAuth } from "contexts";
import { downloadTokensUrl, fetchFile, saveBlobToDisk } from "lib";
import System from "models/System";
import { useState } from "react";

interface ManualAssertContentProps {
    isDisabled: boolean;
    system: System;
    onRevokeKeyChange: (revokeKey: string) => void;
}; 

const ManualAssertContent = ({ isDisabled, system, onRevokeKeyChange } : ManualAssertContentProps) => {
    const [text, setText] = useState("");
    const { currentUser } = useAuth();
    const { toast } = useSimpleToast();
    const infoToast = useToast();
    
    const handleDownload = async () => {
        if (!currentUser) return; // Safety check                    
        if (!system?.siteId) return; // Safety check
        try {
            const url = downloadTokensUrl(system.siteId);
            const token = await currentUser.getIdToken();
            const [filename, blob] = await fetchFile(url, token);
            saveBlobToDisk(blob, filename);
            infoToast({
                id: "download-credits-info",
                title: "Download Credits",
                description: "Download success, please check your downloads folder.",
                status: "info",
            });
        } catch (e) {
            console.error(e);
            toast({
                id: "download-credits-error",
                title: "Download Credits",
                description: "Download encountered a problem, please try again.",
                status: "error",
            });
        }
    };

    return (<Stack spacing="16px">
        <Text>The system <strong>{system?.name}</strong> cannot be contacted.</Text><Text>To perform this action manually, follow these steps.</Text>
        <FormControl>
            <FormLabel htmlFor="download-button">1. Download the latest credits file</FormLabel>
            <Button
                id="download-button"
                data-test-id="modal-download-button"
                onClick={handleDownload}
                isDisabled={isDisabled}
            >
                <Flex alignItems="center">
                    <DownloadIcon mr={4} /> Download
                </Flex>
            </Button>
        </FormControl>
        <FormControl>
            <FormLabel htmlFor="text">2. Upload the file into <strong>HOME &gt; Files &gt; Licenses</strong></FormLabel>
        </FormControl>
        <FormControl>
            <FormLabel htmlFor="text">3. Copy the Revoke Key from <strong>HOME &gt; Settings &gt; Licensing</strong> page</FormLabel>
        </FormControl>
        <FormControl>
            <FormLabel htmlFor="text-value">4. Paste the Revoke Key below</FormLabel>
            <TextInput
                id="text-value"
                data-test-id="modal-text-value"
                defaultValue={text}
                isDisabled={isDisabled}
                onChange={(e) => {
                    setText(e.target.value);
                    onRevokeKeyChange(e.target.value);
                }}
            />
        </FormControl>
    </Stack>);
};

export default ManualAssertContent;