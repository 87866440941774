import React from "react";
import { Box, Text, TextInput } from "@lawo/lawo-ui";
import Bold from "../Bold";

interface Props {
    inputRef?: React.RefObject<HTMLInputElement>;
    targetValue: string;
    onValid: (boolean: boolean) => void;
    hideMessage?: boolean;
    testID?: string;
}

function shouldAutofillConfirmation() {
    const flag = localStorage.getItem("autofillConfirmation");
    return flag === "true";
}

interface Props {
    inputRef?: React.RefObject<HTMLInputElement>;
    targetValue: string;
    onValid: (boolean: boolean) => void;
    hideMessage?: boolean;
    testID?: string;
};

const Confirmation = ({
    inputRef,
    targetValue,
    onValid,
    hideMessage,
    testID,
}: Props): JSX.Element => {
    const [value, setValue] = React.useState(
        shouldAutofillConfirmation() ? targetValue : ""
    );
    onValid(value === targetValue);

    const handleChange = (value: string) => {
        setValue(value);
        onValid(value === targetValue);
    };

    return (
        <Box>
            {!hideMessage ? (
                <Text>
                    To confirm you want to do this, enter <Bold>{targetValue}</Bold> in
                    the text field below.
                </Text>
            ) : null}

            <TextInput
                data-test-id={`${testID}-confirmation`}
                ref={inputRef}
                mt="16px"
                value={value}
                autoComplete="off"
                onChange={(e) => {
                    handleChange(e.target.value);
                }}
                placeholder={`Type "${targetValue}" here`}
            />
        </Box>
    );
};

export default Confirmation;
