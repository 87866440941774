import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@lawo/lawo-ui';
import { useEffect, useState } from 'react';
import { storage } from 'firebase.config';
import { ref, getBlob } from 'firebase/storage';
import Header from 'pages/common/Header';

const Credits = () => {
    type Credit = {
        department: string;
        relatedTo: string;
        name: string;
        licensePeriod: string;
        material: string;
        licenseType: string;
        link: string;
        remoteVersion: string;
        installedVersion: string;
        definedVersion: string;
        author: string;
    };

  const [credits, setCredits] = useState<Credit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const bgColor = useColorModeValue("#f9f9f9", "#242424");

  useEffect(() => {
    const fetchCredits = async () => {
        setLoading(true);
      try {
        const licensesRef = ref(storage, 'licenses/oss-licenses.json');
        const response = await getBlob(licensesRef);
        const json = await response.text();
        
        setCredits(JSON.parse(json) as Credit[]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCredits();
  }, []);

  return (
    <Flex direction="column" flex="1">
        <Header>
            Credits
        </Header>
        <Flex
            flexDirection="column"
            bg={bgColor}
            borderBottomRadius="8px"
            height="calc(100vh - 140px)"
            overflow="auto"
        >
            { loading &&
                <Flex direction="column" flex="1" alignItems="center" justifyContent="center">
                    <Spinner mt={8} />
                </Flex>
            }
            <Stack w="100%" height="calc(100vh - 56px)" overflow="auto">
                {
                    credits.map((credit: Credit, i: number) => {
                        return (
                            <Accordion key={i} allowToggle>
                                <AccordionItem>
                                <AccordionButton>
                                    <Heading size="sm" flex="1" textAlign="left">
                                        {credit.name}
                                    </Heading>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Stack spacing="8px" mb="8px">
                                    <Flex>
                                        <Text fontWeight="semibold">Author</Text>&nbsp;-&nbsp;
                                        <Text>{credit.author}</Text>
                                    </Flex>
                                    <Flex>
                                        <Text fontWeight="semibold">License</Text>&nbsp;-&nbsp;
                                        <Text>{credit.licenseType}</Text>
                                    </Flex>
                                    <Flex>
                                        <Text fontWeight="semibold">Repository</Text>&nbsp;-&nbsp;
                                        <a
                                        href={credit.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >
                                        {credit.link}
                                        </a>
                                    </Flex>
                                    <Flex>
                                        <Text fontWeight="semibold">Version</Text>&nbsp;-&nbsp;
                                        <Text>{credit.installedVersion}</Text>
                                    </Flex>
                                    </Stack>
                                </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        );
                    })
                }
            </Stack>
        </Flex>
    </Flex>
  );
};

export default Credits;
