import { Box, Flex, Heading, Image, Stack, Text, useColorModeValue } from "@lawo/lawo-ui";
import GlobeIcon from "assets/images/earth.png";
import { useAuth } from "contexts/AuthContext";
import useCustomerAccount from "hooks/useCustomerAccount";
import useCustomerUser from "hooks/useCustomerUser";
import useRegions from "hooks/useRegions";
import { formatUTCDate, formatUTCDateWithTime, timeStampToDate } from "lib/timestamp";
import { useEffect, useState } from "react";
import { getBlob, getMetadata, ref } from 'firebase/storage';
import { storage } from 'firebase.config';
import ReactCountryFlag from "react-country-flag";

const Pua = () => {
    const { currentUser, currentCustomerAccountId } = useAuth();
    const [termsHtml, setTermsHtml] = useState("");
    const currentCustomerAccount = useCustomerAccount();
    const [lastUpdate, setLastUpdate] = useState("");
    // const currentCustomerUser = useCustomerUser();
    const color = useColorModeValue("lawoGrey.50", "black");
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    // const regions = useRegions();

    const fetchTerms = async () => {
        try {
            const region = currentCustomerAccount?.region;
            const regionSuffix = region ? `-${region}` : '-Rest';
            const path = `termsOfUse/terms-of-use${regionSuffix}.html`;
            const termsRef = ref(storage, path);
            const generationData = await getMetadata(termsRef);
            setLastUpdate(generationData.updated);
            const response = await getBlob(termsRef);
            const html = await response.text();
            setTermsHtml(html);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
      fetchTerms();
    }, [currentCustomerAccount]);
      
    if (!currentUser) {
        return null;
    }

    return (
        <Flex direction="column" flex="1">
            <Flex
              flexDirection="column"
              bg={bgColor}
              borderBottomRadius="8px"
              height="calc(100vh - 140px)"
              overflow="auto"
            >
                <Stack textAlign="left" spacing="16px" padding={4} overflow="auto" position="relative">
                    <div dangerouslySetInnerHTML={{ __html: termsHtml }}/>
                    <Box mx="8px" borderTop={"1px solid"} borderColor={color} />
                    <Text align="center">Updated {formatUTCDateWithTime(timeStampToDate(lastUpdate))}</Text>
                </Stack>
            </Flex>
        </Flex>
    )
};

export default Pua;