import {
    Flex,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Text,
} from '@lawo/lawo-ui';
import Header from 'pages/common/Header';
import Entitlements from './Entitlements';
import Licenses from './Licenses';
import Vouchers from './Vouchers';

const Perpetuals = () => {
    
    return (
        <Flex flexDirection="column" flex="1">
            <Tabs>
                <Header>
                    <Flex direction="row" alignItems="center">
                        <Text mr={6}>Perpetual</Text>
                        <TabList>
                            <Tab>Licenses</Tab>
                            <Tab>Entitlements</Tab>
                            <Tab>Vouchers</Tab>
                        </TabList>
                    </Flex>
                </Header>
                <TabPanels>
                    <TabPanel>
                        <Licenses />
                    </TabPanel>
                    <TabPanel>
                        <Entitlements />
                    </TabPanel>
                    <TabPanel>
                        <Vouchers />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
};

export default Perpetuals;
