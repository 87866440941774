import {
    Text,
    Flex,
    useSimpleToast,
    useColorModeValue,
    Heading,
    Input,
    Button,
} from "@lawo/lawo-ui";
import Loading from "components/common/Loading";
import Title from "components/common/Title";
import { useAuth } from "contexts/AuthContext";
import { FirebaseError } from "firebase/app";
import { ActionCodeErrors } from "lib/actionCodeErrors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface SetNewPasswordProps {
    oobCode: string;
};

const SetNewPassword = ({ oobCode }: SetNewPasswordProps) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { confirmPasswordReset } = useAuth();
    const { toast } = useSimpleToast();
    const navigate = useNavigate();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");

    useEffect(() => {
        setValid(false);

        if (password && confirmPassword) {
            if (password === confirmPassword) {
                if (password.length >= 6) {
                    setValid(true);
                }
            }
        }
    }, [password, confirmPassword]);

    const handleSetNewPassword = async () => {
        try {
            setLoading(true);
            await confirmPasswordReset(oobCode, password);
            toast({ id: "password-set-success", status: "success", title: "Set Password Success", description: "Password has been set successfully. You can now login with your new password." });
        }
        catch (error) {
            if (error instanceof FirebaseError) {
                toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: ActionCodeErrors[error.code] });
            } else {
                toast({ id: "password-set-error", status: "error", title: "Set Password Failed", description: "Unknown error. Please try again or contact Lawo support." });
            }
            console.log("Error setting new password", error);
        }
        finally {
            setLoading(false);
            navigate("/login");
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Flex direction="column" height="100vh" minHeight="500px" bg={bgColor} borderRadius="4px">
            <Title p="4px"/>
            <Flex justifyContent="center" alignItems="center" height="100%" overflow="hidden">
                <Flex direction="column" w="256px" h="328px">
                    <Heading mb={12}>Reset Password</Heading>
                    <Text mb={1}>New Password</Text>
                    <Input
                        id="password"
                        type="password"
                        maxHeight="40px"
                        mb={4}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if ( valid && event.key === "Enter") { handleSetNewPassword() }
                        }}
                    />
                    <Text mb={1}>Confirm New Password</Text>
                    <Input
                        id="confirmPassword"
                        type="password"
                        maxHeight="40px"
                        mb={8}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if ( valid && event.key === "Enter") { handleSetNewPassword() }
                        }}
                    />
                    <Flex direction="row" justify="flex-end">
                        <Button
                            colorScheme="lawoBlue"
                            color="white"
                            isDisabled={!valid}
                            onClick={handleSetNewPassword}
                        >
                            Reset Password
                        </Button>
                        <Button ml={2} onClick={() => navigate("/login")}>Cancel</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SetNewPassword;
