import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';


declare global {
    // eslint-disable-next-line no-var
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
}

if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '') {
    // ONLY ENABLE WHEN DEBUGGING LOCALLY AS THIS PREVENTS OTHER TOKENS FROM WORKING RESULTING IN 403 ERRORS!!
    console.log('FIREBASE_APPCHECK_DEBUG_TOKEN enabled');
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const firebaseConfig : FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
    authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,    
    projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
    measurementId: process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_APP_CHECK_KEY_ID),
    isTokenAutoRefreshEnabled: true
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const funcs = getFunctions(app, "europe-west3");
export const storage = getStorage(app);
export const firebaseApp = app;

if (process.env.REACT_APP_EMULATE === 'true') {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true });
    connectFirestoreEmulator(db, '127.0.0.1', 8080);
}
