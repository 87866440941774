import {
    FormControl,
    FormLabel,
    Input as FormInput,
    useToken,
    getTranslucentColor,
} from "@lawo/lawo-ui";
import React, { HTMLInputTypeAttribute } from "react";

interface Props {
    label: string;
    id: string;
    mb?: number | string;
    type?: HTMLInputTypeAttribute | undefined;
    onChange(value: string): void;
    onKeyPress?: (value: string) => void;
    value?: string;
    autoComplete?: string;
    required?: boolean;
    placeholder?: string;
}

const Input = ({
    label,
    id,
    mb,
    type,
    value,
    autoComplete,
    required,
    placeholder,
    onChange,
    onKeyPress,
}: Props): JSX.Element => {
    const [lawoBrandBlue100, lawoBrandBlue110] = useToken("colors", [
        "lawoBrandBlue.100",
        "lawoBrandBlue.110",
    ]);
    const lawoBrandBlue100Translucent = getTranslucentColor(
        lawoBrandBlue100,
        0.5
    );
    const lawoBrandBlue110Translucent = getTranslucentColor(
        lawoBrandBlue110,
        0.5
    );

    return (
        <FormControl mb={mb}>
            <FormLabel htmlFor={id} color="white">
                {label}
            </FormLabel>
            <FormInput
                id={id}
                type={type}
                value={value}
                autoComplete={autoComplete}
                required={required}
                placeholder={placeholder}
                sx={{
                    _placeholder: {
                        color: "white",
                        opacity: 0.45
                    },
                    backgroundColor: lawoBrandBlue100Translucent,
                    color: "white",                    
                    "&:active": {
                        backgroundColor: lawoBrandBlue100Translucent,
                    },
                    "&:focus": {
                        backgroundColor: lawoBrandBlue100Translucent,
                        border: "2px solid black",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                    },
                    "&:hover": {
                        backgroundColor: lawoBrandBlue110Translucent,
                    },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e.currentTarget.value)
                }
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (!!onKeyPress) {
                        onKeyPress(e.key);
                    }
                }}
            />
        </FormControl>
    );
};

export default Input;
