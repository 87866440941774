
import {
    Button,
    Grid,    
    Heading,    
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Text,    
    useSimpleToast,    
} from "@lawo/lawo-ui";
import { assertEquivalence, cancelSubscription, RevokeError, setAutoRevokeToBillingEndDateHttp } from "api";
import { calculateEndDate, formatUTCDate, timeStampToDate, delay } from "lib";
import Assignment from "models/Assignment";
import ManualAssertContent from "pages/common/ManualAssertContent";
import { useEffect, useState } from "react";

interface ResolveAssignmentsModalProps {
    subscriptionId: string;
    customerAccountId: string;
    assignments: Assignment[];    
    isOpen: boolean;
    onClose: () => void;        
}

// returns a list systems derived from the assignments
const listSystems = (assignments: Assignment[]) => {
    let all = assignments.map( (a) => a.system );     
    return all.filter((s, idx) => all.findIndex((s2) => s2?.id === s?.id) === idx);
}; 

const assignmentsForSystem = (assignments: Assignment[], systemId: string) => {
    return assignments.filter((a) => a.system?.id === systemId);
}

// move the expiresOn date to the subscription end date
const setAutoRevokeToBillingEndDateOnAllEquivalence = async (customerAccountId : string, systemId : string, equivalenceIds : string[] ) => {    
    if (!systemId) return;    
    if (!equivalenceIds.length) return;                
    for( let i = 0 ; i < equivalenceIds.length ; ++i) {
        let a = equivalenceIds[i];        
        await setAutoRevokeToBillingEndDateHttp(customerAccountId, systemId, a);             
    }        
}

// Possible stages we may be in during this process
type stage = 'initial' |        // initial state
        'set-auto-revoke' |      // Step 1: Try and set auto revoke and adjust the end date to the deactivateDate
        'wait-for-assert' |      // Step 2: Wait for 5 seconds for AEGIS to assert it has the change
        'manual-assert' |        // Step 3: Retry the cancel, and if still fails we need to manually assert (show a UI)
        'complete';              // Step 4: Complete

// ResolveAssignmentsModal 
const ResolveAssignmentsModal = ({ isOpen, onClose, customerAccountId, subscriptionId, assignments : initial }: ResolveAssignmentsModalProps) => {    
    
    const [systemId, setSystemId] = useState<string | null>(null);
    const [stage, setStage] = useState<stage>('initial');
    const [assignments, setAssignments] = useState<Assignment[]>(initial);
    const [inProgress, setInProgress] = useState(false) ;
    const [manualAssert, setManualAssert] = useState(false) ;
    const [revokeKey, setRevokeKey] = useState("");
    
    // Reset when dialog is opened.
    useEffect(() => {
        if (isOpen) {
            setAssignments(initial);
            setInProgress(false);
            setManualAssert(false);
        }
    }, [isOpen, setAssignments, setInProgress, setManualAssert, initial]);


    // Returns the first system we are dealing with. 
    const systems = listSystems(assignments);
    const system = systems.length > 0 ? systems[0] : null;        
    const isContinueDisabled = inProgress || assignments.length === 0 || (manualAssert&&revokeKey.length === 0) || stage === 'wait-for-assert';
    
    const { toast } = useSimpleToast();

    const retryCancel = async () : Promise<Assignment[]> => {
        if (!subscriptionId ||!customerAccountId) {
            return;
        }        
        try {
            await cancelSubscription(customerAccountId, subscriptionId);             
        } catch (e) {
            if (e instanceof RevokeError) {
                return e.assignments;                    
            }
        }
        return [];
    }

    const processStage = async (s : stage, systemId: string) => {                
        setStage(s);
        switch (s) {
            // Step 1: Try and set auto revoke and adjust the end date to the deactivateDate
            case "set-auto-revoke":            
                console.log(`set-auto-revoke: ${systemId}`);
                setManualAssert(false);
                await setAutoRevokeToBillingEndDateOnAllEquivalence(
                    customerAccountId,
                    systemId, 
                    assignmentsForSystem(assignments, systemId).map((a) => a.equivalence.id)
                );                        
                await processStage("wait-for-assert", systemId);
                break;
            
            // Step 2: Wait for 5 seconds for AEGIS to assert it has the change
            case "wait-for-assert":
                let newAssignments : Assignment[] = [];
                // Try 5 times, waiting 1 second between each attempt
                for (let attempt = 0;  attempt < 5; ++attempt) {
                    await delay(1000);                                    
                    newAssignments = await retryCancel();                    
                    if (assignmentsForSystem(newAssignments, systemId).length === 0) {
                        console.log(`wait-for-assert: ${systemId} success`);
                        break;
                    }
                }
                setAssignments(newAssignments);
                // If this subscription is assigned to multiple systems, we may still have more to do.
                // If the 'systemId' still exists in newAssignments though, we need to manually assert.                
                if (assignmentsForSystem(newAssignments, systemId).length > 0) {
                    console.log(`wait-for-assert: ${systemId} failed, manual assert required`);
                    await processStage("manual-assert", systemId);

                } else if (newAssignments.length > 0) {           
                    
                    // More to do
                    const nextSystem = listSystems(newAssignments)[0].id;
                    if (nextSystem !== systemId) {
                        console.log(`wait-for-assert: ${systemId} success, but other systems to process`);
                        await processStage("set-auto-revoke", nextSystem);
                    }

                } else {

                    // No more assignments to process, the subscription is now cancelled.
                    console.log(`wait-for-assert: ${systemId} success`);
                    await processStage("complete", systemId);
                }
                break;

            // Step 3: Retry the cancel, and if still fails we need to manually assert (show a UI)
            case "manual-assert":
                // Requires user intervention.
                setSystemId(systemId);
                setInProgress(false);
                setManualAssert(true);
                break;
            case "complete":
                promptComplete();
                onClose();
                break;
            default:
                break;                    
        }        
    };

    const handleError = (e: unknown) => {
        if (e instanceof Error) {
            toast({
                id: "move-expires-error",
                status: "error",
                title: "Reset validity date",
                description: e.message,
            });                
        } else {
            console.error(e);
        }           
    }

    const promptComplete = () => {
        toast({
            id: "cancel-subscription-success",
            title: "Cancel Subscription",
            description: "Subscription cancelled okay. You may continue using credits until subscription is deactivated.",
            status: "info",
        });
        onClose();
    }

    const startProcess = async () => {
        setSystemId(system?.id); // Set the system id we are dealing with
        await processStage("set-auto-revoke", system?.id)
    }

    const startManualAssertProcess = async () => {        
        await assertEquivalence(revokeKey);
        const newAssignments = await retryCancel();
        setAssignments(newAssignments);
        if (assignmentsForSystem(newAssignments, systemId).length > 0) {
            // if this does not include the system we are dealing with
            // then we are back to the start of the process, but for the next system.            
            handleError(new Error("Manual assert failed, please try again or contact support."));
            return; 
        } else if (newAssignments.length > 0) {
            const nextSystem = listSystems(newAssignments)[0].id;
            if (nextSystem !== systemId) {                
                await processStage("set-auto-revoke", nextSystem);
            }
        } else {
            promptComplete();                    
        }
    }

    const handleContinue = async () => {
        try {
            setInProgress(true);
            if (!manualAssert) {
                await startProcess();
            }else {
                await startManualAssertProcess();
            }
        } catch (e) {
            handleError(e);
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
            }}
            >
                <ModalContent minW={520}>
                    <ModalHeader>Cancel Subscription</ModalHeader>
                    <ModalBody>                        
                        <Stack spacing="16px">
                            {
                            manualAssert ? 
                                <ManualAssertContent 
                                    isDisabled={inProgress}
                                    system={system}
                                    onRevokeKeyChange={setRevokeKey} /> :             
                                stage === 'wait-for-assert' ?
                                    <Waiting name={system?.name}/> :
                                    <SystemAssignmentInfo assignments={assignments} />                                                   
                            }
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        {
                            systems.length > 0 ? (
                                <Button
                                    type="submit"
                                    colorScheme="lawoBlue"
                                    mr={3}
                                    data-test-id="modal-resolve-assignments-submit-button"                            
                                    onClick={() => handleContinue()}
                                    isDisabled={isContinueDisabled}
                                    
                                >   
                                    {inProgress ? <Spinner mr={2} /> : null}                         
                                    Reset Credit Validity Date
                                </Button>
                            ) : null 
                        }
                        <Button 
                            onClick={onClose} 
                            data-test-id="model-revoke-cancel-button">
                            {systems.length === 0 ? 'Cancel' : 'Close'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};


const Waiting = ({name} : {name: string}) => {
    return (
        <Stack spacing="16px">
            <Spinner />
            <Text>Attempting to contact the system {name}.</Text>
        </Stack>
    );
}

interface SystemAssignmentInfoProps {
    assignments: Assignment[];
}

const SystemAssignmentInfo = ({ assignments }: SystemAssignmentInfoProps) => {
    const numSystems = listSystems(assignments).length;        
    return (
        <Stack spacing="16px">        
        {
            numSystems > 0 ? (
            <>                            
                <Text>You are attempting to cancel a subscription where credits have been assigned and remain valid in <strong>{numSystems === 1 ? `1 system` : `${numSystems} systems`}</strong>.</Text>
                <Text>You must resolve these issues before continuing with cancellation.</Text>
                {
                    listSystems(assignments).map((s) => {
                        const assignmentsForSystem =  assignments.filter((a) => a.system?.id === s?.id);    
                        return (                        
                            <>
                            <Heading>{s.name}</Heading>    
                            <SystemAssignments assignments={assignmentsForSystem} />                                                                                
                            </>
                        )
                    }) 
                }
                <Text>To resolve this issue, the credit validity date will be moved back to the subscription validity date and the HOME systems updated. If your HOME system is internet connected, this will happen automatically.</Text>                        
            </>
            )
            :
            (
            <>
                <Text>All issues have been resolved.</Text>                                    
            </>)
        }
        </Stack>
    );
};

const SystemAssignments = ({ assignments }: { assignments: Assignment[] }) => {
    return ( 
    <Grid
        gridTemplateColumns="[credits] 1fr [expiresOn] 2fr [deactivateOn] 2fr"
    >
        <Heading gridColumn="credits" size="sm">Credits</Heading>
        <Heading gridColumn="expiresOn" size="sm">Credits Valid Until</Heading>
        <Heading gridColumn="deactivateOn" size="sm">Subscription Valid Until</Heading>

        {assignments.map((a) => <SystemAssignment key={a.equivalence.id} assignment={a} />)}

    </Grid>
    );
};

const SystemAssignment = ({ assignment : a }: { assignment: Assignment }) => {
    const endDate = calculateEndDate(a.equivalence.subActivatedOn, a.equivalence.subBillingInterval);     
    return (
        <>          
            <Text gridColumn="credits">{a.equivalence.tokens / 100}</Text>
            <Text gridColumn="expiresOn">{formatUTCDate(timeStampToDate(a.equivalence.expiresOn))}</Text>
            <Text gridColumn="deactivateOn">{formatUTCDate(endDate)}</Text>
        </>
    );

};

export default ResolveAssignmentsModal;
