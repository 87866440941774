import {
    useColorModeValue,
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td,
    Switch,
} from '@lawo/lawo-ui';
import { Cookie, CookieCategory } from "models/Cookie";
import { useEffect, useState } from 'react';

interface CookieTableProps {
    cookieCategory: CookieCategory;
    cookie: Cookie;
    categoryAccepted: boolean;
    modifyConsents: (category: string, id: string, enabled: boolean) => void;
};

const CookieTable = ({ cookieCategory, cookie, categoryAccepted, modifyConsents }: CookieTableProps) => {
    const bgColor = useColorModeValue("#fff", "#1d1d1d");
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        modifyConsents(cookieCategory.id, cookie.id, accepted);
    }, [accepted]);

    useEffect(() => {
        setAccepted(categoryAccepted);
    }, [categoryAccepted]);

    return (
        <TableContainer p="8px" bg={bgColor} borderRadius="8px" width="100%">
            <Table size="sm">
                <Tbody>
                    <Tr>
                        <Td><b>Accept</b></Td>
                        <Td><Switch disabled={cookieCategory.essential} isChecked={accepted || cookieCategory.essential} onChange={(e) => setAccepted(e.target.checked)}/></Td>
                    </Tr>
                    <Tr>
                        <Td><b>Name</b></Td>
                        <Td>{cookie.name}</Td>
                    </Tr>
                    <Tr>
                        <Td><b>Provider</b></Td>
                        <Td whiteSpace="normal">{cookie.provider}</Td>
                    </Tr>
                    <Tr>
                        <Td><b>Purpose</b></Td>
                        <Td whiteSpace="normal">{cookie.purpose}</Td>
                    </Tr>
                    <Tr>
                        <Td><b>Privacy Policy</b></Td>
                        <Td><a href={cookie.privacyPolicy}><u>{cookie.privacyPolicy}</u></a></Td>
                    </Tr>
                    { cookie.hosts &&
                        <Tr>
                            <Td><b>Host(s)</b></Td>
                            <Td whiteSpace="normal">{cookie.hosts.join(", ")}</Td>
                        </Tr>
                    }
                    { cookie.cookieName.length > 0 &&                    
                        <Tr>
                            <Td><b>Cookie Name(s)</b></Td>
                            <Td whiteSpace="normal">{cookie.cookieName.join(", ")}</Td>
                        </Tr>
                    }
                    {
                        cookie.cookieExpiry &&
                        <Tr>
                            <Td><b>Cookie Expiry</b></Td>
                            <Td>{cookie.cookieExpiry}</Td>
                        </Tr>
                    }
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default CookieTable;
