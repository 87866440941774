import { useColorModeValue } from "@lawo/lawo-ui";


const TopLayer = (): JSX.Element => {
    const fill = useColorModeValue("#E3E3E3", "#474747");
    return (
        <svg width="53" height="156" viewBox="0 0 53 156" fill="none">
            <path
                d="M30 152L53 -90H29L6 152C5 155 3 156 0 156H24C27 156 29.5 154 30 152Z"
                fill={fill}
            />
        </svg>
    );
};

export default TopLayer;
