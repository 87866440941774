import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useSimpleToast,
  Text,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { FirebaseError } from "firebase/app";
import {
  MultiFactorError,
  MultiFactorResolver,
} from "firebase/auth";
import MFACodeInput from "pages/MultiFactorAuthentication/MFACodeInput";
import { useState } from "react";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { updatePassword, initializeMFARequest, verifyMFACode, reauthenticate, getRecaptchaVerifier } = useAuth();
  const [verificationID, setVerificationID] = useState<string>();
  const [resolver, setResolver] = useState<MultiFactorResolver>();
  const { toast } = useSimpleToast();

  const valid = () => {
    return (
      newPassword?.length > 5 &&
      confirmPassword?.length > 5 &&
      newPassword === confirmPassword
    );
  };

  const handleMFA = async (response: MultiFactorError) => {
    const recaptcha = getRecaptchaVerifier("change-password");

    if (recaptcha) {
      const data = await initializeMFARequest(response, recaptcha);

      if (!data) {
        toast({
          id: "login-2fa-error",
          status: "error",
          title: "Handle 2FA failed",
          description: response.code,
        });
      } else {
        const { verificationID, resolver } = data;
        setVerificationID(verificationID);
        setResolver(resolver);
      }
    }

    recaptcha.clear();
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
        await reauthenticate(currentPassword);
        await updatePassword(newPassword);
        toast({ id: "update-password-success", title: "Update Password", description: "Success", status: "success" });
        closeModal();
    } catch (error) {
        const response = error as FirebaseError;
        if (response.code === "auth/wrong-password") {
            toast({
                id: "reauthenticate-failed-error",
                status: "error",
                title: "Authentication failed",
                description: "Please check your current credentials and try again.",
            });
        } else if (response.code === "auth/multi-factor-auth-required") {
            await handleMFA(response as MultiFactorError);
        }
    } finally {
        setLoading(false);
    }
  };

  const submit = async (code: string) => {
    if (await verifyMFACode(verificationID, resolver, code)) {
        await updatePassword(newPassword);
        toast({ id: "update-password-success", title: "Update Password", description: "Success", status: "success" });
        closeModal();
    } else {
        toast({ id: "submit-2fa-failed-error", status: "error", title: "2FA failed!", description: "Invalid code! Please try again."});
    }
  }

  const closeModal = () => {
    setVerificationID(null);
    setResolver(null);
    setNewPassword("");
    setConfirmPassword("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} motionPreset="scale">
      <ModalOverlay />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleChangePassword();
        }}
      >
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalBody>
          {
            !verificationID && !resolver &&
            <>
                <FormLabel htmlFor="currentPassword">Current Password</FormLabel>
                <Input
                    id="currentPassword"
                    type="password"
                    mb={6}
                    required
                    autoComplete="current-password"
                    onChange={(a) => setCurrentPassword(a.target.value)}
                />
                <FormLabel htmlFor="newPassword">New Password</FormLabel>
                <Input
                    id="newPassword"
                    type="password"
                    mb={6}
                    required
                    autoComplete="new-password"
                    onChange={(a) => setNewPassword(a.target.value)}
                />
                <FormLabel htmlFor="confirmPassword">
                    Confirm New Password
                </FormLabel>
                <Input
                    id="confirmPassword"
                    type="password"
                    mb={6}
                    required
                    autoComplete="new-password"
                    onChange={(a) => setConfirmPassword(a.target.value)}
                />
            </>
            }
            { verificationID &&
            <>
                <Text ml={10} mt={4}>Please enter your 6 digit code</Text>
                <MFACodeInput submit={submit} m={4} justify="center"/>
            </>
            }
          </ModalBody>
          <ModalFooter>
              { !verificationID &&
                  <>
                      <Button
                          type="submit"
                          colorScheme="lawoBlue"
                          mr={3}
                          data-test-id="modal-change-password-submit-button"
                          isDisabled={!valid() || loading}
                          >
                          Change Password
                      </Button>
                  </>
              }
              <Button onClick={closeModal} data-test-id="modal-change-password-cancel-button">
                  Cancel
              </Button>
          </ModalFooter>
        </ModalContent>
      </form>
      <div id="change-password"></div>
    </Modal>
  );
};

export default ChangePasswordModal;
