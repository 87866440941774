import {
    HStack,
    Image,
    Text,
    Button,
    Spinner,
    useColorMode,
    useSimpleToast,
} from "@lawo/lawo-ui";
import AppleLogoLight from "assets/svgs/apple_logo_light.svg";
import AppleLogoDark from "assets/svgs/apple_logo_dark.svg";
import GoogleLogoLight from "assets/svgs/google_logo_light.svg";
import GoogleLogoDark from "assets/svgs/google_logo_dark.svg";
import MicrosoftLogoLight from "assets/svgs/microsoft_logo_light.svg";
import MicrosoftLogoDark from "assets/svgs/microsoft_logo_dark.svg";
import { useAuth } from "contexts/AuthContext";
import { useCallback, useEffect, useMemo, useState } from "react";

interface AuthProviderLinkProps {
    providerId: string;
};

const AuthProviderLink = ({providerId}: AuthProviderLinkProps) => {
    const [isLinked, setIsLinked] = useState<boolean>(false);
    const [linking, setLinking] = useState<boolean>(false);
    const { toast } = useSimpleToast();
    const { colorMode } = useColorMode();
    const { currentUser, linkToAuthProvider, unlinkFromAuthProvider } = useAuth();
    const [AppleLogo, GoogleLogo, MicrosoftLogo] = useMemo(() => {
        return [
            colorMode === "dark" ? AppleLogoDark : AppleLogoLight,
            colorMode === "dark" ? GoogleLogoDark : GoogleLogoLight,
            colorMode === "dark" ? MicrosoftLogoDark : MicrosoftLogoLight,
        ];
    }, [colorMode]);

    const link = async (linking: boolean) => {
        setLinking(true);
        if (linking) {
            if (!await linkToAuthProvider(providerId)) {
                toast({
                    id: "link-provider-failed-error",
                    status: "error",
                    title: "Failed to link " + providerString + " account",
                    description: "Other error",
                  });        
            }
        } else {
            await unlinkFromAuthProvider(providerId);
        }
        updateLinkedAccounts();
        setLinking(false);
    };

    const updateLinkedAccounts = useCallback(() => {
        setIsLinked(currentUser?.providerData.some((provider) => provider.providerId === providerId) ?? false);
    }, [currentUser, providerId]);

    useEffect(() => {
        updateLinkedAccounts();
    }, [updateLinkedAccounts]);

    const providerString = providerId === "google.com" ? "Google" : providerId === "apple.com" ? "Apple" : providerId === "microsoft.com" ? "Microsoft" : "Unknown";
    const logoString = providerId === "google.com" ? GoogleLogo : providerId === "apple.com" ? AppleLogo : providerId === "microsoft.com" ? MicrosoftLogo : undefined;

    return (
        <>
            <HStack>
            {
                isLinked || linking
                    ? <Image src={logoString} />
                    : <Image onClick={() => link(true)} cursor="pointer" src={logoString} />
            }
            <Text>{isLinked ? "Linked" : "Link"} to {providerString} Account</Text>
            { 
                isLinked &&
                <Button height="40px" isDisabled={linking} onClick={() => link(false)}>Unlink</Button>
            }
            {
                linking &&
                <Spinner size="sm" mr={2} />
            }
            </HStack>            
        </>
    );
};

export default AuthProviderLink;
