import {
    PinInput,
    PinInputField,
    HStack
} from "@lawo/lawo-ui";

type Props = Record<string, unknown> &{
    submit: (mfaCode: string) => void;
};

const MFACodeInput = ({ submit, ...props }: Props) => {
    return (
        <HStack {...props}>
            <PinInput otp size="lg" onComplete={(value: string) => submit(value)} variant="filled">
                <PinInputField autoFocus />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
            </PinInput>
        </HStack>
    );
};

export default MFACodeInput;
