import { funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";
import SerialNumberAssignment from "models/SerialNumberAssignment";
import LicenseInfo from "models/LicenseInfo";

interface AssignSerialNumberProps {
    customerId:         string;
    entitlementPaths:   string[];
    serialNumber:       string;
    label:              string;
};

interface AssignSerialNumberResult {
    message:    string;
    assignment: SerialNumberAssignment
};

export const assignSerialNumber = async (customerId: string, entitlementIds: string[], serialNumber: string, label: string) => {
    const callAssignSerialNumberHttp = httpsCallable<AssignSerialNumberProps, AssignSerialNumberResult>(funcs, "assignSerialNumberHttp", { timeout: 10000 });
    try {
        await callAssignSerialNumberHttp({
            "customerId": customerId,
            "entitlementPaths": entitlementIds,
            "serialNumber": serialNumber,
            "label": label,
        });
    } catch (e) {
        console.log("Error: ", label);
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not assign serial number");
    }
};

interface DownloadPerpetualLicenseProps {
    customerId: string;
    entitlementPaths: string[];
};

interface DownloadPerpetualLicenseResult {
    message: string;
    licenseInfo: LicenseInfo;
};

export const downloadPerpetualLicense = async (customerId: string, entitlementPaths: string[]): Promise<LicenseInfo> => {
    const callDownloadPerpetualLicenseHttp = httpsCallable<DownloadPerpetualLicenseProps, DownloadPerpetualLicenseResult>(funcs, "downloadPerpetualLicenseHttp", { timeout: 10000 });
    try {
        const result = await callDownloadPerpetualLicenseHttp({
            "customerId": customerId,
            "entitlementPaths": entitlementPaths,
        });

        return result.data.licenseInfo;
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not download perpetual license");
    }
};

interface UpdateEntitlementsLabelProps {
    customerId: string;
    entitlementPaths: string[];
    label: string;
};

interface UpdateEntitlementsLabelResult {
    message: string;
};

export const updateEntitlementsLabel = async (customerId: string, entitlementPaths: string[], label: string) => {
    const callUpdateEntitlementsLabelHttp = httpsCallable<UpdateEntitlementsLabelProps, UpdateEntitlementsLabelResult>(funcs, "updateEntitlementsLabelHttp", { timeout: 10000 });
    try {
        await callUpdateEntitlementsLabelHttp({
            "customerId": customerId,
            "entitlementPaths": entitlementPaths,
            "label": label,
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not update entitlements label");
    }
};
