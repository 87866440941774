import {
    Box,
    Button,
    VStack,
    Heading,
    useColorModeValue,
    Image,
    useSimpleToast,
    Checkbox,
    Text,
} from "@lawo/lawo-ui";
import { useEffect, useState } from "react";
import Logo from "assets/svgs/logo.svg";
import { useAuth } from "contexts/AuthContext";
import Input from "components/common/Input";
import { useNavigate } from "react-router-dom";
import {
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence
} from "firebase/auth";
import { auth } from "firebase.config";
import { FirebaseError } from "firebase/app";
import { validateEmail } from "lib/validateEmail";

interface LoginFormProps {
    handleMFA: (error: unknown) => Promise<void>;
};

const LoginForm = ({ handleMFA }: LoginFormProps) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const lanyardLoopFill = useColorModeValue("#C4C4C4", "lawoDarkGreyDark.120");
    const {
        login,
        isSignInWithEmailLink,
        registerUserWithPassword,
        currentUser
    } = useAuth();

    const { toast } = useSimpleToast();
    const navigate = useNavigate();
    const isEmailLink = isSignInWithEmailLink(window.location.href);
    const isValid = validateEmail(email) && password.length >= 6;

    useEffect(() => {
        if (currentUser) {
            navigate("/subscriptions");
        }
    }, [currentUser]);

    const handleLogin = async () => {
        try {
            // trim whitespace from the email
            let trimmedEmail = email.trim();

            // TODO: Way better validation. Perhaps look at yup for this in a later epic.
            if (!trimmedEmail.length || !password.length) {
                return;
            }
            setLoading(true);
            if (isEmailLink) {
                try {
                    await registerUserWithPassword(trimmedEmail, password, window.location.href);
                } catch (error) {
                    const fErr = error as FirebaseError;
                    if (fErr?.code === "auth/invalid-email") {
                        toast({ id: "register-email-error", status: "warning", title: "Set Password", description: "The email provided does not match the email LAWO registered has registered for you. Please double check and if problem persists contact LAWO support." });                    
                    } else { 
                        toast({ id: "register-email-error", status: "error", title: "Set Password", description: "Sorry, something went wrong. Please try again" });                    
                    }                   
                } finally {
                    setLoading(false);
                }
            } else {
                setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
                await login(trimmedEmail, password);
            }
        }
        catch (error) {
            await handleMFA(error);
            setLoading(false);
        }
    };

    const buttonLabel = isEmailLink ? "Set Password" : "Sign In";

    return (
        <>
            <Box w="100%" justifyContent="center" alignContent="center">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                }}>
                <VStack justifySelf="flex-start" justifyContent="space-between" alignContent="center" mb={10}>
                    <Box h="16px" w="48px" borderRadius="8px" bg={lanyardLoopFill} justifySelf="flex-start" mb="24px" />
                    <VStack>
                        <Box mb="10px">
                            <Image mx="8px" border="1px solid white" borderRadius="8px" src={Logo} alt="Lawo Logo" height={128} width={128} />
                        </Box>
                        <Heading size="lg" fontSize="24px" color="white">Licensing</Heading>
                    </VStack>
                </VStack>
                <Input label="Email" id="username" 
                    autoComplete="username" required 
                    placeholder="Enter your email address"
                    mb={2} onChange={setEmail} value={email} />
                <Input 
                    label="Password" id="password" type="password" 
                    autoComplete={isEmailLink ? "new-password" : "current-password"}
                    placeholder={isEmailLink ? "Enter a password" : "Enter your password"}
                    required mb={6} 
                    onChange={(a) => setPassword(a)} onKeyPress={(key: string) => {
                    if (key === "Enter") { handleLogin(); }
                }}
                />
                <Button colorScheme="lawoBrandBlue" color="white" w="100%" mb={4} isDisabled={loading || !isValid} type="submit">{buttonLabel}</Button>
                {
                    !isEmailLink &&
                    <VStack>
                        <Checkbox color="white" width="100%" onChange={(e) => setRememberMe(e.target.checked)}>Remember Me</Checkbox>
                    </VStack>
                }
                </form>
            </Box>
        </>
    );
};

export default LoginForm;
