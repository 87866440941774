import {
    useColorModeValue,
    Flex,
    Stack,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { getBlob, ref } from 'firebase/storage';
import { storage } from 'firebase.config';
import useCustomerAccount from 'hooks/useCustomerAccount';


const Privacy = () => {
    const [imprintHtml, setImprintHtml] = useState("");
    const { currentUser } = useAuth();
    const borderColor = useColorModeValue("lawoGrey.50", "black");
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    const currentCustomerAccount = useCustomerAccount();


    const fetchImprint = async () => {
        try {
            const path = "privacy_policy_lawo.html";
            const licensesRef = ref(storage, path);
            const response = await getBlob(licensesRef);
            const html = await response.text();
            setImprintHtml(html);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchImprint();
    }, [currentCustomerAccount]);
      
    if (!currentUser) {
        return null;
    }

    return (
        <Flex direction="column" flex="1">
            <Flex
              flexDirection="column"
              bg={bgColor}
              borderBottomRadius="8px"
              height="calc(100vh - 140px)"
              overflow="auto"
            >
                <Stack textAlign="left" spacing="16px" padding={4} overflow="auto" position="relative">
                    <div dangerouslySetInnerHTML={{ __html: imprintHtml }}/>
                </Stack>
            </Flex>
        </Flex>
    )
};

export default Privacy;
