import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

const AuthRoute = ({ children, redirectPath }: { children: JSX.Element; redirectPath?: string }): JSX.Element => {
    const { currentUser, puaAccepted } = useAuth();
    const isAuthenticated = !!currentUser;
    const location = useLocation();

    // redirect unauthenticated users to login page when they try to access a protected page
    if (!isAuthenticated && !redirectPath) {
        return <Navigate to="/login" />;
    }

    // redirect authenticated users to subscriptions page when they try to access login page or reset password page
    if (isAuthenticated && redirectPath) {
        return <Navigate to={redirectPath} />;
    }

    // redirect authenticated users to portal user agreement page when they try to access a protected page
    // and have not accepted the portal user agreement yet
    if (isAuthenticated && !puaAccepted && location.pathname !== "/pua") {
        return <Navigate to="/pua" />;
    }

    return children;
};

export default AuthRoute;
