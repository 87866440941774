import {
    Button,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Field,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useSimpleToast as useErrorToast,
    Flex,
    Spacer,
    WarningColorIcon,
    Text,
    Switch,
    TextInput,
    Box,
} from "@lawo/lawo-ui";
import { useState } from "react";
import System from "models/System";
import { useSubscriptions } from "hooks";
import { useAuth } from "contexts";
import Subscription from "models/Subscription";
import { assignEquivalence } from "api";
import { creditsToTokens, tokensToCredits } from "lib/credits";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface AddTokensModalProps {
    isOpen: boolean;
    onClose: () => void;
    system: System;
}

type Page = "Page1" | "Page2";

// subscriptionTypeText returns 'Monthly'|'Annual' etc depending on billingInterval
const subscriptionTypeText = (sub: Subscription) => {
    switch (sub.billingInterval) {
        case 1:
            return "Monthly";
        case 12:
            return "Annual";
        default:
            return `${sub.billingInterval} Months`;
    }
}

const initAutoRevokeDate = () : Date => {
    const now = new Date();    
    now.setHours(12, 0, 0, 0);    
    return now;
}

// maxAutoRevokeEndDate returns the maximum allowed end date for a subscription
const maxAutoRevokeEndDate = (sub: Subscription): Date => {
    if (!sub) return new Date();

    if (sub.cancelled) {
        return sub.deactivateOn.toDate();
    }

    const now = new Date();
    now.setMonth(now.getMonth() + sub.billingInterval);    
    return now;
};


const AddTokensModal = ({ isOpen, onClose, system }: AddTokensModalProps) => {
    const [page, setPage] = useState<Page>("Page1");
    const [loading, setLoading] = useState(false);
    const [creditCount, setCreditCount] = useState(0);
    const [subscription, setSelectedSubscription] = useState<Subscription>(null);
    const [autoRevoke, setAutoRevoke] = useState(false);
    const [autoRevokeEndDate, setAutoRevokeEndDate] = useState<Date>(new Date());

    const { currentCustomerAccountId } = useAuth();
    const subscriptions = useSubscriptions();

    const { toast } = useErrorToast();

    const onModalClose = () => {
        setCreditCount(0);
        setAutoRevoke(false); 
        setAutoRevokeEndDate(initAutoRevokeDate());
        onClose();
    };

    const valid = (page === "Page2" && creditCount > 0) || (page === "Page1" && subscription);
    
    const handleAdd = async () => {

        if (currentCustomerAccountId === "" || !subscription) {
            return;
        }        
        try {
            setLoading(true);            
            var revokeDateUtc = new Date(Date.UTC(autoRevokeEndDate.getUTCFullYear(), autoRevokeEndDate.getUTCMonth(),
                autoRevokeEndDate.getUTCDate(), 23, 59, 59));
            assignEquivalence(currentCustomerAccountId,
                system.id,
                subscription.id,
                creditsToTokens(creditCount),
                autoRevoke,
                revokeDateUtc.toISOString());
            onModalClose();
        } catch (e) {
            console.error(e);
            toast({
                id: "assign-tokens-error",
                title: "Assign Tokens",
                description: "Error assigning tokens, please try again.",
                status: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const onSubscriptionChange = (subscriptionId: string) => {
        const sub = subscriptions.find((s) => s.id === subscriptionId);
        setSelectedSubscription(sub);
        setAutoRevokeEndDate(initAutoRevokeDate());        
        setCreditCount(0);
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                if (page === "Page1") {
                    setPage("Page2");
                    return;
                }
                handleAdd();
            }}
            >
                <ModalContent minW={560}>
                    <ModalHeader>Add Credits to {system?.name}</ModalHeader>
                    <ModalBody>
                        {page === "Page1" && (
                            <AddTokensSelectSubscriptionPage
                                subscriptions={subscriptions}
                                selectedId={subscription?.id}
                                onSubscriptionChange={onSubscriptionChange}
                            />
                        )}
                        {
                            page === "Page2" && (
                                <AddTokensSetOptionsPage
                                    subscription={subscription}
                                    autoRevoke={autoRevoke}
                                    credits={creditCount}
                                    onCreditsChange={setCreditCount}
                                    onAutoRevokeChange={setAutoRevoke}
                                    autoRevokeEndDate={autoRevokeEndDate}
                                    onAutoRevokeEndDateChange={setAutoRevokeEndDate}
                                />
                            )
                        }
                    </ModalBody>
                    <ModalFooter display="flex" justifyContent="space-between">
                        {
                            page === "Page2" ? (
                                <Button
                                    onClick={() => setPage("Page1")}
                                    data-test-id="modal-add-tokens-back-button">
                                    Back
                                </Button>) :
                                (<Box />)
                        }
                        <Box>
                            <Button
                                type="submit"
                                colorScheme="lawoBlue"
                                mr={3}
                                data-test-id="modal-add-tokens-submit-button"
                                isDisabled={!valid || loading}
                            >
                                {page === "Page1" ? "Next" : "Add Credits"}
                            </Button>
                            <Button onClick={() => {
                                setPage("Page1");
                                onModalClose();
                            }}
                                data-test-id="modal-add-tokens-cancel-button">
                                Cancel
                            </Button>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};


interface AddTokensSetOptionsPageProps {
    subscription: Subscription;
    credits: number;
    onCreditsChange: (credits: number) => void;
    autoRevoke: boolean;
    onAutoRevokeChange: (enabled: boolean) => void;
    autoRevokeEndDate : Date; 
    onAutoRevokeEndDateChange : (enddate : Date) => void;
};

export const AddTokensSetOptionsPage = ({ subscription, credits, onCreditsChange, autoRevoke, onAutoRevokeChange, autoRevokeEndDate, onAutoRevokeEndDateChange }: AddTokensSetOptionsPageProps) => {

    const runningBalance = subscription ? subscription.balance - creditsToTokens(credits) : 0;    
   
    if (!subscription) return null;

    return (
        <Stack>
            <Text>Subscription <strong>{subscription?.orderId}</strong> has <strong>{tokensToCredits(runningBalance)}</strong> credits remaining. This is a <strong>{subscriptionTypeText(subscription)}</strong> subscription.</Text>
            <Text>Enter the number of credits from this subscription to assign to the system.</Text>
            <Field>
                <FormLabel htmlFor="credits">Number of Credits</FormLabel>
                <NumberInput
                    id="credits"
                    name="credits"
                    width={200}
                    min={0}
                    max={tokensToCredits(subscription?.balance ?? 0)}
                    value={credits}
                    onChange={(stringValue, numberValue) => onCreditsChange(isNaN(numberValue) ? 0 : numberValue)}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </Field>
            <Field>
                <FormLabel htmlFor="credits">Auto Revoke</FormLabel>
                <Text>Schedule the credits to be moved back to the subscription on a specific date.</Text>
                <Switch
                    mr={8}
                    mt={4}
                    checked={autoRevoke}
                    onChange={(e) => onAutoRevokeChange(e.target.checked)} />
            </Field>
            {
                autoRevoke && (
                    <>
                        <Field>
                            <FormLabel htmlFor="credits">Revoke Date (23 h 59s)</FormLabel>
                            <DatePicker
                                minDate={new Date()}
                                maxDate={maxAutoRevokeEndDate(subscription)}
                                startDate={new Date()}
                                disabled={!autoRevoke}
                                selected={autoRevokeEndDate}
                                selectsEnd                                
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => onAutoRevokeEndDateChange(date)}
                                customInput={<TextInput width={200} />}
                            />
                        </Field>                                                                        
                    </>
                )
            }
        </Stack>
    )
}


interface AddTokensSelectSubscriptionPageProps {
    subscriptions: Subscription[];
    selectedId: string;
    onSubscriptionChange: (subscrioptionId: string) => void;
}

const AddTokensSelectSubscriptionPage = ({ subscriptions, selectedId, onSubscriptionChange }: AddTokensSelectSubscriptionPageProps) => {
    return (
        <Stack>
            <Text>Choose the subscription from which you want to allocate credits from.</Text>
            <TableContainer>
                <RadioGroup onChange={onSubscriptionChange} value={selectedId}>
                    <Table>
                        <SubscriptionsHeader />
                        <SubscriptionsBody subscriptions={subscriptions} selectedSubscription={selectedId} />
                    </Table>
                </RadioGroup>
            </TableContainer>
        </Stack>
    )
}

const SubscriptionsHeader = () => {
    return (
        <Thead>
            <Tr>
                <Th></Th>
                <Th>Subscription</Th>
                <Th>Balance</Th>
                <Th>Type (State)</Th>
            </Tr>
        </Thead>
    );
};

const SubscriptionsBody = ({
    subscriptions,
    selectedSubscription,
}: {
    subscriptions: Subscription[],
    selectedSubscription: string,
}) => {
    return (
        <Tbody>
            {
                subscriptions.map((s, i) => {
                    return (
                        <Tr key={i}>
                            <RadioCell subscription={s} />
                            <LabelCell subscription={s} />
                            <BalanceCell subscription={s} selectedSubscription={selectedSubscription} />
                            <TypeCell subscription={s} />
                        </Tr>
                    );
                })
            }
        </Tbody>
    );
}

const RadioCell = ({ subscription }: { subscription: Subscription }) => {
    return (
        <Td>
            {
                subscription.active && subscription.balance > 0 &&
                <Radio key={subscription.id} value={subscription.id} />
            }
        </Td>
    );
};

const LabelCell = ({ subscription }: { subscription: Subscription }) => {
    return (
        <Td opacity={subscription.active ? 1.0 : 0.4}>{subscription.orderId + (subscription.active ? "" : " (inactive)")}</Td>
    );
};

const BalanceCell = ({ subscription, selectedSubscription }: { subscription: Subscription, selectedSubscription: string }) => {
    return (
        <Td>{tokensToCredits(subscription.balance)}</Td>
    );
};

const TypeCell = ({ subscription }: { subscription: Subscription }) => {
    return (
        <Td>
            <Flex alignItems="center" flex={1}>
                {subscriptionTypeText(subscription)}
                {subscription.cancelled &&
                    <Flex flex={1}>
                        <Spacer />
                        <WarningColorIcon ml={2} />
                    </Flex>
                }
            </Flex>
        </Td>
    );
};

export default AddTokensModal;
