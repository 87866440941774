import { Box } from "@lawo/lawo-ui";
import BackLayer from "./BackLayer";
import TopLayer from "./TopLayer";


const Lanyard = (): JSX.Element => {
    return (
        <>
            <Box pos="absolute" left="38%" top="-124px">
                <BackLayer />
            </Box>
            <Box
                pos="absolute"
                left="37%"
                h="21px"
                w="40px"
                bgColor="lawoBrandCyan.100"
            />
            <Box pos="absolute" left="46.5%" top="-124px">
                <TopLayer />
            </Box>
        </>
    );
};

export default Lanyard;
