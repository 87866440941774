import {
    Flex,
    Heading,
    useColorModeValue
} from "@lawo/lawo-ui";

type HeaderProps = Record<string, unknown> &{
    children?: any;
};

const Header = ({ children, ...props }: HeaderProps) => {
    const bgColor = useColorModeValue("#fff", "#1d1d1d");

    return (
        <Flex
            align="center"
            p="16px"
            height="56px"
            bg={bgColor}
            borderRadius="8px 8px 0px 0px"
            {...props}
        >
            <Heading>{children}</Heading>
        </Flex>
    );
};

export default Header;
