import {
    Box,
    ToolbarItem,
    EditIcon,
    Toolbar,
    PlusIcon,
    DeleteIcon,
    GridTable,
    MiddleEllipsisSpan,
    useDisclosure,
    useSimpleToast,
    Text,
    Flex,
    useColorModeValue,
    Heading
} from "@lawo/lawo-ui";
import {useSystems} from "hooks";
import { useState } from "react";
import System from "models/System";
import Link from "components/common/Link";
import CreateModal from "./CreateModal";
import { useAuth } from "contexts/AuthContext";
import { deleteSystem } from "api";
import ConfirmModal from "components/common/ConfirmModal";
import { tokensToCredits } from "lib/credits";
import TableToolbar from "pages/common/TableToolbar";

const Systems = () => {
    const [selectedSystemIds, setSelectedSystemIds] = useState([]);
    const systems = useSystems();
    const { currentCustomerAccountId } = useAuth();
    const createModal = useDisclosure();
    const deleteModal = useDisclosure();
    const editModal = useDisclosure();
    const bgColor = useColorModeValue("#fff", "#1d1d1d");

    const first = () => {
        return selectedSystemIds.length > 0 ? systems.find(s => s.id === selectedSystemIds[0]) : undefined;
    }

    const deleteMessage = () => selectedSystemIds.length > 1 ? "Are you sure you want to delete these systems?" :  "Are you sure you want to delete this system?";
    const deleteTitle = () => selectedSystemIds.length > 1 ? "Delete Systems" :  "Delete System";
    const { toast } = useSimpleToast();
    
    const handleDelete = async () => {        
        const allDeletes = Promise.all(selectedSystemIds.map(async (id) => {
            await deleteSystem(currentCustomerAccountId, id);
        }));

        try {
            await allDeletes;
        } catch (error) {
            if (error instanceof Error) {
                let message = error.message;

                if (first()?.tokenTotal > 0) {
                    message = "Revoke tokens before deleting system";
                }

                toast({ id: "delete-system-error", status: "error", title: "Could not delete system", description: message});
            } 
        }

        setSelectedSystemIds([]);
    };

    return (
        <Flex flexDirection="column" flex="1">
            <Flex
                align="center"
                p="16px"
                height="56px"
                bg={bgColor}
                borderRadius="8px 8px 0px 0px"
            >
                <Heading>Systems</Heading>
            </Flex>
            <TableToolbar>
                <ToolbarItem
                icon={PlusIcon}
                onClick={createModal.onOpen}
                borderRadius="4px"
                data-test-id="systems-add-button"
                >
                New
                </ToolbarItem>
                <ToolbarItem
                icon={EditIcon}
                onClick={editModal.onOpen}
                isDisabled={ selectedSystemIds.length !== 1 }
                borderRadius="4px"
                data-test-id="systems-edit-button"
                >
                Edit
                </ToolbarItem>        
                <ToolbarItem
                icon={DeleteIcon}
                onClick={deleteModal.onOpen}
                isDisabled={ selectedSystemIds.length === 0 }
                borderRadius="4px"
                data-test-id="systems-delete-button"
                >
                Delete
                </ToolbarItem>  
            </TableToolbar>
            <Box className="table-parent">
                <GridTable
                    columns={[
                        {
                            accessorKey: "name",
                            header: "Label",
                            id: "name",
                            resizable: true,
                            testId: "name",
                            sortable: false,
                            cell: (props) => <SystemLabelCell rowData={props.cell.row.original as System} />,
                        },
                        {
                            accessorKey: "siteId",
                            header: "System ID",
                            id: "siteId",
                            resizable: true,
                            testId: "siteId",
                            sortable: false,       
                            size: 200,                     
                        },
                        {
                            accessorKey: "tokenTotal",
                            header: "Credits",
                            id: "tokenTotal",
                            resizable: false,
                            testId: "tokenTotal",
                            sortable: false,
                            size: 100,
                            cell: (props) => <CreditsCell rowData={props.cell.row.original as System} />,
                        },
                    ]
                    }
                    data={systems}
                    selectable={
                        {
                            selectedIds: selectedSystemIds,
                            setSelectedIds: setSelectedSystemIds,
                        }
                    }
                    stickyLeft={2}
                    style={{
                        width: "100%",
                        height: "calc(100vh - 212px)",
                        borderRadius: "0px 0px 8px 8px",
                        overflow: "auto",
                    }}
                    data-test-id="system-list"
                />
            </Box>
            <CreateModal {...createModal} />
            <CreateModal {...editModal} initial={first()} />
            <ConfirmModal {...deleteModal} title={deleteTitle()} message={deleteMessage()} requiresConfirm onOk={handleDelete} buttonText="Delete"/>
        </Flex>
    );
};

const CreditsCell = ( { rowData } : { rowData : System} ) => {
    return (
        <>
            <Text>{tokensToCredits(rowData.tokenTotal)}</Text>            
        </>
    );    
}

const SystemLabelCell = ({  rowData }: {  rowData: System}) => {
    return (
        <Link to={`/systems/${rowData.id}`} overflow="hidden" textOverflow="ellipsis">
            <MiddleEllipsisSpan text={rowData.name || "No Label"} />
        </Link>
    );
};

export default Systems;
