import {
    Box,
    Flex,
    Input,
    Select
} from "@lawo/lawo-ui";
import { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { getCountries, getCountryCallingCode, Country } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import labels from 'react-phone-number-input/locale/en.json';
import flags from 'react-phone-number-input/flags';

interface PhoneNumberInputProps {
    value: string;
    onChange: (value: string) => void;
};

const PhoneNumberInput = ({value, onChange, ...rest}: PhoneNumberInputProps): JSX.Element => {
    const [countryCode, setCountryCode] = useState<Country>("GB");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        onChange("+" + getCountryCallingCode(countryCode));
    }, [countryCode]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(handleNavigator, () => console.warn('permission was rejected'));
    }, []);

    const fetchCountry = async ({ latitude, longitude }: { latitude: number, longitude: number} ): Promise<Country> => {
        let result = "DE";
        try {
            const loader = new Loader({
                apiKey: process.env.REACT_APP_MAPS_API_KEY as string,
                version: "weekly",
                libraries: ["geocoding"]
            });
            await loader.importLibrary("geocoding");
            const geocoder = new google.maps.Geocoder();
            await geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
                if (status === "OK") {
                    const [{ address_components }] = results.filter(({ types }: any) => types.includes('country'));
                    const [{ short_name }] = address_components;
                    result = short_name;
                }
            });
        } catch (error) {
            console.error(error);
        }

        return result as Country;
    };

    const handleNavigator = async (position: any) => {
        const { latitude, longitude } = position.coords;
        const userCountryCode = await fetchCountry({ latitude, longitude });
        setCountryCode(userCountryCode);
    };

    const handleCountryCode = (countryCode: Country) => {
        setCountryCode(countryCode);
    };

    return (
        <>
            <Flex flexDirection="row" mb={4}>
                <Box position="relative">
                    <Box position="absolute" m="7px" zIndex="1" pointerEvents="none" width="40px">
                        <SvgCountryFlag cc={countryCode} />
                    </Box>
                    <Select
                        {...rest}
                        borderTopRightRadius={0}
                        borderBottomRightRadius={0}
                        width="80px"
                        onMouseDown={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                        value={value}
                        onChange={(e) => handleCountryCode(e.target.value as Country || undefined)}>
                        {
                            getCountries().map((country) => {
                                return (
                                    <option key={country} value={country}>
                                        { isOpen && labels[country] + " +" + getCountryCallingCode(country) }
                                        { !isOpen && country}
                                    </option>
                                );
                            })
                        }
                    </Select>
                </Box>
                <Input
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                    flex="1"
                    id="phoneNumber"
                    value={value}
                    onChange={(e) => onChange(e.target.value)} />
            </Flex>
        </>
    );
};

const SvgCountryFlag = ({cc}: {cc: Country}) => {
    return flags[cc]({ title: cc });
};

export default PhoneNumberInput;
