import {
    Flex,
    Switch,
    Text,
    Collapse,
    useDisclosure,
    Link,
} from '@lawo/lawo-ui';
import CookieTable from './CookieTable';
import { Cookie, CookieCategory, CookieDescriptions } from "models/Cookie";
import { useEffect, useState } from 'react';

const descriptions: CookieDescriptions = {
    "essential": "Essential cookies enable basic functions and are necessary for the proper function of the website.",
    "statistics": "Statistics cookies collect information anonymously. This information helps us to understand how our visitors use our website.",
    "externalMedia": "Content from video platforms and social media platforms is blocked by default. If External Media cookies are accepted, access to those contents no longer requires manual consent.",
};

interface CookieTableProps {
    cookieCategory: CookieCategory;
    cookies: Cookie[];
    modifyConsents: (category: string, id: string, enabled: boolean) => void;
}

const CookieCategoryItem = ({ cookieCategory, cookies, modifyConsents }: CookieTableProps) => {
    const { isOpen, onToggle } = useDisclosure();
    const [accepted, setAccepted] = useState(false);

    return (
        <Flex>
            <Switch mr="16px" disabled={cookieCategory.essential} isChecked={accepted || cookieCategory.essential} onChange={(e) => setAccepted(e.target.checked)}/>
            <Flex flexDirection="column">
                <Text mb="8px"><b>{cookieCategory.name} ({cookies.length})</b> - {descriptions[cookieCategory.id]} <Link onClick={onToggle}><u>{isOpen ? "Hide" : "Show"} Cookie Information</u></Link></Text>
                { cookies.map((cookie: Cookie, index: number) => {
                    return (
                        <Collapse key={index} in={isOpen} animateOpacity>
                            <Flex p="8px">
                                <CookieTable cookieCategory={cookieCategory} cookie={cookie} categoryAccepted={accepted} modifyConsents={modifyConsents}/>
                            </Flex>
                        </Collapse>
                    );
                })}
            </Flex>
        </Flex>
    );
}

export default CookieCategoryItem;
