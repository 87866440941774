import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Flex,
    Spinner,
    Spacer,
    Box,
    NumberInput,
    NumberInputField,
    Select,
    FormLabel,
    useColorModeValue,
    Heading,
    Checkbox,
    PlusIcon,
    Input,
    MinusIcon,
    InfoIcon,
} from "@lawo/lawo-ui";
import Confirmation from "components/common/Confirmation";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import useProducts from "hooks/useProducts";
import useEntitlements from "hooks/useEntitlements";
import { assignSerialNumber, updateEntitlementsLabel } from "api/entitlements";
import LicenseSummary from "models/LicenseSummary";
  
interface EditLabelModalProps {
    isOpen: boolean;
    onClose: () => void;
    licenseSummary: LicenseSummary;
};

const EditLabelModal = ({ isOpen, onClose, licenseSummary }: EditLabelModalProps) => {
    const [inProgress, setInProgress] = useState(false);
    const [valid, setValid] = useState(false);
    const [label, setLabel] = useState("");
    const { currentCustomerAccountId } = useAuth();

    const handleEditLabel = async () => {
        setInProgress(true);
        try {
            await updateEntitlementsLabel(currentCustomerAccountId, licenseSummary.entitlementPaths, label);
            onClose();
        } catch (error) {
            console.log("Error editing label", error);
        } finally {
            setInProgress(false);
        }
    };

    const handleClose = () => {
        setLabel("");
        onClose();
    };

    useEffect(() => {
        setValid(label.length > 0);
    }, [label]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleEditLabel();
                }}
                >
                    <ModalContent>
                        <ModalHeader>Edit Label</ModalHeader>
                        <ModalBody>
                            <FormLabel htmlFor="label">New Label</FormLabel>
                            <Input
                                id="label"
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}
                                isDisabled={inProgress}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="submit"
                                colorScheme="lawoBlue"
                                mr={3}
                                data-test-id="modal-edit-name-submit-button"
                                isDisabled={!valid || inProgress}
                            >
                                { inProgress &&
                                    <Spinner mr={2}/>
                                }
                                Confirm
                            </Button>
                            <Button
                                onClick={handleClose}
                                data-test-id="modal-edit-name-cancel-button"
                                isDisabled={inProgress}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </>
    );
};

export default EditLabelModal;
