import { useColorModeValue } from "@lawo/lawo-ui";


const BackLayer = (): JSX.Element => {
    const fill = useColorModeValue("#CDCDCD", "#393939");

    return (
        <svg width="51" height="156" viewBox="0 0 51 156" fill="none">
            <path
                d="M23 152L0 -90H24L47 152L51 156H29C26 156 23.5 154 23 152Z"
                fill={fill}
            />
        </svg>
    );
};

export default BackLayer;
