import AuthProviderLink from "./AuthProviderLink";
import ChangePasswordModal from "./ChangePasswordModal";
import EditNameModal from "./EditNameModal";
import EnableMfaModal from "./EnableMfaModal";
import {
  Box,
  Button,
  EditIcon,
  Flex,
  Heading,
  LockIcon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useSimpleToast,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";

const Account = () => {
  const { currentUser, mfaEnabled, unenrollUser } = useAuth();
  const editNameModal = useDisclosure();
  const changePasswordModal = useDisclosure();
  const enableMfaModal = useDisclosure();
  const { toast } = useSimpleToast();
  const color = useColorModeValue("lawoGrey.50", "black");
  const bgColor = useColorModeValue("#f9f9f9", "#242424");

  const enableMfa = async () => {
    if (mfaEnabled) {
      const unenrolled = await unenrollUser();

      if (!unenrolled) {
        toast({
          id: "disable-2fa-failed-error",
          status: "error",
          title: "Failed to disable 2FA",
        });
      }
    } else {
      enableMfaModal.onOpen();
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <Flex flexDirection="column" flex="1">
      <Flex
        flexDirection="column"
        bg={bgColor}
        borderBottomRightRadius="8px"
        height="calc(100vh - 140px)"
        overflow="auto"
      >
        <Stack textAlign="left" spacing="16px" padding={4}>
          <Heading size="lg">{currentUser?.displayName}</Heading>
          <Text>{currentUser?.email}</Text>
          <Button
            width="172px"
            leftIcon={<EditIcon />}
            justifyContent="flex-start"
            onClick={editNameModal.onOpen}
          >
            <Text ml={1}>Edit Name</Text>
          </Button>
          <Button
            width="172px"
            leftIcon={<EditIcon />}
            justifyContent="flex-start"
            onClick={changePasswordModal.onOpen}
          >
            <Text ml={1}>Change Password</Text>
          </Button>
        </Stack>
        <Box mx="16px" borderTop={"1px solid"} borderColor={color} />
        <Stack textAlign="left" spacing="16px" padding={4}>
          <Heading size="md">Two-Factor Authentication</Heading>
          <Text>
            Two-Factor Authentication is{mfaEnabled ? "" : " not"} enabled.
          </Text>
          <Button
            width="132px"
            leftIcon={<LockIcon />}
            justifyContent="flex-start"
            onClick={enableMfa}
          >
            <Text ml={1}>{mfaEnabled ? "Disable" : "Enable"} 2FA</Text>
          </Button>
          <EditNameModal {...editNameModal} initialName={currentUser.displayName} />
          <ChangePasswordModal {...changePasswordModal} />
          <EnableMfaModal {...enableMfaModal} />
        </Stack>
        <Box mx="16px" borderTop={"1px solid"} borderColor={color} />
        <Stack textAlign="left" spacing="16px" padding={4}>
          <Heading size="md">Auth Providers</Heading>
          <Text>
            If you have an account with one of the auth providers below for the
            email address <b>{currentUser?.email}</b> you can click below to link
            it to this Lawo licensing account. You can then use either account to
            log in.
          </Text>
          <AuthProviderLink providerId="apple.com"/>
          <AuthProviderLink providerId="google.com"/>
          <AuthProviderLink providerId="microsoft.com"/>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Account;
