import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot, where, query } from "firebase/firestore"; 
import CustomerUser from "models/CustomerUser";
import { useAuth } from "contexts/AuthContext";

const useCustomerUsers = (): CustomerUser[] => {
    const [data, setData] = useState<CustomerUser[]>([]);
    const { currentCustomerAccountId, currentUser } = useAuth();

    useEffect(() => {
        const q = query(collection(db, "customerUsers"), where("customerIds", "array-contains", currentCustomerAccountId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const customerUsers = snapshot.docs.map((doc: DocumentData) => {
                const customerUser: CustomerUser = {
                    id: doc?.id,
                    ...doc?.data()
                };

                return customerUser;
            }).filter((cu: CustomerUser) => cu.id !== currentUser.uid);

            setData(customerUsers);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, currentUser.uid]);

    return data;
}

export default useCustomerUsers;
