import {
    Box,
    VStack,
    HStack,
    Image,
    Flex,
    Text,
    useSimpleToast,
    Button,
    useColorMode,
    useColorModeValue,
    useDisclosure,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { Lanyard } from "./Lanyard";
import LoginForm from "./LoginForm";
import AppleLogoLight from "assets/svgs/apple_logo_light.svg";
import AppleLogoDark from "assets/svgs/apple_logo_dark.svg";
import GoogleLogoLight from "assets/svgs/google_logo_light.svg";
import GoogleLogoDark from "assets/svgs/google_logo_dark.svg";
import MicrosoftLogoLight from "assets/svgs/microsoft_logo_light.svg";
import MicrosoftLogoDark from "assets/svgs/microsoft_logo_dark.svg";
import { useNavigate } from "react-router-dom";
import { MultiFactorError, MultiFactorResolver, UserCredential } from "firebase/auth";
import { useMemo, useState } from "react";
import Login2FA from "./Login2FA";
import { FirebaseError } from "firebase/app";
import CookiePreferences from "pages/Legal/CookiePreferences";
import { useCookies } from "react-cookie";

const Login = () => {
    const navigate = useNavigate();
    const { toast } = useSimpleToast();
    const { colorMode } = useColorMode();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");
    const color = useColorModeValue("#636363", "#dadada");
    const [AppleLogo, GoogleLogo, MicrosoftLogo] = useMemo(() => {
        return [
            colorMode === "dark" ? AppleLogoDark : AppleLogoLight,
            colorMode === "dark" ? GoogleLogoDark : GoogleLogoLight,
            colorMode === "dark" ? MicrosoftLogoDark : MicrosoftLogoLight,
        ];
    }, [colorMode]);

    const [verificationID, setVerificationID] = useState<string>();
    const [resolver, setResolver] = useState<MultiFactorResolver>();
    const {
        initializeMFARequest,
        loginWithGoogle,
        loginWithApple,
        loginWithMicrosoft,
        isSignInWithEmailLink,
        getRecaptchaVerifier,
    } = useAuth();
    const isEmailLink = isSignInWithEmailLink(window.location.href);
    const cookiePreferencesDialog = useDisclosure();
    const [cookies] = useCookies(["lawoFlexPortal"]);
    
    const handleMFA = async (error: unknown) => {
        if (!(error instanceof FirebaseError)) {
            return;
        }

        const response = error as FirebaseError;
        const recaptcha = getRecaptchaVerifier("recaptcha-container-id");
        if (response.code === 'auth/multi-factor-auth-required') {
            const data = await initializeMFARequest(response as MultiFactorError, recaptcha);

            if (!data) {
                toast({ id: "login-2fa-error", status: "error", title: "2FA failed", description: response.code });
            } else {
                const { verificationID, resolver } = data;
                setVerificationID(verificationID);
                setResolver(resolver);
            }
        } else {
            if (response.code === 'auth/wrong-password') {
                toast({ id: "login-wrong-password-error", status: "error", title: "Login failed", description: "Please check your credentials and try again." });
            } else {
                toast({ id: "login-failed-error", status: "error", title: "Login failed", description: "Invalid email. Please try again or contact Lawo support." });
            }
        }

        recaptcha.clear();
    };

    const handleSignInWith = async (loginFunction: () => Promise<UserCredential>) => {
        try {
            await loginFunction();
            navigate("/subscriptions");
        } catch (error) {
            await handleMFA(error);
        }
    };

    const handleCancel = () => {
        setVerificationID(undefined);
        setResolver(undefined);
    };

    return (
        <>
            {
                verificationID && resolver &&
                <Login2FA verificationID={verificationID} resolver={resolver} handleCancel={handleCancel}/>
            }
            {
                !verificationID && !resolver &&
                <Flex
                    justifyContent="center"
                    h="100%"
                    w="100%"
                    pos="relative"
                    overflow="auto"
                    borderRadius="8px"
                    bg={bgColor}
                >
                    <Box>
                        <VStack pos="relative" top="124px">
                            <Lanyard />
                            <VStack
                                bgColor="lawoBrandCyan.100"
                                width="320px"
                                borderRadius="16px"
                                p={6}
                                pb={4}
                                boxShadow="0px 32px 64px rgba(0, 0, 0, 0.11), 0px 16px 32px rgba(0, 0, 0, 0.11), 0px 8px 16px rgba(0, 0, 0, 0.11), 0px 4px 8px rgba(0, 0, 0, 0.11), 0px 2px 4px rgba(0, 0, 0, 0.11), 0px 1px 2px rgba(0, 0, 0, 0.11);"
                            >
                                <LoginForm handleMFA={handleMFA}/>
                            </VStack>
                            {
                                !verificationID && !resolver &&
                                <VStack>
                                    {
                                        !isEmailLink &&
                                        <Button
                                            mt={6}
                                            w="272px"
                                            onClick={() => navigate("/resetPassword")}>
                                                <Text color={color}>Reset Password</Text>
                                        </Button>
                                    }
                                    <Text mt={3}>Sign In With</Text>
                                    <HStack>
                                        <Image onClick={() => handleSignInWith(loginWithApple)} cursor="pointer" src={AppleLogo} />
                                        <Image onClick={() => handleSignInWith(loginWithGoogle)} cursor="pointer" src={GoogleLogo} />
                                        <Image onClick={() => handleSignInWith(loginWithMicrosoft)} cursor="pointer" src={MicrosoftLogo} />
                                    </HStack>
                                </VStack>
                            }
                        </VStack>
                    </Box>
                    <CookiePreferences {...cookiePreferencesDialog} isCookiesOpen={!cookies.lawoFlexPortal}/>
                    <div id="recaptcha-container-id"></div>
                </Flex>
            }
        </>
    );
};

export default Login;
