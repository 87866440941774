import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { doc, DocumentData, onSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import LicenseSummary from "models/LicenseSummary";

const useLicenses = (): LicenseSummary[] => {
    const [data, setData] = useState<LicenseSummary[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }

        const summariesPath = "customerAccounts/" + currentCustomerAccountId + "/summaries";
        const unsubscribeLicenses = onSnapshot(doc(db, summariesPath, "licenses"), (doc) => {
            const licenseSummaryData = doc.data() as DocumentData;
            if (licenseSummaryData) {
                const licenseSummaries: LicenseSummary[] = [];
                Object.keys(licenseSummaryData).forEach((key) => {
                    const licenseSummary = licenseSummaryData[key] as LicenseSummary;
                    licenseSummary.id = key;
                    licenseSummaries.push(licenseSummary);
                });

                setData(licenseSummaries);
            }
        });

        return () => unsubscribeLicenses();
    }, [currentCustomerAccountId]);

    return data;
}

export default useLicenses;
