import { db } from "firebase.config";
import { doc, getDoc, getDocFromCache } from "firebase/firestore"; 
import { useEffect, useState } from "react";

const fetchCustomerName = async (customerId: string) => {    
    const docRef = doc(db, `customerAccounts/${customerId}`);
    try {
        const docSnap = await getDocFromCache(docRef); 
        if (docSnap.exists()) {
            return docSnap.data()?.name;
        }
    } catch {}
    try {
        // try from the network
        const docSnapNetwork = await getDoc(docRef);
        if (docSnapNetwork.exists()) {
            return docSnapNetwork.data()?.name;
        }
    } catch (e) {
        console.error(e);
    }
    return null;
}

const getCustomerNames = async (customerIds: string[]) => {
    const customerNames: Map<string, string> = new Map<string, string>();
    await Promise.all(customerIds.map(async (customerId) => {
        const customerName = await fetchCustomerName(customerId);
        if (customerName) {
            customerNames.set(customerId, customerName);
        }
    }));
    return customerNames;
}; 



// Hook returns the list of customer names when given a list of customers ids
const useCustomerNames = (customerIds: string[]) : Map<string, string> => {
    const [customerNames, setCustomerNames] = useState<Map<string,string>>(new Map<string, string>());
    useEffect(() => {        
        getCustomerNames(customerIds).then((customerNames) => {
            setCustomerNames(customerNames);
        });
    }, [customerIds]);
    return customerNames;
};

export default useCustomerNames;