import { Timestamp } from "firebase/firestore";

export const formatTimestampAsDuration = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / (1000));
    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else if (seconds > 0) {
        return `${seconds} seconds ago`;
    } else {
        return `just now`;
    }
};

// formats  the date in UTC as 'Wed, 11 Oct 2023'
export const formatUTCDate = (date: Date) => {
    if (!date) return '';
    return date.toUTCString().substring(0, 16);
}

export const formatUTCDateWithTime = (date: Date) => {
    if (!date) return '';
    return date.toUTCString().substring(0, 25);
}

export const secondsSinceTimestamp = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / (1000));
    if (seconds < 0) {
        return 0;
    }
    return seconds;
}

export const timeStampToDate = (ts: Timestamp | string): Date => {
    if (ts === undefined) return new Date();
    if (typeof ts === "string") {
        return new Date(ts);
    }
    return ts.toDate();
}

export const calculateEndDate = (activated: Timestamp | string, billingInterval: number): Date => {

    let end = timeStampToDate(activated);

    // work out hours since activated to now
    const timeSince = (date: Date) => {
        const now = new Date();
        return now.getTime() - date.getTime();
    };
    // convert to hours
    const hoursSince = timeSince(end) / 1000 / 60 / 60;

    // calculate the number of months since the subscription was activated
    let months = Math.ceil(hoursSince / 24 / 30);
    if (months === 0) {
        months = 1
    }

    // add this many months to the activated date and set the time to 23:59:59
    end.setMonth(end.getMonth() + (months * billingInterval));
    end.setHours(23, 59, 59, 0);
    return end;
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));