import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    TextInput,
    useSimpleToast,
} from "@lawo/lawo-ui";

import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import CustomerUser from "models/CustomerUser";
import { useCustomerUsers} from "hooks";
import { createOrUpdateUser } from "../../api";
import { validateEmail } from "lib/validateEmail";

interface CreateModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    initial?: CustomerUser;
}

const CreateModal = ({ isOpen, onClose, initial }: CreateModalProps) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [puaAccepted, setPuaAccepted] = useState(false);
    const [valid, setValid] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { currentCustomerAccountId } = useAuth();
    const customerUsers = useCustomerUsers();
    const { toast } = useSimpleToast();

    useEffect(() => {
        setName(initial ? initial.name : "");
        setEmail(initial ? initial.email : "");
        setRole(initial ? initial.roles[currentCustomerAccountId] : "");
        setPuaAccepted(initial ? initial.puaAccepted : false);
    }, [initial]);

    useEffect(() => {
        let valid = name?.length !== 0 && validateEmail(email) && role.length !== 0;
        setValid(valid);
    }, [name, email, role]);


    const handleCreateOrUpdate = async () => {
        if (!initial && customerUsers.some(user => user.email === email)) {
            toast({ id: "create-update-customer-user-exists-error", status: "error", title: "Create user failed", description: "User with this email address already exists." });
            return;
        }
        try {
            

            await createOrUpdateUser(currentCustomerAccountId, name, email, role, puaAccepted);
        } catch (e) {
            toast({ id: "create-update-customer-user-error", status: "error", title: "Create user failed", description: "Something went wrong, please try again" });
        }
    };

    const haveSave = async () => {
        try {
            setLoading(true);
            await handleCreateOrUpdate();            
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            onModalClose();
        }
    };

    const onModalClose = () => {
        setName(initial ? initial.name : "");
        setEmail(initial ? initial.email : "");
        setRole(initial ? initial.roles[currentCustomerAccountId] : "");
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                haveSave();
            }}
            >
                <ModalContent>
                    <ModalHeader>{initial ? "Edit" : "Add"} User</ModalHeader>
                    <ModalBody>
                        <Stack spacing="16px">
                            <FormControl mt="4">
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <TextInput
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel htmlFor="email">Email Address</FormLabel>
                                <TextInput
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="role">Role</FormLabel>
                                <Select
                                    id="role"
                                    name="role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option key="empty" value="">Please select a role</option>
                                    <option key="admin" value="admin">Admin</option>
                                    <option key="operator" value="operator">Operator</option>
                                </Select>
                            </FormControl>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme="lawoBlue"
                            mr={3}
                            data-test-id="modal-create-user-submit-button"
                            isDisabled={!valid || isLoading}
                        >
                            {initial ? "Update" : "Create"}
                        </Button>
                        <Button onClick={onModalClose} data-test-id="modal-create-user-cancel-button">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};

export default CreateModal;
