import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot } from "firebase/firestore"; 
import ProductCode from "models/ProductCode";
import { useAuth } from "contexts/AuthContext";

const useProducts = (): ProductCode[] => {
    const [data, setData] = useState<ProductCode[]>([]);
    const { currentCustomerAccountId } = useAuth();
    
    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }
        
        const path = "productCodes";
        const unsubscribe = onSnapshot(collection(db, path), (snapshot) => {
            const productCodes = snapshot.docs.map((doc: DocumentData) => {
                const data = doc.data();
                const productCode: ProductCode = {
                    id: doc.id,
                    ...(data as ProductCode)
                };

                return productCode;
            });

            setData(productCodes);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId]);

    return data;
}

export default useProducts;
