import {
    Toolbar,
    useColorModeValue
} from "@lawo/lawo-ui";

interface TableToolbarProps {
    children: JSX.Element[] | JSX.Element;
}; 

const TableToolbar = ({ children }: TableToolbarProps) => {
    const bgColor = useColorModeValue("#fff", "#1d1d1d");

    return (
        <Toolbar
            p="8px"
            height="72px"
            gap="8px"
            bg={bgColor}
        >
            { children }
        </Toolbar>
    );
};

export default TableToolbar;
