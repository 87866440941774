import {
    Box,
    Flex,
    GridTable,
    ToolbarItem,
    useDisclosure,
    DownloadIcon,
    Text,
    PlusIcon,
    EditIcon,
} from '@lawo/lawo-ui';
import TableToolbar from 'pages/common/TableToolbar';
import { useState } from 'react';
import { downloadPerpetualLicense } from 'api/entitlements';
import useLicenses from 'hooks/useLicenses';
import LicenseSummary from 'models/LicenseSummary';
import NewLicenseModal from './NewLicenseModal';
import { useAuth } from 'contexts/AuthContext';
import EditLabelModal from './EditLabelModal';

const Licenses = () => {
    const [selectedIds, setSelectedIds] = useState(new Array<string>());
    const licenseSummaries = useLicenses();
    const newLicenseModal = useDisclosure();
    const editLabelModal = useDisclosure();
    const { currentCustomerAccountId } = useAuth();

    const handleSelect = (selectedRowKeys: Array<string>) => {
        setSelectedIds(selectedRowKeys);
    };

    const downloadLicenses = async () => {
        const entitlementPaths: string[] = [];
        selectedIds.forEach((id) => {
            const license = licenseSummaries.find((license) => license.id === id);
            if (license) {
                entitlementPaths.push(...license.entitlementPaths);
            }
        });

        await downloadSelectedLicenses(entitlementPaths);
    };

    const downloadSelectedLicenses = async (entitlementPaths: string[]) => {
        const result = await downloadPerpetualLicense(currentCustomerAccountId, entitlementPaths);
        const licenses = result.licenses as any;

        licenses.forEach((license: any) => {
            const element = document.createElement("a");
            const file = new Blob([license.jwt], { type: "text/plain" });
            element.href = URL.createObjectURL(file);
            element.download = license.filename;
            document.body.appendChild(element);
            element.click();
        });
    }

    return (
        <Flex flexDirection="column">
            <TableToolbar>
                <ToolbarItem
                    icon={PlusIcon}
                    onClick={newLicenseModal.onOpen}
                    isDisabled={selectedIds.length > 0}
                    borderRadius="4px"
                    data-test-id="perpetuals-assign-button"
                >
                    New
                </ToolbarItem>
                <ToolbarItem
                    icon={EditIcon}
                    onClick={editLabelModal.onOpen}
                    isDisabled={selectedIds.length !== 1}
                    borderRadius="4px"
                    data-test-id="perpetuals-assign-button"
                >
                    Edit
                </ToolbarItem>
                <ToolbarItem
                    icon={DownloadIcon}
                    onClick={downloadLicenses}
                    isDisabled={selectedIds.length === 0}
                    borderRadius="4px"
                    data-test-id="perpetuals-download-button"
                >
                    Download
                </ToolbarItem>
            </TableToolbar>
            <Box className="table-parent">
                <GridTable
                    columns={[
                        {
                            accessorKey: "label",
                            header: "Label",
                            id: "label",
                            resizable: true,
                            testId: "label",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "productName",
                            header: "Product",
                            id: "productName",
                            resizable: true,
                            testId: "productName",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "lockedTo",
                            header: "ID",
                            id: "lockedTo",
                            resizable: true,
                            testId: "lockedTo",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "entitlementIds",
                            header: "Entitlements",
                            id: "entitlementIds",
                            resizable: true,
                            testId: "entitlementIds",
                            sortable: true,
                            size: 100,
                            cell: (props) => <BalanceCell rowData={props.cell.row.original as LicenseSummary} />,
                        },
                    ]}
                    data={licenseSummaries}
                    defaultSort={[{ id: "lockedTo", desc: true }]}
                    emptyMessage="No perpetual licenses have been added to the system"
                    selectable={{
                        selectedIds,
                        setSelectedIds: handleSelect,
                    }}
                    style={{
                        width: "100%",
                        height: "calc(100vh - 212px)",
                        overflow: "auto",
                        borderRadius: "0px 0px 8px 8px",
                        padding: "8px",
                    }}
                    data-test-id="perpetuals-list"
                />
            </Box>
            <NewLicenseModal
                isOpen={newLicenseModal.isOpen}
                onClose={newLicenseModal.onClose}
                downloadSelectedLicenses={downloadSelectedLicenses}
            />
            <EditLabelModal
                isOpen={editLabelModal.isOpen}
                onClose={editLabelModal.onClose}
                licenseSummary={licenseSummaries.find((license: LicenseSummary) => license.id === selectedIds[0])}
            />
        </Flex>
    );
};

const BalanceCell = ({ rowData }: { rowData: LicenseSummary }) => {
    return (
        <Flex flex={1} flexDirection="column">
            <Text>{rowData.entitlementIds.length}</Text>
        </Flex>
    );
};

export default Licenses;
