import {
    Button,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useState } from "react";

interface EditNameModalProps {
    initialName?: string;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
}

const EditNameModal = ({ initialName, isOpen, onClose }: EditNameModalProps) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(initialName);
    const {
        updateName,
    } = useAuth();
    
    const lettersSpaces = /[\p{Letter}\p{Mark}\s]+/gu;
    const valid = () => {
        return name?.length > 0 && lettersSpaces.test(name) && name !== initialName;
    };

    const handleEditName = async () => {
        setLoading(true);

        try {
            await updateName(name);
            onClose();
        } catch (error) {
            console.log("Error editing name", error);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setName(initialName);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeModal} motionPreset="scale">
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                handleEditName();
            }}
            >
                <ModalContent>
                    <ModalHeader>Edit Name</ModalHeader>
                    <ModalBody>
                        <FormLabel htmlFor="name">New Name</FormLabel>
                        <Input id="name" mb={6} onChange={(a) => setName(a.target.value)} value={name} isDisabled={loading} />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme="lawoBlue"
                            mr={3}
                            data-test-id="modal-edit-name-submit-button"
                            isDisabled={!valid() || loading}
                        >
                            {loading &&
                            <Spinner mr={2}/>}
                            Change Name
                        </Button>
                        <Button onClick={closeModal} data-test-id="modal-edit-name-cancel-button">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};

export default EditNameModal;
