import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot, QuerySnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import Voucher from "models/Voucher";

const useVouchers = (): Voucher[] => {
    const [data, setData] = useState<Voucher[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }

        const path = "customerAccounts/" + currentCustomerAccountId + "/vouchers";
        const unsubscribe = onSnapshot(collection(db, path), (snapshot: QuerySnapshot<DocumentData>) => {
            const vouchers = snapshot.docs.map((doc: DocumentData) => {
                const data = doc.data();
                const voucher: Voucher = {
                    id: doc.id,
                    ...(data as Voucher)
                };

                return voucher;
            });

            setData(vouchers);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId]);

    return data;
}

export default useVouchers;
