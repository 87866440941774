import {
    Button,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useSimpleToast,
    useToast,
    Text,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberInput from "./PhoneNumberInput";
import MFACodeInput from "pages/MultiFactorAuthentication/MFACodeInput";

interface EnableMfaModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
}

const EnableMfaModal = ({ isOpen, onClose }: EnableMfaModalProps) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [verificationCodeID, setVerificationCodeID] = useState<string | null>(null)
    const [loading, setLoading] = useState(false);
    const { toast } = useSimpleToast();
    const successToast = useToast();
    const {
        currentUser,
        enrollUser,
        verifyPhoneNumber,
        reauthenticate,
        getRecaptchaVerifier,
        getErrorMessage,
    } = useAuth();

    const getVerificationId = async (phoneNumber: string) => {
        if (!currentUser) {
            return;
        }

        const recaptchaVerifier = getRecaptchaVerifier("sign-up");
        try {
            await reauthenticate(currentPassword);
            const verificationID = await verifyPhoneNumber(phoneNumber, recaptchaVerifier);
            if (verificationID) {
                setVerificationCodeID(verificationID);
            }
        } catch (error) {
            toast({ id: "register-phone-failed-error", status: "error", title: "Phone registration failed", description: getErrorMessage(error) });
        }

        recaptchaVerifier.clear();
    };

    const submit = async (code: string) => {
        setLoading(true);
        const response = await enrollUser(verificationCodeID, code);

        if (response) {
            closeModal();
            successToast({ title: "Two Factor Authentication", description: "2FA successfully enabled for " + phoneNumber, status: "success", isClosable: true });
        } else {
            toast({ id: "submit-2fa-failed-error", status: "error", title: "2FA failed!" });
        }
        setLoading(false);
    };

    const valid = () => {
        if (!phoneNumber || !currentPassword) {
            return false;
        } else {
            return isPossiblePhoneNumber(phoneNumber);
        }
    };

    const closeModal = () => {
        setVerificationCodeID(null);
        setPhoneNumber("");
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeModal} motionPreset="scale">
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                getVerificationId(phoneNumber);
            }}
            >
                <ModalContent>
                    <ModalHeader>Enable Two-Factor Authentication</ModalHeader>
                    <ModalBody>
                        { !verificationCodeID &&
                            <>
                                <FormLabel htmlFor="currentPassword">Current Password</FormLabel>
                                <Input id="currentPassword" type="password" mb={6} onChange={(a) => setCurrentPassword(a.target.value)} />
                                <Text>Please enter your phone number (e.g. +491711234567). You will receive a text message with a 6 digit code.</Text>
                                <FormLabel htmlFor="phoneNumber" mt={6} mb={2}>Phone Number</FormLabel>
                                <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} />
                            </>
                        }
                        { verificationCodeID &&
                            <>
                                <Text ml={10} mt={4}>Please enter your 6 digit code</Text>
                                <MFACodeInput submit={submit} m={4} justify="center"/>
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        { !verificationCodeID &&
                            <Button
                                type="submit"
                                colorScheme="lawoBlue"
                                mr={3}
                                data-test-id="modal-enable-2fa-submit-button"
                                isDisabled={!valid()  || loading}
                            >
                                Send Confirmation Code
                            </Button>
                        }
                        <Button onClick={closeModal} data-test-id="modal-enable-2fa-cancel-button">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
            <div id="sign-up"></div>
        </Modal>
    );
};

export default EnableMfaModal;
