import {
    Box,
    Text,
    Flex,
    useSimpleToast,
    useColorModeValue,
    Heading,
    Button,
} from "@lawo/lawo-ui";
import Loading from "components/common/Loading";
import Title from "components/common/Title";
import { useAuth } from "contexts/AuthContext";
import { FirebaseError } from "firebase/app";
import { ActionCodeErrors } from "lib/actionCodeErrors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Revert2FAProps {
    oobCode: string;
};

const Revert2FA = ({ oobCode }: Revert2FAProps) => {
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const { checkActionCode, applyActionCode } = useAuth();
    const { toast } = useSimpleToast();
    const navigate = useNavigate();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");

    useEffect(() => {
        const getPhoneNumber = async () => {
            setLoading(true);
            try {
                const number = await checkActionCode(oobCode);
                setPhoneNumber(number);
            } catch (error) {
                if (error instanceof FirebaseError) {
                    toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: ActionCodeErrors[error.code] });
                } else {
                    toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: "Unknown error. Please try again or contact Lawo support." });
                    console.error("Invalid action code", oobCode, error);
                }
                navigate("/login");
            } finally {
                setLoading(false);
            }
        };

        getPhoneNumber();
    }, []);

    const disable2FA = async () => {
        setLoading(true);
        try {
            await applyActionCode(oobCode);
            toast({ id: "action-code-success", status: "success", title: "Email Link Success", description: "Your 2FA has been disabled." });
            navigate("/login");
        } catch (error) {
            if (error instanceof FirebaseError) {
                toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: ActionCodeErrors[error.code] });
            } else {
                toast({ id: "action-code-error", status: "error", title: "Email Link Error", description: "Unknown error. Please try again or contact Lawo support." });
                console.error("Invalid action code", oobCode, error);
            }
            console.log("2FA not disabled", error);
            navigate("/login");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Flex direction="column" height="100vh" minHeight="500px" bg={bgColor} borderRadius="4px">
            <Title p="4px"/>
            <Flex justifyContent="center" alignItems="center" height="100%" overflow="hidden">
                <Flex direction="column" w="256px" h="328px">
                    <Heading mb={12}>Disable 2FA</Heading>
                    <Text mb={12}>Disable 2FA for {phoneNumber}.</Text>
                    <Flex direction="row" justify="flex-end">
                        <Button colorScheme="lawoBlue" color="white" onClick={disable2FA}>Disable 2FA</Button>
                        <Button ml={2} isDisabled={loading} onClick={() => navigate("/login")}>Cancel</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Revert2FA;
