import "@fontsource/inter/variable-full.css";
import ReactDOM from 'react-dom/client';
import Providers from 'Providers';
import App from 'App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(<Providers><App /></Providers>);
