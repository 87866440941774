import {
    Box,
    Flex,
    GridTable,
    Text,
} from '@lawo/lawo-ui';
import useEntitlements from 'hooks/useEntitlements';
import EntitlementSummary from 'models/EntitlementSummary';

const Entitlements = () => {
    const entitlementSummaries = useEntitlements();

    return (
        <Flex flexDirection="column">
            <Box className="table-parent">
                <GridTable
                    columns={[
                        {
                            accessorKey: "name",
                            header: "Entitlements",
                            id: "name",
                            resizable: true,
                            testId: "name",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "productName",
                            header: "Product",
                            id: "productName",
                            resizable: true,
                            testId: "productName",
                            sortable: true,
                            size: 140,
                        },
                        {
                            accessorKey: "partNumber",
                            header: "Part Number",
                            id: "partNumber",
                            resizable: true,
                            testId: "partNumber",
                            sortable: true,
                            size: 140,
                        },
                        {
                            accessorKey: "total",
                            header: "Total",
                            id: "total",
                            resizable: true,
                            testId: "total",
                            sortable: true,
                            size: 100,
                            cell: (props) => <TotalCell rowData={props.cell.row.original as EntitlementSummary} />,
                        },
                        {
                            accessorKey: "used",
                            header: "Used",
                            id: "used",
                            resizable: true,
                            testId: "used",
                            sortable: true,
                            size: 100,
                            cell: (props) => <UsedCell rowData={props.cell.row.original as EntitlementSummary} />,
                        },
                        {
                            accessorKey: "remain",
                            header: "Remain",
                            id: "remain",
                            resizable: true,
                            testId: "remain",
                            sortable: true,
                            size: 100,
                            cell: (props) => <BalanceCell rowData={props.cell.row.original as EntitlementSummary} />,
                        },
                    ]}
                    data={entitlementSummaries.filter((entitlementSummary: EntitlementSummary) => entitlementSummary.productName !== "Flex")}
                    defaultSort={[{ id: "name", desc: true }]}
                    emptyMessage="No perpetual licenses have been added to the system"
                    style={{
                    width: "100%",
                    height: "calc(100vh - 140px)",
                    overflow: "auto",
                    borderRadius: "0px 0px 8px 8px",
                    padding: "8px",
                    }}
                    data-test-id="perpetuals-list"
                />
            </Box>
        </Flex>
    );
};

const BalanceCell = ({ rowData }: { rowData: EntitlementSummary }) => {
    const unused = rowData.unusedPaths?.length || 0;
    return (
        <Flex flex={1} flexDirection="column">
            <Text>{unused}</Text>
        </Flex>
    );
};

const TotalCell = ({ rowData }: { rowData: EntitlementSummary }) => {
    const used = rowData.usedPaths?.length || 0;
    const unused = rowData.unusedPaths?.length || 0;
    return (
        <Flex flex={1} flexDirection="column">
            <Text>{used + unused}</Text>
        </Flex>
    );
};

const UsedCell = ({ rowData }: { rowData: EntitlementSummary }) => {
    const used = rowData.usedPaths?.length || 0;
    return (
        <Flex flex={1} flexDirection="column">
            <Text>{used}</Text>
        </Flex>
    );
}

export default Entitlements;
