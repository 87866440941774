import {  funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";

interface PublishToAegisRequestProps {
    "siteId": string,
    "message": string,        
};

interface PublishToAegisResponseProps {
    "msg": string,        
};

export const refreshConnectionStatus = async (siteId : string) => {
    if (siteId?.length !== 20) {
        throw new Error("Invalid siteId");
    }
    try {
        const callPublishToAegisHttp = httpsCallable<PublishToAegisRequestProps, PublishToAegisResponseProps>(funcs, "publishToAegisHttp");
        await callPublishToAegisHttp({
            "siteId": siteId,
            "message": "pingme",            
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not call cloud function");
    }
};

export const publishNameChange = async (siteId : string, name : string) => {
    if (siteId?.length !== 20) {
        throw new Error("Invalid siteId");
    }
    if (!name) {
        throw new Error("Invalid name");
    }
    try {
        const callPublishToAegisHttp = httpsCallable<PublishToAegisRequestProps, PublishToAegisResponseProps>(funcs, "publishToAegisHttp");
        await callPublishToAegisHttp({
            "siteId": siteId,
            "message": "namechange:"+name,
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not call cloud function");
    }
}; 