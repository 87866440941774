import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot, QuerySnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import Subscription from "models/Subscription";

const useSubscriptions = (): Subscription[] => {
    const [data, setData] = useState<Subscription[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }

        const path = "customerAccounts/" + currentCustomerAccountId + "/subscriptions";
        const unsubscribe = onSnapshot(collection(db, path), (snapshot: QuerySnapshot<DocumentData>) => {
            const subscriptions = snapshot.docs.map((doc: DocumentData) => {
                const data = doc.data();
                const subscription: Subscription = {
                    id: doc.id,
                    ...(data as Subscription)
                };

                return subscription;
            });

            setData(subscriptions);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId]);

    return data;
}

export default useSubscriptions;
