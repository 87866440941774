import { Box } from "@lawo/lawo-ui";
import { Link as RLink } from "react-router-dom";

const Link = (props: any) => {
  return (
    <Box
      as={RLink}
      borderBottom="1px solid"
      borderBottomColor="lawoGrey.100"
      lineHeight="1.4em"
      cursor="pointer"
      userSelect="none"
      style={{
        whiteSpace: "nowrap",
      }}
      display="inline-block"
      {...props}
    />
  );
};

export const LinkedCell = (props: any) => {
  return (
    <Link
      borderBottom="1px dotted"
      borderBottomColor="lawoGrey.100"
      lineHeight="1.4em"
      cursor="pointer"
      userSelect="none"
      {...props}
    />
  );
};

export default Link;
