import React from "react";
import { Box } from "@lawo/lawo-ui";

interface Props {
    children: React.ReactNode;
}

const Bold = ({ children }: Props): JSX.Element => {
  return (
    <Box as="span" fontWeight="bold">
      {children}
    </Box>
  );
};

export default Bold;
