import {
    Flex,
    useColorModeValue,
    useSimpleToast,
    Heading,
    Button,
    Text,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/common/Title";
import MFACodeInput from "pages/MultiFactorAuthentication/MFACodeInput";
import { MultiFactorResolver, RecaptchaVerifier } from "firebase/auth";

type Props = {
    verificationID: string,
    resolver: MultiFactorResolver,
    handleCancel: () => void,
};

const Login2FA = ({ verificationID, resolver, handleCancel }: Props) => {
    const { verifyMFACode } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { toast } = useSimpleToast();
    const bgColor = useColorModeValue("#f9f9f9", "#242424");

    const submit = async (code: string) => {
        setLoading(true);
        const response = await verifyMFACode(verificationID, resolver, code);
        setLoading(false);

        if (response) {
            navigate("/settings");
        } else {
            toast({ id: "login-failed-error", status: "error", title: "Login failed" });
        }
    };

    return (
        <Flex direction="column" height="100vh" minHeight="500px" bg={bgColor} borderRadius="4px">
            <Title p="4px"/>
            <Flex justifyContent="center" alignItems="center" height="100%" overflow="hidden">
                <Flex direction="column" w="256px" h="328px">
                    <Heading mb={12}>Enter Code</Heading>
                    <Text mb={4}>Please enter your 6 digit code</Text>
                    <MFACodeInput submit={submit} mb={8} />
                    <Flex direction="row" justify="flex-end">
                        <Button ml={2} isDisabled={loading} onClick={handleCancel}>Cancel</Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Login2FA;
