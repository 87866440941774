import {
    AccountIcon,
    Box,
    Button,
    ButtonGroup,
    DarkModeIcon,
    Flex,
    LightModeIcon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SettingsIcon,
    SignOutIcon,
    Text,
    useColorMode,
    useColorModeValue,
    useDisclosure,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


const UserButton = (): JSX.Element => {
    const { colorMode } = useColorMode();
    const [colorSettings, setColorSettings] = useState("");
    const { onOpen, isOpen, onClose } = useDisclosure();
    const borderColor = useColorModeValue("lawoGrey.50", "black");
    const hoverColor = useColorModeValue("#eeeeee", "#282828");
    const buttonBackground = useColorModeValue("#f9f9f9", "#242424");
    const expandedColor = { light: "white", dark: "black" };
    const textColor = useColorModeValue("#636363", "#dadada");
    const { setColorMode } = useColorMode();
    const { logout, displayName } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
        }
        catch {
            console.log("Failed to log out");
        }
    };

    const handleSettings = () => {
        onClose();
        navigate("/settings");
    };

    useEffect(() => {
        const setAutoColorMode = () => {
            window.localStorage.setItem('colorMode', "Auto");
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                setColorMode("dark");
            } else {
                setColorMode("light");
            }
        };

        if (colorSettings === "") {
            const storedColorMode = window.localStorage.getItem('colorMode');
            if (storedColorMode !== null) {
                setColorSettings(storedColorMode);
            } else {
                setAutoColorMode();
            }
        } else if (colorSettings === "Light") {
            setColorMode("light");
            window.localStorage.setItem('colorMode', "Light");
        } else if (colorSettings === "Dark") {
            setColorMode("dark");
            window.localStorage.setItem('colorMode', "Dark");
        } else if (colorSettings === "Auto") {
            setAutoColorMode();
        }
    }, [colorSettings, setColorMode]);

    return (
        <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
                <Button
                    display="block"
                    boxShadow="none"
                    p={2}
                    border={0}
                    h="56px"
                    borderRadius="4px"
                    bg={buttonBackground}
                    color={textColor}
                    _hover={{
                        bg: hoverColor,
                    }}
                    _expanded={{
                        bg: "lawoBlue.100",
                        color: expandedColor[colorMode],
                    }}
                    _focus={{
                        boxShadow: "none",
                        border: 0,
                    }}
                    onClick={() => (isOpen ? onClose() : onOpen())}
                >
                    <span style={{ display: "block" }}>
                        <AccountIcon fill={isOpen ? expandedColor[colorMode] : textColor} />
                        <Text color={isOpen ? expandedColor[colorMode] : textColor}>{ displayName }</Text>
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent borderRadius="16px" border="none" mr={4}>
                <PopoverBody>
                    <Text mb={4}>Appearance</Text>
                    <Flex justifyContent="center">
                        <ButtonGroup
                            options={["Light", "Dark", "Auto"]}
                            icons={[<LightModeIcon />, <DarkModeIcon />]}
                            value={colorSettings}
                            defaultValue="Light"
                            onChange={(value: any) => setColorSettings(value)}
                        />
                    </Flex>
                    <Box mt={4} mb={4} borderTop={"1px solid"} borderColor={borderColor} />
                    <Flex direction="column" align="start">
                        <Button color={textColor} width="100%" justifyContent="start" boxShadow="none" bg="none" border={0} borderRadius="4px" onClick={handleSettings}>
                            <SettingsIcon mr={2} />
                            Account Settings
                        </Button>
                        <Button color={textColor} width="100%" justifyContent="start" boxShadow="none" bg="none" border={0} borderRadius="4px" onClick={handleLogout}>
                            <SignOutIcon mr={2} />
                            Sign Out
                        </Button>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default UserButton;
