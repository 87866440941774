import {
  Box,
  Button,
  CheckIcon,
  ChevronLeftIcon,
  DeleteIcon,
  DownloadIcon,
  Flex,
  GridTable,
  Heading,
  MiddleEllipsisSpan,
  PlusIcon,
  Progress,
  RefreshIcon,
  Spinner,
  Text,
  ToolbarItem,
  Tooltip,
  UnusedIcon,
  WarningColorIcon,
  useColorModeValue,
  useDisclosure,
  useSimpleToast,
  useToast,
} from '@lawo/lawo-ui';
import {
  ManualRevokeError,
  assertEquivalence,
  cancelRevokeEquivalence,
  revokeEquivalence,
} from 'api';
import { refreshConnectionStatus } from 'api';
import ConfirmModal from 'components/common/ConfirmModal';
import { useAuth } from 'contexts/AuthContext';
import { Timestamp } from 'firebase/firestore';
import { useEquivalences, useSiteConnection, useSystem } from 'hooks';
import {
  calculateEndDate,
  formatUTCDate,
  secondsSinceTimestamp,
  timeStampToDate,
} from 'lib';
import { tokensToCredits } from 'lib/credits';
import Equivalence from 'models/Equivalence';
import SiteConnection from 'models/SiteConnection';
import { useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  downloadTokensUrl,
  fetchFile,
  saveBlobToDisk,
} from '../../lib/download';
import AddTokensModal from './AddTokens';
import ManualRevokeModal from './ManualRevokeModal';
import TableToolbar from 'pages/common/TableToolbar';

const SystemContext = React.createContext(null);

const System = () => {
  const customerAccountId = useRef(undefined);
  const navigate = useNavigate();
  const bgColor = useColorModeValue("#fff", "#1d1d1d");

  const [selectedEquivalences, setSelectedEquivalences] = useState<Array<string>>([]);
  const [manualRevokeEquivalenceId, setManualRevokeEquivalenceId] = useState('');

  const addTokensModal = useDisclosure();
  const manualRevokeModal = useDisclosure();
  const revokeTokensModal = useDisclosure();

  const { currentCustomerAccountId, currentUser } = useAuth();

  const { toast } = useSimpleToast();
  const infoToast = useToast();

  const params = useParams<{ systemId: string }>();
  const system = useSystem(params.systemId);
  const equivalences = useEquivalences(params.systemId);
  const connectedSite = useSiteConnection(system?.siteId);

  // returns false if a manual revoke is required
  const revoke = async (
    equivalenceId: string,
    revokeKey?: string,
  ): Promise<boolean> => {
    try {
      if (revokeKey) {
        await assertEquivalence(revokeKey);
      }
      await revokeEquivalence(
        currentCustomerAccountId,
        system.id,
        equivalenceId,
      );
      return true;
    } catch (e) {
      if (e instanceof ManualRevokeError) {
        return false;
      } else if (e instanceof Error) {
        toast({
          id: 'revoke-credits-error',
          title: 'Revoke Credits',
          description: e.message,
          status: 'error',
        });
      }
    }
    return true;
  };

  const handleRevoke = async () => {
    if (selectedEquivalences.length !== 1) return; // Safety check
    const equivalenceId = selectedEquivalences[0];
    const ok = await revoke(equivalenceId);
    revokeTokensModal.onClose();
    if (ok) {
      setSelectedEquivalences([]);
    } else {
      setManualRevokeEquivalenceId(equivalenceId);
      manualRevokeModal.onOpen();
    }
  };

  const handleManualRevoke = async (revokeKey: string) => {
    if (manualRevokeEquivalenceId.length === 0) return; // Safety check
    const ok = await revoke(manualRevokeEquivalenceId, revokeKey);
    setSelectedEquivalences([]);
    setManualRevokeEquivalenceId('');
    manualRevokeModal.onClose();
    if (!ok) {
      toast({
        id: 'manual-revoke-credits-error',
        title: 'Manual Revoke',
        description:
          'Sorry something went wrong, please cancel revoke and try again.',
        status: 'error',
      });
    }
  };

  const handleCancel = async (systemId: string, equivalenceId: string) => {
    try {
      await cancelRevokeEquivalence(
        currentCustomerAccountId,
        systemId,
        equivalenceId,
      );
    } catch (e) {
      if (e instanceof Error) {
        toast({
          id: 'cancel-revoke-credits-error',
          title: 'Cancel Revoke',
          description: e.message,
          status: 'error',
        });
      }
    }
  };

  const revokeMessage = 'Are you sure you want to revoke these credits from the system?';
  const revokeTitle = 'Revoke Credits';

  useEffect(() => {
      customerAccountId.current = currentCustomerAccountId;
  }, []);

  // Deal with user changing accounts, reset back to systems page.
  useEffect(() => {
    if (customerAccountId.current !== currentCustomerAccountId) {
      navigate('/systems');
    }
  }, [currentCustomerAccountId, navigate]);

  const downloadEnabled = () => {
    return equivalences?.length > 0;
  };

  const handleDownload = async () => {
    if (!currentUser) return; // Safety check
    try {
      const url = downloadTokensUrl(system.siteId);
      const token = await currentUser.getIdToken();
      const [filename, blob] = await fetchFile(url, token);
      saveBlobToDisk(blob, filename);
      infoToast({
        id: 'download-credits-info',
        title: 'Download Credits',
        description: 'Download success, please check your downloads folder.',
        status: 'info',
      });
    } catch (e) {
      console.error(e);
      toast({
        id: 'download-credits-error',
        title: 'Download Credits',
        description: 'Download encountered a problem, please try again.',
        status: 'error',
      });
    }
  };

  return (
    <Flex flex="1" flexDirection="column">
      <Flex
        px="8px"
        h="56px"
        alignItems="center"
        zIndex="1"
        borderRadius="8px 8px 0px 0px"
        bg={bgColor}
        >
        <Button
          onClick={() => {
            navigate('/systems');
          }}
          aria-label="Back to systems"
          leftIcon={<ChevronLeftIcon />}
        >
          Systems
        </Button>
        <Heading
          p="2"
          ml="2"
          mr="6"
          maxWidth="500px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          <MiddleEllipsisSpan text={system?.name} />
        </Heading>
        <ConnectedState connectedSite={connectedSite} />
      </Flex>
      <Flex flexDirection="column" bg={bgColor}>
        <TableToolbar>
          <ToolbarItem
            icon={PlusIcon}
            onClick={addTokensModal.onOpen}
            borderRadius="4px"
            data-test-id="tokens-add-button"
          >
            Add
          </ToolbarItem>
          <ToolbarItem
            icon={DeleteIcon}
            onClick={revokeTokensModal.onOpen}
            isDisabled={selectedEquivalences.length !== 1}
            borderRadius="4px"
            data-test-id="tokens-remove-button"
          >
            Revoke
          </ToolbarItem>
          <ToolbarItem
            icon={DownloadIcon}
            onClick={handleDownload}
            isDisabled={!downloadEnabled()}
            borderRadius="4px"
            data-test-id="tokens-download-button"
          >
            Download
          </ToolbarItem>
        </TableToolbar>
      </Flex>
      <SystemContext.Provider value={system}>
        <Box className="table-parent">
          <GridTable
            columns={[
              {
                accessorKey: 'tokens',
                header: 'Credits',
                id: 'tokens',
                resizable: true,
                testId: 'tokens',
                sortable: true,
                size: 100,
                cell: (props) => (
                  <CreditsCell
                    rowData={props.cell.row.original as Equivalence}
                  />
                ),
              },
              {
                accessorKey: 'assignedOn',
                header: 'Assigned',
                id: 'assignedOn',
                resizable: true,
                testId: 'assignedOn',
                sortable: true,
                size: 200,
                cell: (props) => (
                  <TimestampCell
                    cellData={props.cell.row.original.assignedOn as Timestamp}
                  />
                ),
              },
              {
                accessorKey: 'expiresOn',
                header: 'Valid',
                id: 'expiresOn',
                resizable: true,
                testId: 'expiresOn',
                sortable: true,
                cell: (props) => (
                  <ExpiresCell
                    rowData={props.cell.row.original as Equivalence}
                  />
                ),
              },
              {
                accessorKey: 'autoRevoke',
                header: 'Auto Revoke',
                id: 'autoRevoke',
                resizable: true,
                testId: 'autoRevoke',
                sortable: true,
                cell: (props) => (
                  <BooleanCell
                    cellData={!(props.cell.row.original.autoRevoke as boolean)}
                  />
                ),
              },
              {
                accessorKey: 'revokable',
                header: 'Downloaded',
                id: 'revokable',
                resizable: true,
                testId: 'revokable',
                sortable: true,
                cell: (props) => (
                  <BooleanCell
                    cellData={props.cell.row.original.revokable as boolean}
                  />
                ),
              },
              {
                accessorKey: 'subOrderId',
                header: 'Sub Order Id',
                id: 'subOrderId',
                resizable: true,
                testId: 'subOrderId',
                sortable: true,
              },
              {
                accessorKey: 'subBillingInterval',
                header: 'Sub Duration',
                id: 'subBillingInterval',
                resizable: true,
                testId: 'subBillingInterval',
                sortable: true,
                cell: (props) => (
                  <IntervalCell
                    cellData={
                      props.cell.row.original.subBillingInterval as number
                    }
                  />
                ),
              },
              {
                accessorKey: 'subActive',
                header: 'Sub Status',
                id: 'subActive',
                resizable: true,
                testId: 'subActive',
                sortable: true,
                size: 300,
                cell: (props) => (
                  <ActiveStatusCellWrapper
                    equivalence={props.cell.row.original as Equivalence}
                    onManualRevoke={() => {
                      setManualRevokeEquivalenceId(
                        props.cell.row.original.id as string,
                      );
                      manualRevokeModal.onOpen();
                    }}
                    onCancel={(systemId: string) => {
                      handleCancel(
                        systemId,
                        props.cell.row.original.id as string,
                      );
                    }}
                  />
                ),
              },
            ]}
            data={equivalences}
            selectable={{
              selectedIds: selectedEquivalences,
              setSelectedIds: setSelectedEquivalences,
            }}
            stickyLeft={2}
            style={{
              width: "100%",
              height: "calc(100vh - 212px)",
              overflow: "auto",
              borderRadius: "0px 0px 8px 8px",
            }}
          />
        </Box>
      </SystemContext.Provider>
      <AddTokensModal {...addTokensModal} system={system} />
      <ConfirmModal
        {...revokeTokensModal}
        title={revokeTitle}
        message={revokeMessage}
        requiresConfirm
        onOk={handleRevoke}
        buttonText="Revoke"
      />
      <ManualRevokeModal
        {...manualRevokeModal}
        system={system}
        onContinue={handleManualRevoke}
      />
    </Flex>
  );
};

const refreshInterval = 60;
const tryingInterval = 5;

const ConnectedState = ({
  connectedSite,
}: {
  connectedSite: SiteConnection;
}) => {
  const siteId = connectedSite?.id;
  const [tick, setTick] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tick + 1);
      if (!(tick % refreshInterval))
        refreshConnectionStatus(siteId).catch(console.error);
    }, 1000);
    return () => clearInterval(interval);
  }, [setTick, tick, siteId]);
  return renderConnectedState(connectedSite, tick < tryingInterval);
};

function renderOffline() {
  return (
    <Flex alignItems="center">
      <WarningColorIcon mr="0.5em" />
      <Box as="span" lineHeight="24px">
        Disconnected
      </Box>
    </Flex>
  );
}

function renderOnline() {
  return (
    <Flex alignItems="center">
      <CheckIcon mr="0.5em" />
      <Box as="span" lineHeight="24px">
        Online
      </Box>
    </Flex>
  );
}

function renderTrying() {
  return <Spinner />;
}

const renderConnectedState = (siteConn: SiteConnection, trying: boolean) => {
  let online = false;
  if (siteConn) {
    const secsSince = secondsSinceTimestamp(siteConn.seenOn);
    if (secsSince < refreshInterval) online = true;
  }
  return online ? renderOnline() : trying ? renderTrying() : renderOffline();
};

interface ActiveStatusCellWrapperProps {
  equivalence: Equivalence;
  onManualRevoke: () => void;
  onCancel: (systemId: string) => void;
}

const ActiveStatusCellWrapper = ({
  equivalence,
  onManualRevoke,
  onCancel,
}: ActiveStatusCellWrapperProps) => {
  const system = useContext(SystemContext);
  return (
    <ActiveStatusCell
      equivalence={equivalence}
      onManualRevoke={onManualRevoke}
      onCancel={() => onCancel(system?.id)}
    />
  );
};

const TimestampCell = ({ cellData }: { cellData: Timestamp }) => {
  return <Text>{formatUTCDate(cellData?.toDate())}</Text>;
};

const ExpiresCell = ({ rowData: equivalence }: { rowData: Equivalence }) => {
  if (!equivalence.expiresOn) {
    return null;
  }

  const expiresOn = timeStampToDate(equivalence.expiresOn);

  const dayInMs = 24 * 60 * 60 * 1000;
  const expiresInMs = expiresOn.getTime();
  const nowInMs = new Date().getTime();

  let differenceInMs = expiresInMs - nowInMs;
  let differenceInDays = Math.round(differenceInMs / dayInMs);
  if (differenceInDays < 0) {
    differenceInDays = -1;
    differenceInMs = 0;
  }

  var color = 'green';
  if (differenceInDays < 5) {
    color = 'red';
  } else if (differenceInDays < 10) {
    color = 'orange';
  }

  var whenStr;
  if (differenceInDays === -1) whenStr = 'Expired';
  else if (differenceInDays === 1) whenStr = `1 Day`;
  else whenStr = `${differenceInDays} Days`;

  var expiresOnStr = `${formatUTCDate(expiresOn)} @ 23.59 UTC`;

  return (
    <>
      {!equivalence.subActive && <Text>N/A</Text>}
      {equivalence.subActive && equivalence.expiresOn && (
        <Flex flex={1} flexDirection="column">
          {differenceInDays === 0}
          <Tooltip
            label={expiresOnStr}
            textColor="black"
            backgroundColor="lightgray"
            placement="top"
            closeDelay={500}
          >
            <Text>{whenStr}</Text>
          </Tooltip>
        </Flex>
      )}
    </>
  );
};

const CreditsCell = ({ rowData }: { rowData: Equivalence }) => {
  return (
    <>
      <Text>{tokensToCredits(rowData.tokens)}</Text>
    </>
  );
};

const IntervalCell = ({ cellData }: { cellData: number }) => {
  return (
    <>
      {cellData === 1 && 'Monthly'}
      {cellData === 3 && '3 Months'}
      {cellData === 12 && 'Annual'}
    </>
  );
};

interface ActiveStatusCellProps {
  equivalence: Equivalence;
  onManualRevoke: (eId: string) => void;
  onCancel: (eId: string) => void;
}

const ActiveStatusCell = ({
  equivalence: e,
  onManualRevoke,
  onCancel,
}: ActiveStatusCellProps) => {
  return (
    <>
      {e.revoking && (
        <Flex direction="row" alignItems="center">
          <Spinner size="sm" mr={2} /> Revoking
          <Button
            ml={2}
            padding={2}
            variant="link"
            onClick={() => onManualRevoke(e.id)}
          >
            Enter Code
          </Button>
          <Button
            ml={2}
            padding={2}
            variant="link"
            onClick={() => onCancel(e.id)}
          >
            Cancel
          </Button>
        </Flex>
      )}
      {!e.revoking &&
        e.subActive &&
        e.subReoccurring &&
        !e.subCancelled &&
        renderActiveStatus(e)}
      {!e.revoking && e.subActive && e.subCancelled && renderCancelledStatus(e)}
      {!e.subActive && (
        <Flex direction="row">
          <UnusedIcon />
          <Text opacity=".4">Inactive</Text>
        </Flex>
      )}
    </>
  );
};

function renderCancelledStatus(equivalence: Equivalence) {
  const endDate = timeStampToDate(equivalence.subDeactivateOn);
  return (
    <Flex flexDirection="row" justifyContent="center">
      <WarningColorIcon />
      <Text lineHeight="24px" ml={2}>
        Expires 23.59 {formatUTCDate(endDate)}
      </Text>
    </Flex>
  );
}

// Normal.
function renderActiveStatus(equivalence: Equivalence) {
  let endDate = calculateEndDate(
    equivalence.subActivatedOn,
    equivalence.subBillingInterval,
  );
  return (
    <Flex flexDirection="row" justifyContent="center">
      <RefreshIcon />
      <Text lineHeight="24px" ml={2}>
        Renews midnight {formatUTCDate(endDate)}
      </Text>
    </Flex>
  );
}

const BooleanCell = ({ cellData }: { cellData: boolean }) => {
  return !cellData ? <CheckIcon /> : null;
};

export default System;
