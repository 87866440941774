import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Text,
    Textarea,
    TextInput,
    useSimpleToast,
} from "@lawo/lawo-ui";

import { useEffect, useRef, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import System from "models/System";
import { createSystem, generateAccessKey, publishNameChange, updateSystem, validateSiteId } from "api";

interface CreateModalProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    initial?: System;
}

const CreateModal = ({ isOpen, onClose, initial }: CreateModalProps) => {

    const creating = initial === undefined;
    const { currentCustomerAccountId } = useAuth();
    const [systemId, setSystemId] = useState(initial?.id ?? "");
    const [name, setName] = useState(initial?.name ?? "");
    const [siteId, setSiteId] = useState(initial?.siteId ?? "");
    const [accessKey, setAccessKey] = useState("");
    const [generating, setGenerating] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (initial && isOpen) {
            setSystemId(initial.id);
            setName(initial.name);
            setSiteId(initial.siteId);
        }
    }, [initial, isOpen]);

    let valid = () => {
        switch (page) {
            case 1:
                return name?.length !== 0 && validateSiteId(siteId);
            case 2:
                return accessKey?.length !== 0;
        }
    }

    const { toast } = useSimpleToast();
    const handleError = (e: unknown, title: string) => {
        if (e instanceof Error) {
            toast({
                id: "handle-system-error",
                title: title,
                description: e.message,
                status: "error",
            });
        }
    };

    const handleGenerate = async () => {
        try {
            setGenerating(true);
            const accessKey = await generateAccessKey(systemId, currentCustomerAccountId);
            setAccessKey(accessKey);
        } catch (e) {
            handleError(e, "Generate Access Key");
        } finally {
            setGenerating(false);
        }
    }

    const haveSave = async () => {
        if (page === 2) {
            closeModal();
            return;
        }

        if (!creating) {
            try {
                if (name !== initial.name && siteId === initial.siteId) {
                    publishNameChange(siteId, name).catch(console.error)
                }

                if (name !== initial.name || siteId !== initial.siteId) {
                    await updateSystem(currentCustomerAccountId, systemId, name, siteId);
                }
                setPage(2);
            } catch (e) {
                handleError(e, "Update System");
            }
        } else {
            try {
                const sysId = await createSystem(currentCustomerAccountId, name, siteId);
                setSystemId(sysId);
                setAccessKey("");
                setPage(2);
            } catch (e) {
                handleError(e, "Create System");
            }
        }
    };

    const saveButtonText = () => {
        switch (page) {
            case 1:
                return creating ? "Create" :
                    name !== initial?.name || siteId !== initial?.siteId ? "Update" : "Next";
            case 2:
                return "Finish";
        }
    }

    const handleCancel = () => {
        closeModal();
    }

    const closeModal = () => {
        setPage(1);
        setName("");
        setSiteId("");
        setAccessKey("");
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeModal} motionPreset="scale">
            <ModalOverlay />
            <form onSubmit={(e) => {
                e.preventDefault();
                haveSave();
            }}
            >
                <ModalContent>
                    <ModalHeader>{creating ? "Add" : "Edit"} System</ModalHeader>
                    <ModalBody>
                        {
                            renderPage(page,
                                { name, setName, siteId, setSiteId },
                                { accessKey, onGenerate: handleGenerate, generating })
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            type="submit"
                            colorScheme="lawoBlue"
                            mr={3}
                            data-test-id="modal-create-system-submit-button"
                            isDisabled={!valid()}
                        >
                            {saveButtonText()}
                        </Button>
                        <Button onClick={handleCancel} data-test-id="modal-create-system-cancel-button">
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
};

const renderPage = (page: number, systemProps: SystemDetailsProps, accessProps: AccessKeyProps) => {
    switch (page) {
        case 1:
            return <SystemDetails {...systemProps} />;
        case 2:
            return <AccessKey {...accessProps} />;
        default:
            return null;
    }
}

interface SystemDetailsProps {
    name: string;
    setName: (name: string) => void;
    siteId: string;
    setSiteId: (siteId: string) => void;
}

interface AccessKeyProps {
    accessKey: string;
    generating: boolean;
    onGenerate: () => void;
};

export const AccessKey = ({ accessKey, onGenerate, generating }: AccessKeyProps) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (ref.current && accessKey.length > 0) {
            ref.current.select();
        }
    }, [accessKey]);
    return (
        <Stack spacing="16px">
            <Text>For HOME to be able to access the LAWO flex cloud licensing system, it needs an access key.</Text>
            <Text>Click 'Generate Access Key' then copy the key into the <strong>HOME &gt; Settings &gt; Licensing</strong> page.</Text>
            <FormControl mt="4">
                <Button
                    onClick={onGenerate}
                    colorScheme="lawoRed"
                    isDisabled={generating}
                    data-test-id="modal-create-system-access-key-button">
                    {generating && <Spinner size="sm" mr={4} />}
                    Generate Access Key
                </Button>
            </FormControl>
            <FormControl mt="4">
                <FormLabel htmlFor="siteId">Access Key</FormLabel>
                <Textarea
                    ref={ref}
                    id="accessKey"
                    value={accessKey}
                    isReadOnly
                />
            </FormControl>
        </Stack>
    )
}

const SystemDetails = ({ name, setName, siteId, setSiteId }: SystemDetailsProps) => {
    return (
        <Stack spacing="16px">
            <FormControl mt="4">
                <FormLabel htmlFor="name">Label</FormLabel>
                <TextInput
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
            <FormControl mt="4">
                <FormLabel htmlFor="siteId">System ID (20 characters)</FormLabel>
                <TextInput
                    id="siteId"
                    value={siteId}
                    onChange={(e) => setSiteId(e.target.value)}
                />
            </FormControl>
        </Stack>
    )
}


export default CreateModal;
