import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { doc, DocumentData, onSnapshot, QueryDocumentSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts/AuthContext";
import CustomerAccount from "models/CustomerAccount";


const useCustomerAccount = (): CustomerAccount => {
    const [data, setData] = useState<CustomerAccount>();
    const { currentUser, currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (!currentUser || !currentCustomerAccountId) {
            return;
        }

        const docRef = doc(db, "customerAccounts", currentCustomerAccountId);
        const unsubscribe = onSnapshot(docRef, (snapshot: QueryDocumentSnapshot<DocumentData>) => {
            if (snapshot.exists()) {
                const customerAccount: CustomerAccount = {
                    id: snapshot.id,
                    ...(snapshot.data() as CustomerAccount),
                };
                setData(customerAccount);
            }
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, currentUser?.uid]);

    return data;
}

export default useCustomerAccount;
