import {
    Box,
    GridTable,
    DeleteIcon,
    EditIcon,
    PlusIcon,
    ToolbarItem,
    useDisclosure,
    useSimpleToast,
    useColorModeValue,
    Flex,
} from "@lawo/lawo-ui";
import useCustomerUsers from "hooks/useCustomerUsers";
import { useState } from "react";
import CreateModal from "./CreateModal";
import ConfirmModal from "components/common/ConfirmModal";
import { auth } from "firebase.config";
import CustomerUser from "models/CustomerUser";
import { useAuth } from "contexts/AuthContext";
import { deleteUser } from "../../api";
import Header from "pages/common/Header";
import { TableToolbar } from "pages/common";

const CustomerUsers = () => {
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const createModal = useDisclosure();
    const deleteModal = useDisclosure();
    const editModal = useDisclosure();
    const { currentCustomerAccountId, isAdmin } = useAuth();
    const customerUsers = useCustomerUsers();
    const { toast } = useSimpleToast();
    const bgColor = useColorModeValue("#fff", "#1d1d1d");

    const first = () => {
        return (selectedIds.length > 0) ? customerUsers.find(c => c.id === selectedIds[0]) : undefined;
    };

    const handleDelete = async () => {        
        try {
            const users = selectedIds.map((id) => customerUsers.find(user => user.id === id)); 
            for( let i = 0 ; i < users.length ; ++i) {
                let user = users[i]; 
                if (!user) continue;
                await deleteUser(currentCustomerAccountId, user.email);                        
            }
        }
        catch (e) {
            if (e instanceof Error) {
                toast({
                    id: "delete-user-error",
                    status: "error",
                    title: "Delete User(s)",
                    description: e.message,
                });                
            }            
        }
        finally {
            setSelectedIds([]);
        }
    };

    const deleteMessage = () => selectedIds.length > 1 ? "Are you sure you want to delete these users?" : "Are you sure you want to delete this user?"
    const deleteTitle = () => selectedIds.length > 1 ? "Delete Users" : "Delete User"

    return (
        <Flex flexDirection="column" flex="1">
            <Header>
                Users
            </Header>
            <TableToolbar>
                <ToolbarItem
                    icon={PlusIcon}
                    onClick={createModal.onOpen}
                    isDisabled={!isAdmin}
                    borderRadius="4px"
                    data-test-id="customers-add-button"
                >
                    New
                </ToolbarItem>
                <ToolbarItem
                    icon={EditIcon}
                    onClick={editModal.onOpen}
                    isDisabled={selectedIds.length !== 1 || !isAdmin}
                    borderRadius="4px"
                    data-test-id="customers-edit-button"
                >
                    Edit
                </ToolbarItem>
                <ToolbarItem
                    icon={DeleteIcon}
                    onClick={deleteModal.onOpen}
                    isDisabled={selectedIds.length === 0 || selectedIds.includes(auth.currentUser.uid) || !isAdmin}
                    borderRadius="4px"
                    data-test-id="customers-delete-button"
                >
                    Delete
                </ToolbarItem>
            </TableToolbar>
            <Box className="table-parent">
                <GridTable
                    columns={[
                        {
                            accessorKey: "name",
                            header: "Name",
                            id: "name",
                            resizable: true,
                            testId: "name",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "email",
                            header: "Email Address",
                            id: "email",                            
                            resizable: true,
                            testId: "email",
                            sortable: true,
                            size: 300,
                        },
                        {
                            accessorKey: "role",
                            header: "Role",
                            id: "role",
                            resizable: true,
                            testId: "role",
                            sortable: true,
                            size: 100,
                            cell: (props) => <RoleCell rowData={props.cell.row.original as CustomerUser} customerId={currentCustomerAccountId} />,
                        },                        
                    ]}
                    data={customerUsers}
                    emptyMessage="No users have been added to the system"
                    selectable={{
                        selectedIds: selectedIds,
                        setSelectedIds: setSelectedIds,
                    }}
                    stickyLeft={2}
                    style={{
                        width: "100%",
                        height: "calc(100vh - 212px)",
                        borderRadius: "0px 0px 8px 8px",
                        overflow: "auto",
                    }}
                />
            </Box>
            <CreateModal {...createModal} />
            <CreateModal {...editModal} initial={first()} />
            <ConfirmModal {...deleteModal} title={deleteTitle()} message={deleteMessage()} requiresConfirm onOk={handleDelete} buttonText="Delete" />
        </Flex>
    );
};

const RoleCell = ({ rowData, customerId }: { rowData: CustomerUser, customerId: string }): JSX.Element => {
    let role = "Unknown";
    if (rowData.roles && rowData.roles[customerId]) {
        role = rowData.roles[customerId];
        role = role.charAt(0).toUpperCase() + role.slice(1);
    }

    return (
        <Box>{role}</Box>
    );
}

export default CustomerUsers;
