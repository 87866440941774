import { extendTheme, mode, ThemeProvider } from "@lawo/lawo-ui";

const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      ":root": {
        colorScheme: mode("light", "dark")(props),
      },
      "#root": {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        padding: "4px",
        bg: mode("#eeeeee", "#040404")(props),
      },
    }),
  },
});

interface Props {
  children: React.ReactNode;
};

const CustomThemeProvider = ({ children }: Props): JSX.Element => {
  return (
    <ThemeProvider theme={customTheme} portalZIndex={1400}>
      { children }
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
