import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Text,
    Box,
    useColorModeValue,
    VStack,
    Flex,
  } from '@lawo/lawo-ui';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { Cookie, Consents, CookieObject } from "models/Cookie";
import CookiesCategory from './CookiesCategory';

interface CookiePreferencesProps {
    isCookiesOpen: boolean;
    onClose: () => void;
}

const CookiePreferences = ({ isCookiesOpen, onClose }: CookiePreferencesProps) => {
    const borderColor = useColorModeValue("lawoGrey.50", "black");
    const [cookies, setCookie] = useCookies(["lawoFlexPortal"]);
    const [consents, setConsents] = useState<Consents>({
        essential: [] as string[],
        statistics: [] as string[],
        externalMedia: [] as string[],
    });
    const refuseRef = useRef();

    const essentialCookies: Cookie[] = [{
        id: "lawo",
        name: "Lawo Cookie",
        provider: "Lawo AG",
        providerImprint: "https://lawo.com/company/imprint/",
        purpose: "Cookie by Lawo for basic functions and for the proper function of the website.",
        privacyPolicy: "https://www.lawo.com",
        cookieName: ["lawoFlexPortal"],
        cookieExpiry: "1 year",
    }];

    const statisticsCookies: Cookie[] = [{
        id: "google-analytics",
        name: "Google Analytics",
        provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
        purpose: "Cookie by Google used for website analytics. Generates statistical data on how the visitor uses the website.",
        privacyPolicy: "https://policies.google.com/privacy?hl=en",
        cookieName: ["_ga", "_gid", "_gat"],
        cookieExpiry: "2 years",
    }];

    const externalMediaCookies: Cookie[] = [{
        id: "facebook",
        name: "Facebook",
        provider: "Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland",
        purpose: "Used to unblock Facebook content.",
        privacyPolicy: "https://www.facebook.com/privacy/explanation",
        hosts: [".facebook.com"],
        cookieName: [],
        cookieExpiry: "",
    },
  {
        id: "googlemaps",
        name: "Google Maps",
        provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
        purpose: "Used to unblock Google Maps content.",
        privacyPolicy: "https://policies.google.com/privacy?hl=en",
        hosts: [".google.com"],
        cookieName: ["NID"],
        cookieExpiry: "6 months",
    },
    {
        id: "instagram",
        name: "Instagram",
        provider: "Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland",
        purpose: "Used to unblock Instagram content.",
        privacyPolicy: "https://www.instagram.com/legal/privacy/",
        hosts: [".instagram.com"],
        cookieName: ["pigeon_state"],
        cookieExpiry: "Session",
    },
    {
        id: "openstreetmap",
        name: "OpenStreetMap",
        provider: "Openstreetmap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge CB4 0WS, United Kingdom",
        purpose: "Used to unblock OpenStreetMap content.",
        privacyPolicy: "https://wiki.osmfoundation.org/wiki/Privacy_Policy",
        hosts: [".openstreetmap.org"],
        cookieName: ["_osm_location", "_osm_session", "_osm_totp_token", "_osm_welcome", "_pk_id.", "_pk_ref.", "_pk_ses.", "qos_token"],
        cookieExpiry: "1-10 years",
    },
    {
        id: "twitter",
        name: "Twitter",
        provider: "Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Ireland",
        purpose: "Used to unblock Twitter content.",
        privacyPolicy: "https://twitter.com/privacy",
        hosts: [".twitter.com", ".twimg.com"],
        cookieName: ["__widgetsettings", "local_storage_support_test"],
        cookieExpiry: "Unlimited",
    },
    {
        id: "vimeo",
        name: "Vimeo",
        provider: "Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA",
        purpose: "Used to unblock Vimeo content.",
        privacyPolicy: "https://vimeo.com/privacy",
        hosts: ["player.vimeo.com"],
        cookieName: ["vuid"],
        cookieExpiry: "2 years",
    },
    {
        id: "youtube",
        name: "YouTube",
        provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
        purpose: "Used to unblock YouTube content.",
        privacyPolicy: "https://policies.google.com/privacy?hl=en&gl=en",
        cookieName: ["NID"],
        cookieExpiry: "6 months",
    }
];
    
    const modifyConsents = (category: string, id: string, enabled: boolean) => {
        const currentCategory = consents[category];
        const found = currentCategory.find((cookie: string) => cookie === id);

        if (found && !enabled) {
            currentCategory.splice(consents[category].indexOf(id), 1);
        } else if (!found && enabled) {
            currentCategory.push(id);
        }
    };

    const refuse = () => {
        const newConsents: Consents = {...consents};
        newConsents.statistics = [];
        newConsents.externalMedia = [];
        save(newConsents);
    };

    const saveCurrent = () => {
        save(consents);
    };

    const acceptAll = () => {
        const allConsents: Consents = {essential: [], statistics: [], externalMedia: []};
        statisticsCookies.forEach((cookie: Cookie) => {
            allConsents.statistics.push(cookie.id);
        });
        externalMediaCookies.forEach((cookie: Cookie) => {
            allConsents.externalMedia.push(cookie.id);
        });

        save(allConsents);
    };

    const save = (newConsents: Consents) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        essentialCookies.forEach((cookie: Cookie) => {
            if (newConsents.essential.indexOf(cookie.id) === -1) {
                newConsents.essential.push(cookie.id);
            }
        });

        const cookieObject: CookieObject = {
            consents: newConsents,
            domainPath: "lawo.com",
            expires: date.toUTCString(),
            version: 1,
        };

        const options: CookieSetOptions = {
            path: "/",
            expires: date,
            sameSite: "lax",
            secure: true,
        };

        setCookie("lawoFlexPortal", cookieObject, options);
    };

    return (
        <AlertDialog
            isOpen={isCookiesOpen}
            onClose={onClose}
            leastDestructiveRef={refuseRef}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isCentered={true}
        >
            <AlertDialogOverlay>
                <AlertDialogContent maxW="720px" maxH="800px">
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Privacy & Cookie Preferences
                    </AlertDialogHeader>
                    <Flex overflow="auto">
                        <AlertDialogBody p="24px">
                            <Text>
                                If you are under 16 and wish to give consent to optional services, you must ask your legal guardians for permission.<br /><br />
                            </Text>
                            <Text mb="24px">
                                We use cookies and other technologies on our website. Some of them are essential, while others help us to improve this website and your experience. Personal data may be processed (e.g. IP addresses), for example for personalized ads and content or ad and content measurement. You can find more information about the use of your data in our <a href="https://lawo.com/privacy-policy/"><u>privacy policy</u></a>.
                            </Text>
                            <Box mb="24px" borderTop={"1px solid"} borderColor={borderColor}/>
                            <VStack>
                                <CookiesCategory cookieCategory={{name: "Essential", id: "essential", essential: true}} cookies={essentialCookies} modifyConsents={modifyConsents}/>
                                <CookiesCategory cookieCategory={{name: "Statistics", id: "statistics", essential: false}} cookies={statisticsCookies} modifyConsents={modifyConsents}/>
                                <CookiesCategory cookieCategory={{name: "External Media", id: "externalMedia", essential: false}} cookies={externalMediaCookies} modifyConsents={modifyConsents}/>
                            </VStack>
                        </AlertDialogBody>
                    </Flex>
                    <AlertDialogFooter>
                        <Button ref={refuseRef} onClick={refuse}><Text>Refuse</Text></Button>
                        <Button ml="8px" onClick={saveCurrent}>Save Current</Button>
                        <Button ml="8px" colorScheme="lawoBlue" onClick={acceptAll}>Accept All</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CookiePreferences;
