import AuthRoute from "components/common/AuthRoute/AuthRoute";
import { useAuth } from "contexts/AuthContext";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ children }: { children: JSX.Element}): JSX.Element => {
    const { isAdmin } = useAuth();

    if (!isAdmin) {
        return <Navigate to="/login" />;
    }

    return <AuthRoute>{children}</AuthRoute>;
};

export default AdminRoute;
