import {
    Image,
    Flex,
    Spinner,
    useColorModeValue,
} from "@lawo/lawo-ui";
import Logo from "assets/images/lawo_logo.png";

// Loading component is a full screen loading component to display when the app is loading

const Loading = () => {
    const bgColor = useColorModeValue("#f9f9f9", "#242424");

    return (
        <Flex
            direction="column"
            flex="1"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            bg={bgColor}
        >
            <Image src={Logo} height="64px" width="64px" borderRadius="4px"/>
            <Spinner mt={8} />
        </Flex>
    );
};


export default Loading;