import {  funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";

export const assignEquivalence = async (accountId: string, systemId: string, subscriptionId: string, tokens: number, autoRevoke: boolean, autoRevokeOn: string) => {
    interface AssignEquivalenceRequestProps {
        "customerId": string,
        "systemId": string,
        "subscriptionId": string,
        "tokens": number,
        "autoRevoke": boolean,
        "autoRevokeOn": string,
    };
    
    interface AssignEquivalenceResponseProps {
        "err": string,
        "msg": string,
        "systemId": string,
        "subscriptionId": string,
        "tokens": number
    };

    try {
        const callAssignEquivalence = httpsCallable<AssignEquivalenceRequestProps, AssignEquivalenceResponseProps>(funcs, "assignEquivalenceToSystemHttp");
        await callAssignEquivalence({
            "customerId": accountId,
            "systemId": systemId,
            "subscriptionId": subscriptionId,
            "tokens": tokens,
            "autoRevoke": autoRevoke,
            "autoRevokeOn": autoRevokeOn,
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not assign equivalence");
    }
};

interface RevokeEquivalenceRequestProps {
    "customerId":       string,
    "systemId":         string,
    "equivalenceId":    string,
    "cancel":           boolean,    
};

interface RevokeEquivalenceResponseProps {
    "msg":              string,
    "systemId":         string,
    "subscriptionId":   string,
    "equivalenceId":    string
};

// ManualRevokeError thrown when manual revoke is required
export class ManualRevokeError extends Error {
    constructor() {
        super("Manual revoke required.");
    }
}

export const assertEquivalence = async (revokeKey: string) => {
    if (revokeKey?.length === 0) {
        throw new Error("Invalid revoke key");
    }
    interface AssignEquivalenceRequestProps {
        "jwt": string
    };    
    interface AssignEquivalenceResponseProps {
        "message": string
    };
    try {
        const callAssignEquivalence = httpsCallable<AssignEquivalenceRequestProps, AssignEquivalenceResponseProps>(funcs, "assertEquivalenceHttpPortal");
        await callAssignEquivalence({
            "jwt": revokeKey
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not assert equivalence");
    }
};

export const revokeEquivalence = async (accountId: string, systemId: string, equivalenceId: string) => {
    try {        
        const callRevokeEquivalence = httpsCallable<RevokeEquivalenceRequestProps, RevokeEquivalenceResponseProps>(funcs, "revokeEquivalenceFromSystemHttp");
        await callRevokeEquivalence({
            "customerId": accountId,
            "systemId": systemId,
            "equivalenceId": equivalenceId,
            "cancel": false,            
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            if (e.message.includes("Manual revoke required.")) {
                throw new ManualRevokeError();
            }
            throw new Error(e.message);
        } 
        throw new Error("Could not revoke equivalence");                
    }    
};

export const cancelRevokeEquivalence = async (accountId: string, systemId: string, equivalenceId: string) => {    
    try {
        const revokeEquivalence = httpsCallable<RevokeEquivalenceRequestProps, RevokeEquivalenceResponseProps>(funcs, "revokeEquivalenceFromSystemHttp");        
        await revokeEquivalence({
            "customerId": accountId,
            "systemId": systemId,
            "equivalenceId": equivalenceId,
            "cancel": true,            
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        } 
        throw new Error("Could not cancel revoke");        
    }
}

// setAutoRevokeToBillingEndDateHttp changes the equivalence to set the autoRevoke date and autoRevoke to true and
// asks an online AEGIS to redownload.
export const setAutoRevokeToBillingEndDateHttp = async (accountId: string, systemId: string, equivalenceId: string) => {    

    interface SetAutoRevokeToBillingEndDateHttpRequestProps {
        "customerId":       string,
        "systemId":         string,
        "equivalenceId":    string,        
    };
    
    interface SetAutoRevokeToBillingEndDateHttpResponseProps {
        "msg":              string,
    };

    try {
        const setAutoRevokeToBillingEndDateHttp = httpsCallable<SetAutoRevokeToBillingEndDateHttpRequestProps, SetAutoRevokeToBillingEndDateHttpResponseProps>(funcs, "setAutoRevokeToBillingEndDateHttp");        
        await setAutoRevokeToBillingEndDateHttp({
            "customerId": accountId,
            "systemId": systemId,
            "equivalenceId": equivalenceId,            
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        } 
        throw new Error("Could not move expiresOn");        
    }
}