import { doc, getDoc } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import {
  ApplicationVerifier,
  User as FirebaseUser,
  GoogleAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  OAuthProvider,
  ParsedToken,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  UserCredential,
  getMultiFactorResolver,
  isSignInWithEmailLink,
  multiFactor,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
  updatePassword as updatePasswordFB,
  // sendPasswordResetEmail,
  updateProfile,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  linkWithPopup,
  unlink,
  verifyPasswordResetCode as verifyPasswordResetCodeFB,
  confirmPasswordReset as confirmPasswordResetFB,
  checkActionCode as checkActionCodeFB,
  applyActionCode as applyActionCodeFB,
} from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase.config';
import CustomerUser from 'models/CustomerUser';
import { Loading } from 'components/common';
import { sendPasswordReset } from 'api/users';

// Auth provides ability to manage users within the portal
interface Auth {
  currentUser: FirebaseUser;
  displayName: string;
  isAdmin: boolean;
  role: string;
  phoneNumber: string;
  mfaEnabled: boolean;
  puaAccepted: boolean;
  setPuaAccepted: (puaAccepted: boolean) => void;
  customerAccountIds: string[];
  currentCustomerAccountId: string;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  isSignInWithEmailLink: (href: string) => boolean;
  registerUserWithPassword: (
    email: string,
    password: string,
    url: string,
  ) => Promise<boolean>;
  sendPasswordResetEmail: (email: string) => void;
  verifyPasswordReset: (code: string) => Promise<string>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
  updateName: (displayName: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  verifyPhoneNumber: (
    phoneNumber: string,
    recaptchaVerifier: ApplicationVerifier,
  ) => Promise<false | string>;
  enrollUser: (
    verificationCodeID: string,
    verificationCode: string,
  ) => Promise<boolean>;
  unenrollUser: () => Promise<boolean>;
  initializeMFARequest: (
    error: MultiFactorError,
    recaptchaVerifier: ApplicationVerifier,
  ) => Promise<false | { verificationID: string; resolver: MultiFactorResolver }>;
  verifyMFACode: (
    verificationID: string,
    resolver: MultiFactorResolver,
    verificationCode: string,
  ) => Promise<boolean>;
  checkActionCode: (code: string) => Promise<string>;
  applyActionCode: (code: string) => Promise<void>;
  reauthenticate: (password: string) => Promise<void>;
  loginWithGoogle: () => Promise<UserCredential>;
  loginWithMicrosoft: () => Promise<UserCredential>;
  loginWithApple: () => Promise<UserCredential>;
  setCurrentCustomerAccountId: (customerId: string) => void;
  getRecaptchaVerifier: (id: string) => RecaptchaVerifier;
  getErrorMessage: (error: unknown) => string | undefined;
  linkToAuthProvider: (provider: string) => Promise<boolean>;
  unlinkFromAuthProvider: (provider: string) => Promise<void>;
  reloadUser: () => Promise<void>;
}

const AuthContext = React.createContext(null);
export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);
  const [displayName, setDisplayName] = useState<string>('');
  const [isAdmin, setAdmin] = useState(false);
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [customerAccountIds, setCustomerAccountIds] = useState([]);
  const [currentCustomerAccountId, setCurrentCustomerAccountId] = useState('');
  const [puaAccepted, setPuaAccepted] = useState(false);
  const [loading, setLoading] = useState(true);

  const login = (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = (): Promise<void> => {
    return signOut(auth);
  };

  const sendPasswordResetEmail = (email: string) => {
    // change to pubsub triggered cloud function
    // return sendPasswordResetEmail(auth, email);
    return sendPasswordReset(email);
  };

  const updateName = (displayName: string): Promise<void> => {
    setDisplayName(displayName);
    return updateProfile(auth.currentUser, { displayName: displayName });
  };

  const enrollUser = async (verificationCodeID: string, verificationCode: string) => {
    const phoneAuthCredential = PhoneAuthProvider.credential(
      verificationCodeID,
      verificationCode,
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

    try {
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'Phone Number');
      setMfaEnabled(true);
      return true;
    } catch (error) {
      console.log('enrollUser failed ' + error);
      return false;
    }
  };

  const unenrollUser = async (): Promise<boolean> => {
    try {
      const enrolledUser = multiFactor(auth.currentUser);

      if (enrolledUser.enrolledFactors.length > 0) {
        const option = enrolledUser.enrolledFactors[0];
        if (option.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          await enrolledUser.unenroll(option);
          setMfaEnabled(false);
        }
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const checkActionCode = async (code: string): Promise<string> => {
    const actionCodeInfo = await checkActionCodeFB(auth, code);

    if (actionCodeInfo.data.multiFactorInfo.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      return (actionCodeInfo.data.multiFactorInfo as PhoneMultiFactorInfo).phoneNumber;
    }
  
    return "";
  }

  const applyActionCode = async (code: string) => {
    return await applyActionCodeFB(auth, code);
  }

  const verifyPhoneNumber = async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier): Promise<false | string> => {
    const session = await multiFactor(auth.currentUser).getSession();
    const phoneInfoOptions = {
      phoneNumber,
      session,
    };

    try {
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      return await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );
    } catch (error) {
      console.log('verifyPhoneNumber failed ' + error);
      return false;
    }
  };

  const initializeMFARequest = async (error: MultiFactorError, recaptchaVerifier: ApplicationVerifier): Promise<false | { verificationID: string; resolver: MultiFactorResolver }> => {
    const resolver = getMultiFactorResolver(auth, error);
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      try {
        const verificationID = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier,
        );

        return { verificationID, resolver };
      } catch (error) {
        return false;
      }
    }
  };

  const verifyMFACode = async (verificationID: string, resolver: MultiFactorResolver, verificationCode: string): Promise<boolean> => {
    const credentials = PhoneAuthProvider.credential(verificationID, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);
    let result = true;

    try {
      await resolver.resolveSignIn(multiFactorAssertion);
    } catch (error) {
      result = false;
    }
    return result;
  };

  const reauthenticate = async (password: string) => {
    const credential = EmailAuthProvider.credential(
      currentUser?.email,
      password,
    );
    
    await reauthenticateWithCredential(currentUser, credential);
  }

  const updatePassword = async (password: string): Promise<void> => {
    return await updatePasswordFB(auth.currentUser, password);
  };

  const registerUserWithPassword = async (email: string, password: string, url: string) => {
    await signInWithEmailLink(auth, email, url);
    const user = auth.currentUser;
    await updatePasswordFB(user, password);
  };

  const loginWithGoogle = (): Promise<UserCredential> => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return signInWithPopup(auth, provider);
  };

  const loginWithMicrosoft = (): Promise<UserCredential> => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    return signInWithPopup(auth, provider);
  };

  const loginWithApple = (): Promise<UserCredential> => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');

    return signInWithPopup(auth, provider);
  };

  const roleFromClaims = (claims: ParsedToken) => {
    if (claims.roles) {
      let role = claims.roles[currentCustomerAccountId];
      if (role) {
        return role;
      }
    }

    return claims.role;
  };

  const setPuaAcceptedFromDoc = async (currentUser: FirebaseUser, customerAccountId: string) => {
      const docRef = doc(db, 'customerUsers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          const customerUser: CustomerUser = {
              id: docSnap.id,
              ...(docSnap.data() as CustomerUser)
          };
          if (customerUser.puasAccepted) {
              setPuaAccepted(!!customerUser.puasAccepted[customerAccountId]);
          }
      } else {
          console.error('customerUser document not found!');
      }
  };

  const updateCustomerUserIds = async (currentUser: FirebaseUser) => {
      const tokens = await currentUser.getIdTokenResult();

      if (tokens) {
        setCustomerAccountIds(tokens.claims.customerIds);

        if (tokens.claims.customerIds.length > 0) {
          const customerAccountId = tokens.claims.customerIds[0];
          setCurrentCustomerAccountId(customerAccountId);
          await setPuaAcceptedFromDoc(currentUser, customerAccountId);
        }

        setAdmin(roleFromClaims(tokens.claims) === 'admin');
        setRole(roleFromClaims(tokens.claims));
      }
  };

  const isEmailLinkFunc = (href: string) => {
    return isSignInWithEmailLink(auth, href);
  };

  const getRecaptchaVerifier = (id: string) => {
    const recaptchaVerifier = new RecaptchaVerifier(id, {
      "size": "invisible",
      "callback": () => {}
    }, auth);

    return recaptchaVerifier;
  };

  const getErrorMessage = (error: unknown) => {
    if (error instanceof FirebaseError) {
      const firebaseError = error as FirebaseError;
      return firebaseError.message;
    }
  };

  const linkToAuthProvider = async (provider: string) : Promise<boolean> => {
    let result = false;
    try {
      await linkWithPopup(currentUser, new OAuthProvider(provider));
      result = true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/multi-factor-auth-required') {
          await currentUser.reload();
          result = true;
        }
      }
    }
    return result;
  };

  const unlinkFromAuthProvider = async (provider: string) => {
      await unlink(currentUser, provider);
  };

  const reloadUser = async () => {
    if (currentUser) {
      await currentUser.getIdToken(true);
      await currentUser.reload();
      await updateCustomerUserIds(currentUser);
    }
  };

  const verifyPasswordReset = async (code: string) => {
    return await verifyPasswordResetCodeFB(auth, code);
  };

  const confirmPasswordReset = async (code: string, newPassword: string) => {
    return await confirmPasswordResetFB(auth, code, newPassword);
  };
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      setCurrentUser(user);

      if (user !== null) {
        setDisplayName(user.displayName);
        await updateCustomerUserIds(user);

        const options = multiFactor(user).enrolledFactors;
        if (options.length > 0) {
          const option = options[0];
          if (option.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            setPhoneNumber((option as PhoneMultiFactorInfo).phoneNumber);
            setMfaEnabled(true);
          }
        }
      } else {
        setDisplayName('');
        setPhoneNumber('');
        setCustomerAccountIds([]);
        setCurrentCustomerAccountId('');
        setAdmin(false);
        setRole('');
        setPuaAccepted(false);
        setMfaEnabled(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getTokens = async () => {
      if (!currentUser) {
        return;
      }
      const tokens = await currentUser.getIdTokenResult(true);
      setAdmin(roleFromClaims(tokens.claims) === 'admin');
    };

    getTokens();
  }, [currentCustomerAccountId]);


  const value = {
    currentUser,
    displayName,
    isAdmin,
    role,
    mfaEnabled,
    phoneNumber,
    puaAccepted,
    customerAccountIds,
    currentCustomerAccountId,
    setPuaAccepted,
    login,
    logout,
    isSignInWithEmailLink: isEmailLinkFunc,
    registerUserWithPassword,
    sendPasswordResetEmail,
    verifyPasswordReset,
    confirmPasswordReset,
    updateName,
    updatePassword,
    verifyPhoneNumber,
    enrollUser,
    unenrollUser,
    initializeMFARequest,
    verifyMFACode,
    checkActionCode,
    applyActionCode,
    reauthenticate,
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithApple,
    setCurrentCustomerAccountId,
    getRecaptchaVerifier,
    getErrorMessage,
    linkToAuthProvider,
    unlinkFromAuthProvider,
    reloadUser,
  };

  return (
    <AuthContext.Provider value={value}>
      { loading ? <Loading /> : children }
    </AuthContext.Provider>
  );
};
