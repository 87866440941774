import {
    Box,
    Flex,
    GridTable,
} from '@lawo/lawo-ui';
import useVouchers from 'hooks/useVouchers';

const Vouchers = () => {
    const vouchers = useVouchers();

    return (
        <Flex flexDirection="column">
            <Box className="table-parent">
                <GridTable
                    columns={[
                        {
                            accessorKey: "orderId",
                            header: "Order ID",
                            id: "orderId",
                            resizable: true,
                            testId: "orderId",
                            sortable: true,
                            size: 200,
                        },
                        {
                            accessorKey: "createdBy",
                            header: "Created By",
                            id: "createdBy",
                            resizable: true,
                            testId: "createdBy",
                            sortable: true,
                            size: 300,
                        },
                    ]}
                    data={vouchers}
                    defaultSort={[{ id: "orderId", desc: true }]}
                    emptyMessage="No vouchers found."
                    style={{
                        width: "100%",
                        height: "calc(100vh - 140px)",
                        overflow: "auto",
                        borderRadius: "0px 0px 8px 8px",
                        padding: "8px",
                    }}
                    data-test-id="vouchers-list"
                />
            </Box>
        </Flex>
    );
};

export default Vouchers;
