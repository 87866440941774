import { db } from "firebase.config";
import { useEffect, useRef, useState } from "react";
import { doc, DocumentData, onSnapshot, QueryDocumentSnapshot } from "firebase/firestore"; 
import CustomerUser from "models/CustomerUser";
import { useSimpleToast } from '@lawo/lawo-ui';
import { useAuth } from "contexts/AuthContext";


const useCustomerUser = (): CustomerUser => {
    const cachedUserRef = useRef<CustomerUser>();
    const { currentUser, currentCustomerAccountId, logout, setPuaAccepted, reloadUser } = useAuth();
    const { toast } = useSimpleToast();

    const handleLogout = () => {
        toast({ id: "current-user-error", status: "info", title: "Automatic Signout", description: "You have been signed out due to an authorization change." });
        logout();
    };

    const handleRoleChange = (customerUser: CustomerUser) => {
        if (!customerUser.roles.hasOwnProperty(currentCustomerAccountId)) {
            handleLogout();
        } else {
            const cachedUser = cachedUserRef.current;
            if (cachedUser && cachedUser.roles[currentCustomerAccountId] !== customerUser.roles[currentCustomerAccountId]) {
                handleLogout();
            }
        }
    }

    const handlePuaChange = (customerUser: CustomerUser) => {
        if (!customerUser.puasAccepted.hasOwnProperty(currentCustomerAccountId)) {
            setPuaAccepted(false);
            return;
        }

        setPuaAccepted(customerUser.puasAccepted.hasOwnProperty(currentCustomerAccountId));
    }

    // const handleCustomerIdsChange = (customerUser: CustomerUser) => {
    //     const cachedUser = cachedUserRef.current;
    //     if (!cachedUser) {
    //         return;
    //     }
    //     if (customerUser.customerIds.length !== cachedUser.customerIds.length) {
    //         if (customerUser.customerIds.length > cachedUser.customerIds.length) {
    //             toast({ id: "customer-ids-changed", status: "info", title: "New Customer ID", description: "A customer ID has been added to your user account." });
    //         } else if (customerUser.customerIds.length < cachedUser.customerIds.length) {
    //             toast({ id: "customer-ids-changed", status: "info", title: "Customer ID Removed", description: "A customer ID has been removed from your account." });
    //         }
    //         reloadUser();
    //     }
    // }

    useEffect(() => {
        if (!currentUser || !currentCustomerAccountId) {
            return;
        }

        const docRef = doc(db, "customerUsers", currentUser.uid);
        const unsubscribe = onSnapshot(docRef, (snapshot: QueryDocumentSnapshot<DocumentData>) => {
            if (snapshot.exists()) {
                const customerUser: CustomerUser = {
                    id: snapshot.id,
                    ...(snapshot.data() as CustomerUser),
                };
                handleRoleChange(customerUser);
                handlePuaChange(customerUser);
                // handleCustomerIdsChange(customerUser);
                cachedUserRef.current = customerUser;
            } else {
                handleLogout();
            }
        }, error => {
            handleLogout();
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, currentUser?.uid]);

    return cachedUserRef.current;
}

export default useCustomerUser;
