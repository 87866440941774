import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import System from "models/System";


const useSystems = (): System[] => {
    const [data, setData] = useState<System[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        if (currentCustomerAccountId === "") {
            return;
        }
        
        const path = "customerAccounts/" + currentCustomerAccountId + "/systems";
        const unsubscribe = onSnapshot(collection(db, path), (snapshot) => {
            const systems = snapshot.docs.map((doc: DocumentData) => {
                const data = doc.data();
                const system: System = {
                    id: doc.id,
                    ...(data as System)
                };

                return system;
            });

            setData(systems);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId]);

    return data;
}

export default useSystems;
