import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { firebaseApp } from 'firebase.config';


const useAnalytics = () => {
    const [cookies, setCookie] = useCookies(["lawoFlexPortal"]);

    useEffect(() => {
        if (cookies.lawoFlexPortal) {
            const analytics = getAnalytics(firebaseApp);
            const enabled = cookies.lawoFlexPortal.consents.statistics.indexOf("google-analytics") !== -1;
            setAnalyticsCollectionEnabled(analytics, enabled);
        }
    }, [cookies]);
};

export default useAnalytics;
