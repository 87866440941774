import { funcs } from "firebase.config";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";
import Assignment from "models/Assignment";

interface UpdateSubscriptionProps {
    customerId: string;
    subscriptionId: string;
    action: string;
};

interface UpdateSubscriptionResult {
    message: string;
    assignments: [Assignment]
};

export class RevokeError extends Error {
    assignments: [Assignment];
    constructor(msg: string, assignments: [Assignment]) {
        super(msg);
        this.assignments = assignments;
        Object.setPrototypeOf(this, RevokeError.prototype);
    }
}

export const activateSubscription = async (accountId: string, subscriptionId: string) => {
    const callUpdateSubscriptionHttp = httpsCallable<UpdateSubscriptionProps, UpdateSubscriptionResult>(funcs, "updateSubscriptionHttp", { timeout: 10000 });
    try {
        await callUpdateSubscriptionHttp({
            "customerId": accountId,
            "subscriptionId": subscriptionId,
            "action": "activate",
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        throw new Error("Could not update subscription");
    }
};

// cancelSubscription tries to cancel a subscription.
export const cancelSubscription = async (accountId: string, subscriptionId: string) => {
    const callUpdateSubscriptionHttp = httpsCallable<UpdateSubscriptionProps, UpdateSubscriptionResult>(funcs, "updateSubscriptionHttp", { timeout: 10000 });
    try {        
        let res = await callUpdateSubscriptionHttp({
            "customerId": accountId,
            "subscriptionId": subscriptionId,
            "action": "cancel",
        });
        if (res.data.message==="requires revoke") {
            throw new RevokeError("Credits are in use, please revoke credits and try again", res.data.assignments);
        }
    } catch (e) {
        if (e instanceof FirebaseError) {
            throw new Error(e.message);
        }
        if (e instanceof RevokeError) {
            throw e;
        }
        throw new Error("Could not update subscription");
    }
};

