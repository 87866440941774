import { appCheck } from "firebase.config";
import { getToken } from "firebase/app-check";

export const downloadTokensUrl = (siteId: string) => {
    if (!siteId) {
        throw new Error("siteId not set.");
    }
    const baseUrl = process.env.REACT_APP_DOWNLOAD_URL;
    if (!baseUrl) {
        throw new Error("REACT_APP_DOWNLOAD_URL not set.");
    }
    return baseUrl + "?siteId=" + siteId;
}

// fetchFile downlaods a file from the server and returns a tuple of the filename and the blob.
export const fetchFile = async (
    url: string,
    token: string,
): Promise<[string, Blob]> => {

    let headers: HeadersInit = {
        "Accept-Encoding": "gzip",
        "Authorization": "Bearer " + token,
    };

    try {
        const appCheckTokenResponse = await getToken(appCheck, false);
        headers = { ...headers, "X-Firebase-AppCheck": appCheckTokenResponse.token as string };
    } catch (error) {
        throw new Error("Could not obtain app check token.");
    }

    const response = await fetch(url, {
        method: "GET",
        headers: headers,
        mode: "cors",
    });

    if (response.status !== 200) {
        const reason = await response.text();
        throw new Error(reason);
    }

    const blob = await response.blob();
    if (!blob) {
        throw new Error("Could not download file.");
    }

    // obtain the filename from the content-disposition header
    const contentDisposition = response.headers.get("content-disposition");
    if (!contentDisposition) {
        throw new Error("Could not obtain filename.");
    }
    const filename = contentDisposition.split("filename=")[1];
    // trim quotes from returned filename
    return [filename.replace(/"/g, ""), blob];
};

// saveBlobToDisk causes the blob to be written to a file on disk
// called filename in the users downloads folder.
export const saveBlobToDisk = (blob: Blob, filename: string) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};