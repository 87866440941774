import { db } from "firebase.config";
import { useEffect, useState } from "react";
import { collection, DocumentData, onSnapshot } from "firebase/firestore"; 
import { useAuth } from "contexts";
import Equivalence from "models/Equivalence";

const useEquivalences = (systemId: string): Equivalence[] => {
    const [data, setData] = useState<Equivalence[]>([]);
    const { currentCustomerAccountId } = useAuth();

    useEffect(() => {
        const path = "customerAccounts/" + currentCustomerAccountId + "/systems/" + systemId + "/equivalences";

        const unsubscribe = onSnapshot(collection(db, path), (snapshot) => {
            const equivalences = snapshot.docs.map((doc: DocumentData) => {
                return {
                    id: doc.id,
                    subActivatedOnString: doc.data().subActivatedOn?.toDate().toISOString(),
                    subDeactivateOnString: doc.data().subDeactivateOn?.toDate().toISOString(),
                    assignedOnString: doc.data().assignedOn?.toDate().toISOString(),
                    expiresOnString: doc.data().expiresOn?.toDate().toISOString(),
                    ...doc.data() };
            });

            setData(equivalences);
        }, (error) => {
            console.log(error);
        });

        return () => unsubscribe();
    }, [currentCustomerAccountId, systemId]);

    return data;
}

export default useEquivalences;
