export const validateEmail = (email: string) => {
    if (email.length === 0) {
        return false;
    }

    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) {
        return false;
    }

    // ensure that email address does not contain spaces or other invalid characters
    if (email.indexOf(' ') >= 0) {
        return false;
    }

    // ensure that email only contains a single @ character
    if (email.indexOf('@') !== email.lastIndexOf('@')) {
        return false;
    }

    return true;
};
