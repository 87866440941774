
const tokensPerCredit = 100; 

export const tokensToCredits = (tokens: number) : number => {
    let res =  tokens / tokensPerCredit;
    if (isNaN(res)) return 0; 
    return res;
}

export const creditsToTokens = (credits : number) : number => {
    return credits * tokensPerCredit;
}

